import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import size from 'lodash/size';

import { AppState } from '../state/reducers';
import { TDecisionAidQuestion } from '../../types';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import '../css/DecisionAid.css';
import DecisionAidIntro from '../components/DecisionAidIntro';
import DecisionAidQuestion from '../components/DecisionAidQuestion';
import DecisionAidReport from '../components/DecisionAidReport';
import DecisionAidPreferences from '../components/DecisionAidPreferences';
import { selectors as decisionAidQuestionsSelectors } from '../state/decisionAidQuestions';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const DecisionAid = () => {
  const { t } = useTranslation();

  const [pageIndex, setPageIndex] = useState(0);
  const [state, setState] = useState({
    answers: {},
    scores: {},
  });

  const decisionAidQuestions = useSelector(
    (reduxState: AppState) => decisionAidQuestionsSelectors.getAll(reduxState),
  );

  const next = () => setPageIndex(pageIndex + 1);

  const back = () => setPageIndex(pageIndex - 1);

  let content = <DecisionAidIntro next={next} />;

  if (pageIndex >= 1 && pageIndex <= size(decisionAidQuestions)) {
    content = (
      <DecisionAidQuestion
        question={decisionAidQuestions[pageIndex - 1]}
        questionTotal={size(decisionAidQuestions)}
        questionIndex={pageIndex - 1}
        next={next}
        back={back}
        state={state}
        setState={setState}
      />
    );
  }

  if (pageIndex === size(decisionAidQuestions) + 1) {
    content = <DecisionAidReport next={next} state={state} />;
  }

  if (pageIndex === size(decisionAidQuestions) + 2) {
    content = (
      <DecisionAidPreferences
        back={back}
        state={state}
        decisionAidQuestions={decisionAidQuestions as TDecisionAidQuestion[]}
      />
    );
  }

  return (
    <article className="page decision-aid row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('decision-aid')} showRightButton />
          <section className="decision-aid-container">
            {content}
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default DecisionAid;
