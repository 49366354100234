import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="app-footer">
      <a className="navigation" target="_blank" rel="noreferrer" href={t('urls.about-owise')}>{t('about-us')}</a>
      <Link className="navigation" to="/useful-links">{t('useful-links')}</Link>
      <Link className="navigation" to="/glossary">{t('glossary')}</Link>
      <a className="navigation" target="_blank" rel="noreferrer" href={t('urls.contact')}>{t('contact')}</a>
      <a className="navigation" target="_blank" rel="noreferrer" href={t('urls.terms-of-use')}>{t('terms-of-use')}</a>
      <a className="navigation" target="_blank" rel="noreferrer" href={t('urls.privacy-policy')}>{t('privacy')}</a>
      <a className="navigation" target="_blank" rel="noreferrer" href={t('urls.faq')}>{t('faq')}</a>
    </footer>
  );
};

export default Footer;
