import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import set from 'lodash/set';
import localforage from 'localforage';

import getConfigAsync from '../utils/getConfigAsync';
import getApiTokenAsync from '../utils/getApiTokenAsync';
import localConfig from '../config';

const DEV = process.env.NODE_ENV !== 'production';

const api = axios.create({
  timeout: localConfig.api.timeout,
  validateStatus: (status) => {
    if (status >= 400 && status <= 404) {
      return true;
    }

    return status >= 200 && status < 300;
  },
});

const requestHandlerAsync = async (config: AxiosRequestConfig) => {
  let baseURL = await localforage.getItem('@Settings:baseURL');

  try {
    if (!baseURL) {
      if (DEV) {
        console.info('[api.requestHandlerAsync] Fetching config'); // eslint-disable-line no-console
      }

      const { apiUrl } = await getConfigAsync() || {};

      if (apiUrl) {
        baseURL = apiUrl;
        await localforage.setItem('@Settings:baseURL', apiUrl);
      }

      if (DEV) {
        console.info('[api.requestHandlerAsync] Updated baseURL', { baseURL }); // eslint-disable-line no-console
      }
    }

    const token = await getApiTokenAsync();

    if (!token) {
      if (DEV) {
        console.error('[api.requestHandlerAsync] Invalid or missing token'); // eslint-disable-line no-console
      }

      throw new Error('Invalid or missing token');
    }

    set(config, 'baseURL', baseURL);
    set(config, 'headers.authentication', `Bearer ${token}`);
  } catch (error) {
    // Reset baseURL
    await localforage.setItem('@Settings:baseURL', '');

    if (DEV) {
      console.error('[api.requestHandlerAsync]', 'catch', error); // eslint-disable-line no-console
      console.error('[api.requestHandlerAsync]', 'catch', error.response); // eslint-disable-line no-console
    }
  }

  return config;
};

const errorHandlerAsync = async (error: AxiosError) => {
  if (DEV) {
    console.error('[api.errorHandlerAsync]', 'catch', error); // eslint-disable-line no-console
    console.error('[api.errorHandlerAsync]', 'catch', error.response); // eslint-disable-line no-console
  }

  return Promise.reject(error);
};

// Add a request interceptor
api.interceptors.request.use(requestHandlerAsync, errorHandlerAsync);

export default api;
