import React, { useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import map from 'lodash/map';
import size from 'lodash/size';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import first from 'lodash/first';
import join from 'lodash/join';

import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import ListItem from '../components/ListItem';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import { selectors as profileQuestionCategoriesSelectors } from '../state/profileQuestionCategories';
import { selectors as profileQuestionsSelectors } from '../state/profileQuestions';
import { selectors as profileQuestionAnswersSelectors } from '../state/profileQuestionAnswers';
import getLocalizedText from '../utils/getLocalizedText';
import tWithPlurals from '../utils/tWithPlurals';
import defaultGet from '../utils/defaultGet';
import { ReactComponent as IconAccount } from '../assets/icons/Account.svg';
import { ReactComponent as IconDemographics } from '../assets/icons/Globe.svg';
import { ReactComponent as IconHealth } from '../assets/icons/Health.svg';
import { ReactComponent as IconDiagnosis } from '../assets/icons/Diagnosis.svg';
import { ReactComponent as IconStage } from '../assets/icons/Stage.svg';
import { ReactComponent as IconMolecular } from '../assets/icons/Molecular.svg';
import { ReactComponent as IconSurgery } from '../assets/icons/Surgery.svg';
import { ReactComponent as IconTreatment } from '../assets/icons/Treatment.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import { ReactComponent as IconDecisionAid } from '../assets/icons/DecisionAid.svg';
import Colors from '../theme/Colors';
import getProfileProgressSelector from '../selectors/getProfileProgress';
import showDecisionAidSelector from '../selectors/showDecisionAid';
import '../css/Profile.css';

const profileQuestionCategoryIcons = {
  user: <IconAccount fill={Colors.primary} className="icon" />,
  demographics: <IconDemographics fill={Colors.primary} className="icon" />,
  health: <IconHealth fill={Colors.primary} className="icon" />,
  diagnosis: <IconDiagnosis fill={Colors.primary} className="icon" />,
  stage: <IconStage fill={Colors.primary} className="icon" />,
  molecular: <IconMolecular fill={Colors.primary} className="icon" />,
  surgery: <IconSurgery fill={Colors.primary} className="icon" />,
  treatment: <IconTreatment fill={Colors.primary} className="icon" />,
};

type ProfileQuestionProps = {
  questionId: string;
  index: number;
  listSize: number;
};

const DrawerRight = () => {
  const { t } = useTranslation();

  const { percentage } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  const showDecisionAid = useSelector((state: AppState) => showDecisionAidSelector(state));

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        <section className="drawer-item">
          <header className="row between center">
            <h1 className="heading">{t('profile')}</h1>
            <p className="body light">{t('profile-completed', { percentage })}</p>
          </header>
        </section>
        {
          showDecisionAid
            ? (
              <>
                <header className="row between center">
                  <h1 className="heading">{t('tools')}</h1>
                </header>
                <ListItem
                  leftIcon={<IconDecisionAid fill={Colors.primary} className="icon" />}
                  primaryText={t('decision-aid')}
                  primaryTextClassName="button"
                  secondaryText={t('decision-aid-subtitle.short')}
                  rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
                  to="/treatments/decision-aid"
                  divider
                />
              </>
            )
            : null
        }
      </section>
    </Drawer>
  );
};

const ProfileQuestion = (props: ProfileQuestionProps) => {
  const { questionId, index, listSize } = props;

  const { i18n } = useTranslation();

  const memoizedProfileQuestionGetById = useMemo(
    profileQuestionsSelectors.makeGetById,
    [],
  );

  const question = useSelector(
    (state: AppState) => memoizedProfileQuestionGetById(state, questionId),
  );

  const memoizedProfileQuestionAnswersGetByProfileQuestionId = useMemo(
    profileQuestionAnswersSelectors.makeGetByProfileQuestionId,
    [],
  );

  const answer = useSelector(
    (state: AppState) => memoizedProfileQuestionAnswersGetByProfileQuestionId(state, questionId),
  );

  let secondaryText = '';

  if (!isEmpty(answer)) {
    const answerType = get(question, 'answerType');
    const questionAnswers = get(question, 'answers');
    const profileQuestionAnswerIds = get(answer, 'profileQuestionAnswerIds');

    if (answerType === 'text') {
      secondaryText = get(answer, 'text', '');
    }

    if (answerType === 'year') {
      secondaryText = defaultGet(answer, 'year', '');
    }

    if (
      answerType === 'single'
      || answerType === 'single-with-images'
      || answerType === 'stage'
    ) {
      const selectedAnswer = find(questionAnswers, { id: first(profileQuestionAnswerIds) });

      if (!isEmpty(selectedAnswer)) {
        secondaryText = getLocalizedText(selectedAnswer, i18n.language, 'title');
      }
    }

    if (
      answerType === 'multiple'
      || answerType === 'medical-condition'
    ) {
      const selectedAnswers = map(
        profileQuestionAnswerIds,
        (id) => find(questionAnswers, { id }),
      );

      if (!isEmpty(selectedAnswers)) {
        secondaryText = join(map(selectedAnswers, (a) => getLocalizedText(a, i18n.language, 'title')), ', ');
      }
    }
  }

  return (
    <ListItem
      key={questionId}
      primaryText={getLocalizedText(question, i18n.language, 'title')}
      secondaryText={secondaryText}
      divider={index !== listSize - 1}
      rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
      className="profile-question"
      to={`/profile/${questionId}`}
    />
  );
};

const Profile = () => {
  const { i18n, t } = useTranslation();

  const categories = (
    useSelector((state: AppState) => profileQuestionCategoriesSelectors.getAll(state))
  );

  const { completed: profileCompleted } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  if (!profileCompleted) {
    return <Redirect to="/profile/complete" />;
  }

  return (
    <article className="page profile row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('profile')} showRightButton />
          {map(categories, (category) => (
            <Fragment key={category.id}>
              <section className="list-header">
                <div className="row center">
                  {get(profileQuestionCategoryIcons, category.type)}
                  <h1 className="heading-secondary accent">{getLocalizedText(category, i18n.language, 'title')}</h1>
                </div>
                <p className="body light">{tWithPlurals(t, 'profile-questions-count', size(category.profileQuestionIds))}</p>
              </section>
              <section className="list">
                {map(category.profileQuestionIds, (id, index) => (
                  <ProfileQuestion
                    questionId={id}
                    key={id}
                    index={index as unknown as number}
                    listSize={size(category.profileQuestionIds)}
                  />
                ))}
              </section>
            </Fragment>
          ))}
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Profile;
