import {
  takeLatest,
  put,
  delay,
  select,
  take,
  race,
  spawn,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { REHYDRATE } from 'redux-persist';
import jsonpatch from 'fast-json-patch';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import get from 'lodash/get';
import set from 'lodash/set';
import includes from 'lodash/includes';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import map from 'lodash/map';

import api from '../services/api';
import { actionTypes as authActionTypes } from '../state/auth';
import {
  actions as syncActions,
  actionTypes as syncActionTypes,
  selectors as syncSelectors,
} from '../state/sync';
import { actions as tokenActions } from '../state/token';
import { actions as runtimeActions } from '../state/runtime';
import { actions as UIActions } from '../state/ui';
import { actions as messagesActions } from '../state/messages';
import {
  actions as settingsActions,
  selectors as settingsSelectors,
} from '../state/settings';
import { selectors as templatesSelector } from '../state/templates';
import { getLocale as i18nGetLocale } from '../services/i18n';
import getUnixTime from '../utils/getUnixTime';
import { uploadedUserData } from '../state/sync/actions';
import getApiTokenAsync from '../utils/getApiTokenAsync';
import localConfig from '../config';

let lastFailure = 0;
let lastUserSync = 0;
let lastUserEtag = '';

const DEV = process.env.NODE_ENV !== 'production';

function shouldWaitForFailure() {
  const now = Date.now();

  if (lastFailure > now) {
    lastFailure = 0;
  }

  return now < lastFailure + localConfig.document.failureRetryDelay;
}

function* syncUserData(force: boolean) {
  const token: string = yield getApiTokenAsync().catch(noop);

  if (!token) {
    return false;
  }

  if (!force && shouldWaitForFailure()) {
    return true;
  }

  const now = Date.now();

  if (lastUserSync > now) {
    // Make sure to reset the last sync time if the clock changes
    lastUserSync = 0;
  }

  if (!force && now - lastUserSync < localConfig.document.userDocumentRefreshInterval) {
    // Return success although no syncing happened
    return true;
  }

  try {
    yield put(runtimeActions.syncUpdate({ userSyncInProgress: true }));
    const queryParams = { locale: i18nGetLocale() };

    let response: AxiosResponse<any> = yield api.get(`/user/current/${localConfig.document.id}`, { params: queryParams });

    if (response.status === 401) {
      if (DEV) {
        console.log('[syncUserData]', 'Refresh token', response.status); // eslint-disable-line no-console
      }

      yield put(tokenActions.refresh());

      return false;
    }

    if (response.status === 404) {
      // TODO: Fix types
      // @ts-ignore
      const currentUserData: any = yield select(syncSelectors.getCurrentUserData);
      // @ts-ignore
      const newUserTemplate: any = yield select(templatesSelector.get, 'newUser');
      const updatedNewUserTemplate = cloneDeep(newUserTemplate);
      const nowSeconds = getUnixTime();

      // Make sure that the createdAt and updatedAt fields present in the template are set to the
      // current timestamp, so for instance the welcome message will seem like it was sent today.
      forEach(updatedNewUserTemplate, (category) => forEach(category, (item) => {
        if (get(item, 'createdAt')) {
          set(item, 'createdAt', nowSeconds);
        }
        if (get(item, 'updatedAt')) {
          set(item, 'updatedAt', nowSeconds);
        }
      }));
      let newState = { ...currentUserData, ...updatedNewUserTemplate };

      const writeResponse: AxiosResponse<any> = yield api.post(`/user/current/${localConfig.document.id}`, newState);

      if (writeResponse.status === 200) {
        newState = get(writeResponse, 'data');
      } else if (writeResponse.status !== 204) {
        if (__DEV__) {
          console.log('[syncUserData]', 'Create new user document', response.status); // eslint-disable-line no-console
        }

        return false;
      }

      const etag = get(writeResponse, 'headers.etag');

      response = {
        headers: {
          etag,
        },
        data: newState,
        status: 200,
        statusText: '',
        config: {},
      };
    }

    if (isEmpty(response.data)) {
      if (DEV) {
        console.log('[syncUserData]', 'Empty response data', response.status); // eslint-disable-line no-console
      }

      return false;
    }

    const etag = get(response, 'headers.etag');

    if (etag !== lastUserEtag) {
      yield put(syncActions.updateUserData(response.data));

      const messages = get(response, 'data.messages');

      // Check if there are important messages that haven't been shown yet
      const importantMessages = filter(
        messages,
        ({ important, alertShownAt }) => important && !alertShownAt,
      );

      // Show alert if needed
      if (!isEmpty(importantMessages)) {
        yield put(UIActions.addAlert({ type: 'important' }));

        const importantMessageIds = map(importantMessages, 'id');

        // Set alertShownAt
        yield put(messagesActions.setAlertShownAt(importantMessageIds));
      }

      lastUserEtag = etag;
    }

    // The last sync time is the start time of the sync, maybe during the sync data was already updated
    lastUserSync = now;

    // Check if there are local changes and save them
    // response.data can be a jsonpatch so fetch the full last userdata from the sync state
    // TODO: Fix types
    // @ts-ignore
    const lastUserData: any = yield select(syncSelectors.getLastUserData);
    // @ts-ignore
    const currentUserData: any = yield select(syncSelectors.getCurrentUserData);

    yield put(settingsActions.changeAccountStatus('active'));

    if (!isEqual(currentUserData, lastUserData)) {
      const uploadingUserData = cloneDeep(currentUserData);
      const patch = jsonpatch.compare(lastUserData, uploadingUserData);

      let writeResponse: AxiosResponse<any> = yield api.patch(
        `/user/current/${localConfig.document.id}`,
        patch,
        {
          headers: { 'If-Match': etag },
        },
      );

      if (writeResponse.status === 400) {
        // Patching failed, upload the full document
        writeResponse = yield api.put(
          `/user/current/${localConfig.document.id}`,
          uploadingUserData,
          {
            headers: { 'If-Match': etag },
          },
        );
      }

      if (writeResponse.status >= 200 && writeResponse.status < 400) {
        yield put(uploadedUserData(uploadingUserData));

        // Reset the last sync so a resync will make sure any additional server side processing will be done
        // TODO: The server should return the new state and that should be put as new userstate
        lastUserSync = 0;
      }
    }

    yield put(runtimeActions.syncUpdate({
      userSyncInProgress: false,
      userSyncStale: false,
      lastUserSync,
      lastUserEtag,
    }));
  } catch (error: any) {
    // If the error message contains the word token it means that the token was invalid and should be refreshed
    if (includes(error.message, 'token')) {
      yield put(tokenActions.refresh());
    }

    if (error?.response?.status === 422) {
      if (__DEV__) {
        console.log('[syncUserData]', 'account deletion requested', error.response.status); // eslint-disable-line no-console
      }

      yield put(settingsActions.changeAccountStatus('deletion'));

      return 'changeAccountStatus';
    }

    if (error?.response?.status === 423) {
      if (__DEV__) {
        console.log('[syncUserData]', 'account deactivation requested', error.response.status); // eslint-disable-line no-console
      }

      yield put(settingsActions.changeAccountStatus('deactivated'));

      return 'changeAccountStatus';
    }

    if (DEV) {
      console.log('[syncUserData]', 'catch', error); // eslint-disable-line no-console
      console.log('[syncUserData]', 'catch', error.response); // eslint-disable-line no-console
    }

    return false;
  }

  return true;
}

function* syncSaga(): Generator<any, any, any> {
  lastUserEtag = '';

  let firstOpen = true;

  if (DEV) {
    console.log('[syncSaga] Started syncing'); // eslint-disable-line no-console
  }

  while (true) {
    const accountStatus = yield select(settingsSelectors.getAccountStatus);

    const { actionUser } = yield race({
      actionUser: take(syncActionTypes.USER_DATA),
      timeout: delay(accountStatus === 'active' || firstOpen ? 5000 : 60000),
    });

    firstOpen = false;

    const forceUser = !isEmpty(actionUser);

    if (!(yield* syncUserData(forceUser))) {
      lastFailure = Date.now();

      yield put(runtimeActions.syncUpdate({
        userSyncInProgress: false,
        userSyncStale: true,
        lastFailure,
      }));
    }
  }
}

function* checkStateChange(): Generator<any, any, any> {
  const accountStatus = yield select(settingsSelectors.getAccountStatus);
  yield delay(accountStatus === 'active' ? 3000 : 60000);

  const lastUserData = yield select(syncSelectors.getLastUserData);
  const currentUserData = yield select(syncSelectors.getCurrentUserData);
  const userSyncStale = !isEqual(currentUserData, lastUserData);

  // The 'put' actions will cancel the saga so we need to spawn so the put actions are detached from this task
  yield spawn(function* updateStatus() {
    if (userSyncStale) {
      yield put(runtimeActions.syncUpdate({ userSyncStale }));
      yield put(syncActions.userData());
    }
  });
}

function killEtag() {
  lastUserEtag = '';
}

function* watchSync() {
  // Trigger the syncing check on the authentication change, rehydrate and app reset
  yield takeLatest([authActionTypes.SET, REHYDRATE, 'RESET'], syncSaga);
  yield takeLatest('KILLETAG', killEtag);

  // Trigger forced sync if there is a change in the user state
  yield takeLatest('*', checkStateChange);
}

export {
  watchSync,
  syncUserData,
};
