import * as yup from 'yup';

const agreeTerms = yup
  .bool()
  .oneOf([true], 'errors:agree-terms-required');

const email = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value))
  .required('errors:email-required')
  .email('errors:email-invalid');

const country = yup
  .string()
  .oneOf(['uk', 'nl', 'us'], 'errors:country-required');

const passwordOldRules = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value))
  .required('errors:password-required')
  .min(6, 'errors:password-min-length');

const password = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value))
  .required('errors:password-required')
  .matches(/(?=.*[a-z])(?=.*[A-Z]).{6,}/, 'errors:password-sign-in');

const invitationCode = yup
  .string()
  .trim()
  .transform((value) => (value === '' ? undefined : value))
  .required('errors:invitation-code-required')
  .min(2, 'errors:invitation-code-min-length');

const signup = yup
  .object()
  .shape({
    email,
    password,
    country,
    agreeTerms,
  });

const login = yup
  .object()
  .shape({
    email,
    password: passwordOldRules,
  });

const passwordChange = yup
  .object()
  .shape({
    newPassword: password,
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'errors:password-mismatch')
      .required('errors:password-required'),
  });

const passwordReset = yup
  .object()
  .shape({
    email,
  });

const validateInvite = yup
  .object()
  .shape({
    code: invitationCode,
  });

const text = (required = false, error?: string) => {
  const schema = yup
    .string()
    .trim();

  if (required) {
    return schema
      .required(error);
  }

  return schema;
};

const options = (required = false, values: string[], error?: string) => {
  if (required) {
    return yup
      .string()
      .oneOf(values, error)
      .required(error);
  }

  return yup
    .string()
    .oneOf(values, error);
};

const date = (required = false, error?: string) => {
  if (required) {
    return yup
      .date()
      .typeError(error)
      .min(new Date(1900, 0, 1), error);
  }

  return yup
    .date()
    .nullable()
    .notRequired()
    .min(new Date(1900, 0, 1), error);
};

const duration = (required = false, error?: string) => {
  const units = ['days', 'weeks', 'months', 'years'];

  if (required) {
    return yup
      .object()
      .typeError(error)
      .shape({
        amount: yup
          .number()
          .integer(error)
          .typeError(error)
          .required(error),
        unit: yup
          .mixed()
          .oneOf(units, error)
          .required(error),
      });
  }

  return yup
    .object()
    .typeError(error)
    .shape({
      amount: yup
        .number()
        .integer(error)
        .typeError(error),
      unit: yup
        .mixed()
        .oneOf(units, error),
    });
};

const boolean = (required = false, error?: string) => {
  if (required) {
    return yup
      .boolean()
      .typeError(error)
      .required(error);
  }

  return yup
    .boolean()
    .typeError(error);
};

export {
  agreeTerms,
  email,
  country,
  password,
  invitationCode,
  signup,
  login,
  passwordOldRules,
  passwordChange,
  passwordReset,
  validateInvite,
  text,
  options,
  date,
  duration,
  boolean,
};
