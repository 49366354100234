import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import filter from 'lodash/filter';
import reject from 'lodash/reject';

import { AppState } from '../reducers';
import {
  SettingsTrendIndicatorsState,
  SettingsInvitationCodesState,
  SettingsHospitalGroupAccessCodesState,
  SettingsGeneralState,
  SettingsBannersState,
} from './types';
import {
  SettingsTrendIndicatorsReturnType,
  SettingsInvitationCodesReturnType,
  SettingsHospitalGroupAccessCodesReturnType,
  SettingsTrendIndicatorIdsReturnType,
} from '../../../types';

import EMPTY_ARRAY from '../../utils/empty-array';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const makeGetTrendIndicatorById = (id: string) => (
  createDeepEqualSelector(
    (state: AppState) => get(state, ['settings', 'trendIndicators', id]),
    // TODO: Fix type
    (state: any) => state,
  )
);

const getAllTrendIndicatorsSelector = (
  state: SettingsTrendIndicatorsState,
): SettingsTrendIndicatorsReturnType => (
  orderBy(state, ['order'])
);

const getAllTrendIndicators = createDeepEqualSelector(
  (state: AppState) => state.settings.trendIndicators,
  getAllTrendIndicatorsSelector,
);

const getEnabledTrendIndicatorIdsSelector = (
  state: SettingsTrendIndicatorsState,
): SettingsTrendIndicatorIdsReturnType => (
  map(filter(orderBy(state, ['order'], ['asc']), ({ disabled }) => !disabled), 'id')
);

const getEnabledTrendIndicatorIds = createDeepEqualSelector(
  (state: AppState) => state.settings.trendIndicators,
  getEnabledTrendIndicatorIdsSelector,
);

const getFavoriteTrendIndicatorIdsSelector = (
  state: SettingsTrendIndicatorsState,
): SettingsTrendIndicatorIdsReturnType => (
  map(reject(orderBy(state, ['order']), ({ favorite }) => !favorite), 'id')
);

const getFavoriteTrendIndicatorIds = createDeepEqualSelector(
  (state: AppState) => state.settings.trendIndicators,
  getFavoriteTrendIndicatorIdsSelector,
);

const getAllInvitationCodesSelector = (
  state: SettingsInvitationCodesState,
): SettingsInvitationCodesReturnType => (
  state || EMPTY_ARRAY
);

const getAllInvitationCodes = createDeepEqualSelector(
  (state: AppState) => state.settings.invitationCodes,
  getAllInvitationCodesSelector,
);

const getAllHospitalGroupAccessCodesSelector = (
  state: SettingsHospitalGroupAccessCodesState,
): SettingsHospitalGroupAccessCodesReturnType => (
  state || EMPTY_ARRAY
);

const getAllHospitalGroupAccessCodes = createDeepEqualSelector(
  (state: AppState) => state.settings.hospitalGroupAccessCodes,
  getAllHospitalGroupAccessCodesSelector,
);

const getGeneralSuggestionsEnabledSelector = (state: SettingsGeneralState): boolean => (
  get(state, 'suggestions.enabled')
);

const getGeneralSuggestionsEnabled = createDeepEqualSelector(
  (state: AppState) => state.settings.general,
  getGeneralSuggestionsEnabledSelector,
);

const getGeneralProfileCompletedSelector = (state: SettingsGeneralState): boolean => (
  get(state, 'profileCompleted')
);

const getGeneralProfileCompleted = createDeepEqualSelector(
  (state: AppState) => state.settings.general,
  getGeneralProfileCompletedSelector,
);

const getGeneralProfileCompleteModalShownSelector = (state: SettingsGeneralState): boolean => (
  get(state, 'profileCompleteModalShown')
);

const getGeneralProfileCompleteModalShown = createDeepEqualSelector(
  (state: AppState) => state.settings.general,
  getGeneralProfileCompleteModalShownSelector,
);

const getGeneralProfileCompletedModalShownSelector = (state: SettingsGeneralState): boolean => (
  get(state, 'profileCompletedModalShown')
);

const getGeneralProfileCompletedModalShown = createDeepEqualSelector(
  (state: AppState) => state.settings.general,
  getGeneralProfileCompletedModalShownSelector,
);

const getGeneralProfileIncompleteModalShownSelector = (state: SettingsGeneralState): boolean => (
  get(state, 'profileIncompleteModalShown')
);

const getGeneralProfileIncompleteModalShown = createDeepEqualSelector(
  (state: AppState) => state.settings.general,
  getGeneralProfileIncompleteModalShownSelector,
);

const getGeneralShowIntroSelector = (state: SettingsGeneralState): boolean => (
  get(state, 'showIntro')
);

const getGeneralShowIntro = createDeepEqualSelector(
  (state: AppState) => state.settings.general,
  getGeneralShowIntroSelector,
);

const getGeneralAppLanguageSelector = (state: SettingsGeneralState): boolean => (
  get(state, 'appLanguage')
);

const getGeneralAppLanguage = createDeepEqualSelector(
  (state: AppState) => state.settings.general,
  getGeneralAppLanguageSelector,
);

const getBannersTrendsIndexSelector = (state: SettingsBannersState): number => (
  get(state, 'trends')
);

const getBannersTrendsIndex = createDeepEqualSelector(
  (state: AppState) => state.settings.banners,
  getBannersTrendsIndexSelector,
);

const getAccountStatus = (state: AppState) => state.settings.accountStatus.status;

export {
  makeGetTrendIndicatorById,
  getAllTrendIndicators,
  getEnabledTrendIndicatorIds,
  getFavoriteTrendIndicatorIds,
  getAllInvitationCodes,
  getAllHospitalGroupAccessCodes,
  getGeneralSuggestionsEnabled,
  getGeneralProfileCompleted,
  getGeneralProfileCompleteModalShown,
  getGeneralProfileCompletedModalShown,
  getGeneralProfileIncompleteModalShown,
  getGeneralShowIntro,
  getGeneralAppLanguage,
  getBannersTrendsIndex,
  getAccountStatus,
};
