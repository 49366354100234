import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../css/Login.css';
import { actions as apiActions } from '../state/api';
import { actions as UIActions } from '../state/ui';
import defaultGet from '../utils/defaultGet';
import Colors from '../theme/Colors';
import { ReactComponent as IconCompleted } from '../assets/icons/Completed.svg';
import LoginScreen from './Login';
import Modal from '../components/Modal';

type Params = {
  token: string;
};

const VerifyEmail = () => {
  const dispatch = useDispatch();

  const { token } = useParams<Params>();

  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const verifyEmail = (emailToken: string) => (
      dispatch(apiActions.authEmailVerify({
        token: emailToken,
        onSuccess: () => setVisible(true),
        onError: (error: Error) => {
          const code = defaultGet(error, 'code', 'err_unknown');
          const text = t([`errorCodes.verifyEmail.${code}`, 'errorCodes.err_unknown']);

          dispatch(UIActions.addNotification({ text, type: 'error' }));
        },
      }))
    );

    verifyEmail(token);
  }, [token]);

  return (
    <>
      <LoginScreen />
      <Modal
        visible={visible}
        hideModal={() => setVisible(false)}
        actions={[
          {
            title: t('done'),
            onClick: () => setVisible(false),
            primary: true,
          },
        ]}
        className="mail-sent"
      >
        <>
          <h1 className="heading">{t('account-activated')}</h1>
          <p className="body">{t('account-activated-description')}</p>
          <div className="icon-container">
            <IconCompleted width={132} height={132} fill={Colors.secondary} />
          </div>
        </>
      </Modal>
    </>
  );
};

export default VerifyEmail;
