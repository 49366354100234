import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Loader from 'react-loader-spinner';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import ListItem from '../components/ListItem';
import Modal from '../components/Modal';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';

import Colors from '../theme/Colors';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import { ReactComponent as IconDelete } from '../assets/icons/Delete.svg';
import '../css/ChangeAccountSettings.css';
import { actions as apiActions } from '../state/api';
import { actions as UIActions } from '../state/ui';

const ChangeAccountSection = ({
  heading, subtitle, body, buttonPrimaryText, onClick,
}:{
  heading: string,
  subtitle: string,
  body: string,
  buttonPrimaryText: string,
  onClick: () => void }) => (
    <>
      <p className="heading primary">{heading}</p>
      <p className="subtitle">{subtitle}</p>
      <p>{body}</p>

      <ListItem
        primaryText={buttonPrimaryText}
        rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
        leftIcon={<IconDelete fill={Colors.primary} className="icon small" />}
        className="change-account-settings-button"
        onClick={onClick}
      />
    </>
);

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const ChangeAccountSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isPartialDeletion = useRef(false);

  const [loading, setLoading] = useState(false);

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [showDeletedAccountModal, setShowDeletedAccountModal] = useState(false);
  const [showDeletionSubmitModal, setShowAccountDeletionSubmitModal] = useState(false);

  const [showDeactivatedAccountModal, setShowDeactivatedAccountModal] = useState(false);
  const [showDeactivationSubmitModal, setShowAccountDeactivationSubmitModal] = useState(false);

  const toggleDeleteAccountModal = () => {
    setShowDeleteAccountModal((prev) => !prev);
  };

  const closeDeletionSubmitModal = () => {
    setShowAccountDeletionSubmitModal(false);
  };

  const onAccountDeletionPress = (isPartial: boolean) => () => {
    isPartialDeletion.current = isPartial;

    setShowDeleteAccountModal(false);
    setShowAccountDeletionSubmitModal(true);
  };

  const toggleDeactivationSubmitModal = () => {
    setShowAccountDeactivationSubmitModal((prev) => !prev);
  };

  const closeDeletedAccountModal = () => {
    setShowDeletedAccountModal(false);
  };

  const closeDeactivatedAccountModal = () => {
    setShowDeactivatedAccountModal(false);
  };

  const onSubmitDeactivation = async () => {
    setShowAccountDeactivationSubmitModal(false); // close submit modal
    setLoading(true);

    dispatch(apiActions.deactivateAccount({
      action: 'deactivation',
      onError: () => {
        dispatch(UIActions.addNotification({ text: t('unknown-error'), type: 'error' }));
        setLoading(false);
      },
      onSuccess: () => {
        setShowDeactivatedAccountModal(true); // show deactivated account modal
        setLoading(false);
      },
    }));
  };

  const onSubmitDeletion = () => {
    setShowAccountDeletionSubmitModal(false);
    setLoading(true);

    dispatch(apiActions.deleteAccount({
      action: 'deletion',
      partial: isPartialDeletion.current,
      onError: () => {
        dispatch(UIActions.addNotification({ text: t('unknown-error'), type: 'error' }));
        setLoading(false);
      },
      onSuccess: () => {
        setShowDeletedAccountModal(true);
        setLoading(false);
      },
    }));
  };

  const modalsDeleteAccountBodyText = (
    <Trans i18nKey="modals.delete-account.body">
      <a href={t('urls.privacy-policy')}>Privacy Policy</a>
    </Trans>
  );

  return (
    <article className="page change-account-settings row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('change-account-settings')} />
          <section className="diary-item">
            <ChangeAccountSection
              heading={t('deactivate-account')}
              subtitle={t('deactivate-account-subtitle')}
              body={t('deactivate-account-body')}
              buttonPrimaryText={t('deactivate-account')}
              onClick={toggleDeactivationSubmitModal}
            />

            <div className="empty-block" />

            <ChangeAccountSection
              heading={t('delete-account')}
              subtitle={t('delete-account-subtitle')}
              body={t('delete-account-body')}
              buttonPrimaryText={t('delete-account')}
              onClick={toggleDeleteAccountModal}
            />
          </section>
          {loading
            ? <div className="loader-wrapper"> <Loader type="TailSpin" color={Colors.primary} height={100} width={100} /> </div>
            : null}
        </section>
        <Footer />
      </section>

      <Modal
        visible={showDeleteAccountModal}
        hideModal={toggleDeleteAccountModal}
        actions={[
          {
            title: t('cancel'),
            onClick: toggleDeleteAccountModal,
          },
        ]}
      >
        <>
          <h1 className="heading">{t('modals.delete-account.title')}</h1>
          <p>{modalsDeleteAccountBodyText}</p>
          <button
            className={classNames('button', 'accent', 'align-center', 'delete-account-modal-button')}
            type="button"
            onClick={onAccountDeletionPress(true)}
          >
            {t('yes')}
          </button>
          <p className="delete-account-button-description">{t('modals.delete-account.yes.body')}</p>

          <button
            className={classNames('button', 'accent', 'align-center', 'delete-account-modal-button')}
            type="button"
            onClick={onAccountDeletionPress(false)}
          >
            {t('no')}
          </button>
          <p className="delete-account-button-description">{t('modals.delete-account.no.body')}</p>
        </>
      </Modal>

      <Modal
        visible={showDeletedAccountModal}
        hideModal={closeDeletedAccountModal}
        actions={[
          {
            title: t('alerts.incorrect-hospital-access-code.buttons.default'),
            onClick: closeDeletedAccountModal,
          },
        ]}
      >
        <>
          <p className="heading">{t('modals.account-deleted.body')}</p>
        </>
      </Modal>

      <Modal
        visible={showDeactivatedAccountModal}
        hideModal={closeDeactivatedAccountModal}
        actions={[
          {
            title: t('alerts.incorrect-hospital-access-code.buttons.default'),
            onClick: closeDeactivatedAccountModal,
          },
        ]}
      >
        <>
          <p className="heading">{t('modals.account-deactivated.body')}</p>
        </>
      </Modal>

      <Modal
        visible={showDeletionSubmitModal}
        hideModal={closeDeletionSubmitModal}
        actions={[
          {
            title: t('cancel'),
            onClick: closeDeletionSubmitModal,
          },
          {
            title: t('delete-account'),
            onClick: onSubmitDeletion,
            destructive: true,
          },
        ]}
      >
        <>
          <h1 className="heading">{t('alerts.delete-account.title')}</h1>
          <p>{t('alerts.delete-account.body')}</p>
        </>
      </Modal>

      <Modal
        visible={showDeactivationSubmitModal}
        hideModal={toggleDeactivationSubmitModal}
        actions={[
          {
            title: t('close'),
            onClick: toggleDeactivationSubmitModal,
          },
          {
            title: t('deactivate-account'),
            onClick: onSubmitDeactivation,
            destructive: true,
          },
        ]}
      >
        <>
          <h1 className="heading">{t('alerts.deactivate-account.title')}</h1>
          <p>{t('alerts.deactivate-account.body')}</p>
        </>
      </Modal>

      <DrawerRight />
    </article>
  );
};

export default ChangeAccountSettings;
