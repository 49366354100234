import React from 'react';

import ListItem from './ListItem';
import Colors from '../theme/Colors';
import { ReactComponent as IconChecked } from '../assets/icons/CheckedCircle.svg';
import { ReactComponent as IconUnchecked } from '../assets/icons/UncheckedCircle.svg';

type Props = {
  label: string;
  checked: boolean;
  onClick: () => void;
  additionalLeftIcon?: React.ReactNode
};

const Checkbox = (props: Props) => {
  const {
    checked, label, onClick, additionalLeftIcon,
  } = props;

  const Icon = checked ? IconChecked : IconUnchecked;

  return (

    <ListItem
      key={label}
      primaryText={label}
      onClick={onClick}
      leftIcon={(
        <div className="row center">
          {additionalLeftIcon}
          <Icon
            className="icon"
            fill={Colors.primary}
          />
        </div>
      )}
      divider
      active={false}
    />

  );
};
export default Checkbox;
