import React, { useState, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Tooltip from 'react-tooltip';
import Loader from 'react-loader-spinner';
import Dropdown from 'react-dropdown';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import without from 'lodash/without';
import union from 'lodash/union';
import toUpper from 'lodash/toUpper';
import size from 'lodash/size';
import trim from 'lodash/trim';
import values from 'lodash/values';

import '../css/Login.css';
import Modal from '../components/Modal';
import Button from '../components/Button';
import AnimatedTextInput from '../components/AnimatedTextInput';
import ListItem from '../components/ListItem';
import { ReactComponent as IconCheckEmail } from '../assets/icons/CheckEmail.svg';
import { ReactComponent as IconInfo } from '../assets/icons/Info.svg';
import { ReactComponent as IconCode } from '../assets/icons/Code.svg';
import { ReactComponent as IconDelete } from '../assets/icons/Delete.svg';
import { ReactComponent as IconChecked } from '../assets/icons/Checked.svg';
import { ReactComponent as IconUnchecked } from '../assets/icons/Unchecked.svg';
import Colors from '../theme/Colors';
import {
  signup as signupSchema,
  email as emailSchema,
  password as passwordSchema,
  country as countrySchema,
  agreeTerms as agreeTermsSchema,
  validateInvite as validateInviteSchema,
  options as optionsSchema,
} from '../utils/schemas';
import { actions as apiActions } from '../state/api';
import { actions as UIActions } from '../state/ui';
import defaultGet from '../utils/defaultGet';
import { formValidation, useFormFieldValidation } from '../utils/formValidation';
import { ReactComponent as IconAlert } from '../assets/icons/Alert.svg';
import logo from '../assets/images/logo.svg';
import localConfig from '../config';

type TFormErrors = {
  email?: string;
  password?: string;
  agreeTerms?: string;
};

type TFormErrorsValidateInvite = {
  code?: string | string[];
};

const Signup = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [errors, setErrors] = useState<TFormErrors>({});
  const [errorsValidateInvite, setErrorsValidateInvite] = useState<TFormErrorsValidateInvite>({});
  const [invitationCodes, setInvitationCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [infoVisible, setInfoVisible] = useState(false);
  const [codesVisible, setCodesVisible] = useState(false);
  const [invitationCode, setInvitationCode] = useState('');
  const [countryErrorModalOpen, setCountryErrorModalOpen] = useState(false);
  const [loadingValidateInvite, setLoadingValidateInvite] = useState(false);
  const [signupSuccessVisible, setSignupSuccessVisible] = useState(false);

  const { t } = useTranslation();

  const [email, setEmail] = useFormFieldValidation(
    emailSchema,
    'email',
    '',
    submitted,
    errors,
    setErrors,
  );

  const [password, setPassword] = useFormFieldValidation(
    passwordSchema,
    'password',
    '',
    submitted,
    errors,
    setErrors,
  );

  const [country, setCountry] = useFormFieldValidation(
    countrySchema,
    'country',
    '',
    submitted,
    errors,
    setErrors,
  );

  const [agreeTerms, setAgreeTerms] = useFormFieldValidation(
    agreeTermsSchema,
    'agreeTerms',
    false,
    submitted,
    errors,
    setErrors,
  );

  const [environment, setEnvironment] = useFormFieldValidation(
    optionsSchema(false, localConfig.environments),
    'environment',
    'prod',
    submitted,
    errors,
    setErrors,
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setSubmitted(true);
    setErrors({});

    if (!formValidation(signupSchema, { email, password, agreeTerms }, setErrors)) {
      return false;
    }

    setLoading(true);

    return dispatch(apiActions.authRegister({
      email,
      password,
      additionalData: {
        invitationCodes,
        country,
      },
      environment,
      onSuccess: () => {
        setLoading(false);
        setSignupSuccessVisible(true);
      },
      onError: (error: Error) => {
        const code = defaultGet(error, 'code', 'err_unknown');
        const text = t([`errorCodes.signup.${code}`, 'errorCodes.err_unknown']);

        dispatch(UIActions.addNotification({ text, type: 'error' }));

        setLoading(false);
      },
    }));
  };

  const handleSubmitValidateInvite = () => {
    setErrorsValidateInvite({});

    if (!formValidation(validateInviteSchema, { code: invitationCode }, setErrorsValidateInvite)) {
      return false;
    }

    setLoadingValidateInvite(true);

    return dispatch(apiActions.validateInvite({
      code: invitationCode,
      onSuccess: () => {
        setInvitationCodes(union(invitationCodes, [toUpper(invitationCode)]));

        setInvitationCode('');

        setLoadingValidateInvite(false);
      },
      onError: (error: Error) => {
        const errorCode = defaultGet(error, 'code', 'err_unknown');

        setErrorsValidateInvite({ code: [`errorCodes.validateInvite.${errorCode}`, 'errorCodes.err_unknown'] });

        setLoadingValidateInvite(false);
      },
    }));
  };

  const handleCodeDelete = (code: string) => setInvitationCodes(without(invitationCodes, code));

  const countries = [
    {
      value: 'uk',
      label: t('countries.uk'),
    },
    {
      value: 'nl',
      label: t('countries.nl'),
    },
  ];

  const environments = map(localConfig.environments, (option) => ({
    value: option,
    label: t(`environments.${option}`),
  }));

  const onAddCodePress = () => {
    if (!country) {
      setCountryErrorModalOpen(true);
      return;
    }
    setCodesVisible(true);
  };

  // eslint-disable-next-line max-len
  const signupDisabled = !((agreeTerms && !!country && !!email && !!password) && !(values(errors).length > 0)) || loading;

  return (
    <article className="page signup unauth">
      <section className="content">
        <section className="image-container">
          <header>
            <img src={logo} alt="Owise" />
            <a href={t('urls.website')} className="button contrast">
              {t('back-to-owise', { url: trim(t('urls.website'), 'https://') })}
            </a>
          </header>
        </section>
        <form onSubmit={handleSubmit}>
          <h1 className="heading-large">{t('sign-up-title')}</h1>
          <AnimatedTextInput
            value={email}
            labelText={t('email')}
            onChange={(event) => setEmail(event.currentTarget.value)}
            inputType="email"
            errorText={errors.email ? t(errors.email) : ''}
          />
          <AnimatedTextInput
            value={password}
            labelText={t('password')}
            onChange={(event) => setPassword(event.currentTarget.value)}
            inputType="password"
            errorText={errors.password ? t(errors.password) : ''}
          />
          <Dropdown
            options={countries}
            onChange={(option) => setCountry(option.value)}
            value={country}
            placeholder={t('country')}
          />

          {localConfig.showEnvironments ? (
            <Dropdown
              options={environments}
              onChange={(option) => setEnvironment(option.value)}
              value={environment}
              placeholder={t('environment')}
            />
          ) : null}

          <section className="code-buttons">
            <p>{t('signup-add-code')}</p>
            <Button
              buttonType="button"
              size="medium"
              onClick={onAddCodePress}
              labelText={t('add')}
            />
            <div data-tip={t('signup-code-description')}>
              <IconInfo fill={Colors.secondary} className="icon" />
            </div>
            <Tooltip
              className="tooltip body contrast"
              backgroundColor={Colors.black}
            />
          </section>

          <label htmlFor="terms" className="terms checkbox">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              onChange={(event) => setAgreeTerms(event.target.checked)}
              checked={agreeTerms}
              disabled={loading}
            />
            {
              agreeTerms
                ? <IconChecked fill={Colors.primary} className="icon" />
                : <IconUnchecked fill={Colors.lightGray} className="icon" />
            }
            <span className="body light">
              <Trans i18nKey="agree-terms">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href={t('urls.terms-of-use')}>Terms of Use</a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href={t('urls.privacy-policy')}>Privacy Policy</a>
              </Trans>
            </span>
          </label>
          {
            errors.agreeTerms
              ? (
                <div className="row center error">
                  <IconAlert fill={Colors.destructiveRed} className="icon small margin right" />
                  <p className="body error">{t(errors.agreeTerms)}</p>
                </div>
              )
              : null
          }
          <Button
            buttonType="submit"
            disabled={signupDisabled}
            size="large"
          >
            {
              loading
                ? <Loader type="TailSpin" color={Colors.white} height={20} width={20} />
                : t('sign-up')
            }
          </Button>
          <Link to="/login" className="already-account">
            <p className="button">{t('already-account')}</p>
            <p className="button accent">{t('login')}</p>
          </Link>
        </form>
      </section>

      <Modal
        visible={countryErrorModalOpen}
        hideModal={() => setCountryErrorModalOpen(false)}
        actions={[
          {
            title: t('close'),
            onClick: () => setCountryErrorModalOpen(false),
          },
        ]}
      >
        <>
          <p className="body">{t('errors:invitation-code-country-required')}</p>
        </>
      </Modal>

      <Modal
        visible={infoVisible}
        hideModal={() => setInfoVisible(false)}
        actions={[
          {
            title: t('done'),
            onClick: () => setInfoVisible(false),
          },
        ]}
      >
        <>
          <h1 className="heading">{t('invitation-code')}</h1>
          <p className="body">{t('signup-code-description')}</p>
        </>
      </Modal>

      <Modal
        visible={codesVisible}
        hideModal={() => setCodesVisible(false)}
        actions={[
          {
            title: !isEmpty(invitationCodes) ? t('add-another-code') : t('add-code'),
            onClick: handleSubmitValidateInvite,
            disabled: size(invitationCodes) === 10 || loadingValidateInvite,
            primary: true,
          },
          {
            title: t('done'),
            onClick: () => setCodesVisible(false),
          },
        ]}
      >
        <>
          <h1 className="heading">{t('code-title')}</h1>
          <p className="body">{t('code-description')}</p>
          <AnimatedTextInput
            value={invitationCode}
            labelText={t('code')}
            onChange={(event) => setInvitationCode(event.currentTarget.value)}
            errorText={errorsValidateInvite.code ? t(errorsValidateInvite.code) : ''}
          />
          {
            !isEmpty(invitationCodes)
              ? (
                <div className="codes">
                  <h1 className="heading">{t('my-codes')}</h1>
                  {map(invitationCodes, (code) => (
                    <ListItem
                      key={code}
                      primaryText={code}
                      divider
                      leftIcon={<IconCode fill={Colors.primary} className="icon" />}
                      rightIcon={(
                        <button type="button" onClick={() => handleCodeDelete(code)}>
                          <IconDelete fill={Colors.destructiveRed} className="icon" />
                        </button>
                      )}
                    />
                  ))}
                </div>
              )
              : null
          }
        </>
      </Modal>

      <Modal
        visible={signupSuccessVisible}
        hideModal={() => setSignupSuccessVisible(false)}
        actions={[
          {
            title: t('login'),
            onClick: () => {
              setSignupSuccessVisible(false);

              history.push('/login');
            },
            primary: true,
          },
        ]}
        className="mail-sent"
      >
        <h1 className="heading">{t('signup-mail-sent-title')}</h1>
        <h1 className="heading accent">{email}</h1>
        <p className="body">{t('signup-mail-sent-description')}</p>
        <p className="body">{t('mail-sent-spam')}</p>
        <div className="icon-container">
          <IconCheckEmail width={132} height={132} fill={Colors.secondary} />
        </div>
      </Modal>
    </article>
  );
};

export default Signup;
