import filter from 'lodash/filter';
import split from 'lodash/split';
import includes from 'lodash/includes';
import size from 'lodash/size';

export const getByKeys = (tags: string[], keys: string[]) => (
  filter(tags, (tag) => {
    const [key] = split(tag, ':');

    return includes(keys, key);
  })
);

export const hasKeys = (tags: string[], keys: string[]) => (
  size(getByKeys(tags, keys)) === size(keys)
);
