import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from '../state/reducers';
import { ReactComponent as IconCompleted } from '../assets/icons/Completed.svg';

import Modal from './Modal';
import { selectors as uiSelectors, actions as uiActions } from '../state/ui';
import { actions as settingsActions, selectors as settingsSelectors } from '../state/settings';
import getProfileProgressSelector from '../selectors/getProfileProgress';
import showDecisionAidSelector from '../selectors/showDecisionAid';
import Colors from '../theme/Colors';
import images from '../images';

const ProfileCompletedModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const profileCompletedModalShownSetting = (
    useSelector((state: AppState) => settingsSelectors.getGeneralProfileCompletedModalShown(state))
  );

  const { completed: profileCompleted } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  const showDecisionAid = useSelector((state: AppState) => showDecisionAidSelector(state));

  const visible = useSelector((state: AppState) => (
    uiSelectors.getProfileCompletedModalVisible(state)
  ));

  useEffect(() => {
    if (profileCompleted && !profileCompletedModalShownSetting) {
      dispatch(uiActions.profileCompletedModalShow());
    }
  }, [dispatch, profileCompleted, profileCompletedModalShownSetting]);

  const handleClose = () => {
    dispatch(settingsActions.generalUpdate({ profileCompletedModalShown: true }));
    dispatch(uiActions.profileCompletedModalHide());
  };

  const handlePrimaryButtonClick = () => {
    handleClose();

    if (showDecisionAid) {
      history.push('/treatments/decision-aid');
    } else {
      history.push('/');
    }
  };

  let content = (
    <>
      <h1 className="heading-large">{t('profile-completed-title')}</h1>
      <IconCompleted fill={Colors.secondary} className="icon" />
      <p className="body">{t('profile-completed-body')}</p>
    </>
  );

  if (showDecisionAid) {
    content = (
      <>
        <h1 className="heading-large">{t('profile-completed-title')}</h1>
        <img src={images.icons.sdm.default} alt={t('decision-aid')} />
        <p className="body">{t('profile-completed-decision-aid')}</p>
      </>
    );
  }

  return (
    <Modal
      visible={visible}
      hideModal={handleClose}
      actions={[
        {
          title: t('not-now'),
          onClick: handleClose,
        },
        {
          title: t(showDecisionAid ? 'begin-now' : 'get-started'),
          onClick: handlePrimaryButtonClick,
          primary: true,
        },
      ]}
      className="profile-completed"
    >
      {content}
    </Modal>
  );
};

export default ProfileCompletedModal;
