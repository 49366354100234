import { LanguageDetectorAsyncModule } from 'i18next';
import includes from 'lodash/includes';
import map from 'lodash/map';
import flatten from 'lodash/flatten';

import formatLocale from '../../utils/formatLocale';
import getBrowserLanguage from '../../utils/getBrowserLanguage';
import localConfig from '../../config';

const allowedLanguages = flatten(map(localConfig.availableLanguages, (item) => map(item as ReadonlyArray<{ language: string, name: string, default?: boolean }>, 'language')));

const defaultLanguage = 'en-GB';

const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  init: () => {},
  detect: async (callback) => {
    const language = getBrowserLanguage();

    if (!language) {
      return callback(defaultLanguage);
    }

    const formattedLanguage = formatLocale(language);

    if (!includes(allowedLanguages, formattedLanguage)) {
      return callback(defaultLanguage);
    }

    return callback(formattedLanguage);
  },
  cacheUserLanguage: () => {},
};

export default languageDetector;
