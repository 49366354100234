/* eslint global-require: 0 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from './Button';
import Colors from '../theme/Colors';

import images from '../images';
import { actions as tagsActions } from '../state/tags';

const TreatmentPlanChoice = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  return (
    <section className="treatment-plan-choice">
      <h1 className="heading-secondary">{t('personalize-heading')}</h1>
      <img src={images.personalize.personalize.default} alt={t('personalize-heading')} />
      <p className="body">{t('personalize-body')}</p>
      <Button
        onClick={() => dispatch(tagsActions.add([{ key: 'treatmentplan', value: 'yes' }]))}
        labelText={t('on-treatment-plan')}
        size="medium"
      />
      <Button
        onClick={() => dispatch(tagsActions.add([{ key: 'treatmentplan', value: 'no' }]))}
        labelText={t('no-treatment-plan')}
        color={Colors.primary}
        size="medium"
      />
    </section>
  );
};

export default TreatmentPlanChoice;
