import { createSelector } from 'reselect';
import get from 'lodash/get';
import toArray from 'lodash/toArray';

import { AppState } from '../reducers';
import { ContactsState } from './types';

import EMPTY_OBJECT from '../../utils/empty-object';

const getByIdSelector = (state: ContactsState, id: string) => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createSelector(
    (state: AppState) => state.contacts,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getAllSelector = (state: ContactsState) => toArray(state);

const getAll = createSelector(
  (state: AppState) => state.contacts,
  getAllSelector,
);

export {
  makeGetById,
  getAll,
};
