const prefix = 'MESSAGES';

const ADD = `${prefix}/ADD`;
const REMOVE = `${prefix}/REMOVE`;
const SET_READAT = `${prefix}/SET_READAT`;
const SET_ALERTSHOWNAT = `${prefix}/SET_ALERTSHOWNAT`;

const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  REMOVE,
  SET_READAT,
  SET_ALERTSHOWNAT,
  CLEAR,
};
