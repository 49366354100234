import { createSelector } from 'reselect';
import includes from 'lodash/includes';

import { selectors as tagsSelectors } from '../state/tags';
import includesAny from '../utils/includesAny';
import localConfig from '../config';

const showDecisionAid = createSelector(
  tagsSelectors.getAll,
  (tags) => (
    localConfig.isPC
    && includes(tags, 'treatmentplan:no')
    && includesAny(tags, ['riskProfile:low', 'riskProfile:intermediate', 'riskProfile:high'])
  ),
);

export default showDecisionAid;
