import produce, { Draft } from 'immer';
import map from 'lodash/map';
import set from 'lodash/set';
import keyBy from 'lodash/keyBy';

import {
  SettingsTrendIndicatorsState,
  SettingsTrendIndicatorsUpdateActionType,
  SettingsTrendIndicatorsUpdateDisabledActionType,
  SettingsTrendIndicatorsUpdateFavoriteActionType,
  SettingsTrendIndicatorsUpdateOrderActionType,
  SettingsBannersState,
} from './types';

import getUnixTime from '../../utils/getUnixTime';

const trendIndicatorsUpdate = (
  state: SettingsTrendIndicatorsState,
  action: SettingsTrendIndicatorsUpdateActionType,
) => {
  const { payload } = action;

  const trendIndicators = keyBy(payload, 'id');

  return {
    ...state,
    ...trendIndicators,
  };
};

const trendIndicatorsUpdateDisabled = produce((
  draft: Draft<SettingsTrendIndicatorsState>,
  action: SettingsTrendIndicatorsUpdateDisabledActionType,
) => {
  const { payload } = action;

  map(payload, ({ id, disabled }) => {
    set(draft, [id, 'id'], id);
    set(draft, [id, 'updatedAt'], getUnixTime());
    set(draft, [id, 'disabled'], disabled);
  });
});

const trendIndicatorsUpdateFavorite = produce((
  draft: Draft<SettingsTrendIndicatorsState>,
  action: SettingsTrendIndicatorsUpdateFavoriteActionType,
) => {
  const { payload } = action;

  map(payload, ({ id, favorite }) => {
    set(draft, [id, 'id'], id);
    set(draft, [id, 'updatedAt'], getUnixTime());
    set(draft, [id, 'favorite'], favorite);
  });
});

const trendIndicatorsUpdateOrder = produce((
  draft: Draft<SettingsTrendIndicatorsState>,
  action: SettingsTrendIndicatorsUpdateOrderActionType,
) => {
  const { payload } = action;

  map(payload, (id, index) => {
    set(draft, [id, 'id'], id);
    set(draft, [id, 'order'], index);
  });
});

// TODO: Fix better support for web
const bannersTrendsNext = produce((
  draft: Draft<SettingsBannersState>,
) => draft);

export {
  trendIndicatorsUpdate,
  trendIndicatorsUpdateDisabled,
  trendIndicatorsUpdateFavorite,
  trendIndicatorsUpdateOrder,
  bannersTrendsNext,
};
