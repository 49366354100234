import { TFunction } from 'i18next';

// See https://github.com/i18next/i18next-node/issues/45#issuecomment-10406473
const tWithPlurals = (t: TFunction, key: string, count: number): string => {
  const context = (count !== 0) ? 'many' : 'zero';

  return t(key, { count, context });
};

export default tWithPlurals;
