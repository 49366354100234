import React, {
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import Tooltip from 'react-tooltip';
import classNames from 'classnames';

import { AppState } from '../state/reducers';
import { TDecisionAidQuestion } from '../../types';

import Button from './Button';
import Progress from './Progress';
import DecisionAidListItems from './DecisionAidListItems';
import { selectors as tagsSelectors } from '../state/tags';
import getLocalizedText from '../utils/getLocalizedText';
import Colors from '../theme/Colors';
import { ReactComponent as IconInfo } from '../assets/icons/Info.svg';

type TState = {
  answers: any;
  scores: any;
};

type Props = {
  question: TDecisionAidQuestion;
  questionTotal: number;
  questionIndex: number;
  next: () => void;
  back: () => void;
  state: TState;
  setState: Dispatch<SetStateAction<any>>;
};

const DecisionAidQuestion = (props: Props) => {
  const {
    question,
    questionIndex,
    questionTotal,
    next,
    back,
    state,
    setState,
  } = props;

  const { t, i18n } = useTranslation();

  const memoizedTagsGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  const riskProfileTagValue = useSelector(
    (reduxState: AppState) => memoizedTagsGetValueByKey(reduxState, 'riskProfile'),
  );

  const disabled = isEmpty(get(state, ['answers', question.id]));

  const [visible, setVisible] = useState(false); // eslint-disable-line

  const title = getLocalizedText(question, i18n.language, 'title');
  const description = getLocalizedText(question, i18n.language, 'description');

  const handlePress = (answerId: string) => {
    const answer = find(question.answers, { id: answerId });
    const answerScores = get(answer, ['scores', riskProfileTagValue]);

    setState((prevState: TState) => ({
      ...prevState,
      answers: {
        ...prevState.answers,
        [question.id]: answerId,
      },
      scores: {
        ...prevState.scores,
        [question.id]: answerScores,
      },
    }));
  };

  return (
    <section className="decision-aid-question">
      <Progress progress={(questionIndex + 1) / questionTotal} />
      <h1 className="heading">{t('one-of-total', { one: questionIndex + 1, total: questionTotal })}</h1>
      <section className="title">
        <p className="body">{title}</p>
        {
          description
            ? (
              <>
                <div data-tip={description}>
                  <IconInfo fill={Colors.primary} className="icon" />
                </div>
                <Tooltip
                  className="tooltip body contrast"
                  backgroundColor={Colors.black}
                />
              </>
            )
            : null
        }
      </section>
      <section className="answers">
        {map(question.answers, (answer) => {
          const answerSelected = get(state, ['answers', question.id]) === answer.id;

          return (
            <button
              type="button"
              key={answer.id}
              onClick={() => handlePress(answer.id)}
              className={classNames({ active: answerSelected })}
            >
              <p className={classNames('button', { contrast: answerSelected, accent: !answerSelected })}>
                {getLocalizedText(answer, i18n.language, 'title')}
              </p>
            </button>
          );
        })}
      </section>
      <section className="between row">
        {
          questionIndex > 0
            ? <Button labelText={t('back')} onClick={back} />
            : <div />
        }
        <Button
          labelText={(questionIndex + 1 === questionTotal) ? t('see-result') : t('next')}
          onClick={next}
          disabled={disabled}
        />
      </section>
      <DecisionAidListItems riskProfile={riskProfileTagValue} state={state} />
    </section>
  );
};

export default DecisionAidQuestion;
