import { createSelector } from 'reselect';
import get from 'lodash/get';

import { AppState } from '../reducers';
import { UIState, UIRecordingState } from './types';

const getNotificationSelector = (state: UIState) => get(state, 'notification');

const getNotification = createSelector(
  (state: AppState) => state.ui,
  getNotificationSelector,
);

const getProfileCompleteModalVisibleSelector = (
  state: UIState,
) => (
  state.profileCompleteModalVisible
);

const getProfileCompleteModalVisible = createSelector(
  (state: AppState) => state.ui,
  getProfileCompleteModalVisibleSelector,
);

const getProfileCompletedModalVisibleSelector = (
  state: UIState,
) => (
  state.profileCompletedModalVisible
);

const getProfileCompletedModalVisible = createSelector(
  (state: AppState) => state.ui,
  getProfileCompletedModalVisibleSelector,
);

const getProfileIncompleteModalVisibleSelector = (
  state: UIState,
) => (
  state.profileIncompleteModalVisible
);

const getProfileIncompleteModalVisible = createSelector(
  (state: AppState) => state.ui,
  getProfileIncompleteModalVisibleSelector,
);

const getRecordingSelector = (state: UIRecordingState) => state;

const getRecording = createSelector(
  (state: AppState) => state.ui.recording,
  getRecordingSelector,
);

const drawerLeftOpen = (state: UIState): boolean => state.drawerLeftOpen || false;

const drawerRightOpen = (state: UIState): boolean => state.drawerRightOpen || false;

const getModalSelector = (state: UIState) => get(state, 'modal');

const getModal = createSelector(
  (state: AppState) => state.ui,
  getModalSelector,
);

const isModalVisibleSelector = (state: UIState, type: string) => {
  const modal = get(state, 'modal');

  return modal.visible && modal.type === type;
};

const isModalVisible = createSelector(
  (state: AppState) => state.ui,
  (state: any, type: string) => type,
  isModalVisibleSelector,
);

export {
  getNotification,
  getProfileCompleteModalVisible,
  getProfileCompletedModalVisible,
  getProfileIncompleteModalVisible,
  getRecording,
  drawerLeftOpen,
  drawerRightOpen,
  getModal,
  isModalVisible,
};
