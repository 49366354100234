import { takeLatest, put, select } from 'redux-saga/effects';
import differenceInHours from 'date-fns/differenceInHours';
import { sprintf } from 'sprintf-js';
import fromUnixTime from 'date-fns/fromUnixTime';
import map from 'lodash/map';
import difference from 'lodash/difference';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import first from 'lodash/first';
import size from 'lodash/size';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import find from 'lodash/find';
import slice from 'lodash/slice';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';

import { MessageType, TDailySuggestion } from '../../types';

import {
  DailySuggestionsCheckActionType,
  DailySuggestionsAddPayload,
  DailySuggestionsUpdatePayload,
  actionTypes as dailySuggestionsActionTypes,
  actions as dailySuggestionsActions,
  selectors as dailySuggestionsSelectors,
} from '../state/dailySuggestions';
import { selectors as messagesSelectors } from '../state/messages';
import { selectors as trendsSelectors } from '../state/trends';
import { selectors as treatmentsSelectors } from '../state/treatments';
import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import { selectors as settingsSelectors } from '../state/settings';
import { selectors as questionnairesSelectors } from '../state/questionnaires';
import { selectors as appointmentsSelectors } from '../state/appointments';
import { selectors as notesSelectors } from '../state/notes';
import { selectors as photosSelectors } from '../state/photos';
import getProfileProgress from '../selectors/getProfileProgress';
import getUnixTime from '../utils/getUnixTime';
import localConfig from '../config';

import { suggestions } from '../../data/system-document.json';

function* triggers(ids: string[], close = false) {
  if (isEmpty(ids)) {
    return false;
  }

  const updateActions: DailySuggestionsUpdatePayload = [];
  const addActions: DailySuggestionsAddPayload = [];

  for (let i = 0; i < size(ids); i += 1) {
    const id = get(ids, [i]);
    const suggestion = find(suggestions, { id });
    const dailySuggestionsGetByIdSelector = dailySuggestionsSelectors.makeGetById();

    const dailySuggestion: TDailySuggestion = yield select(dailySuggestionsGetByIdSelector, id);

    // Skip non-existent suggestion
    if (!suggestion) {
      continue; // eslint-disable-line no-continue
    }

    const { trigger, order } = suggestion;

    const update = () => (
      updateActions.push({
        ...dailySuggestion,
        id,
        closedAt: getUnixTime(),
        closed: true,
      })
    );

    const add = (params?: any) => (
      addActions.push({
        id,
        createdAt: getUnixTime(),
        order,
        closed: false,
        params,
      })
    );

    // welcomeMessage suggestion
    if (trigger === 'welcomeMessage') {
      const messages: MessageType[] = yield select(messagesSelectors.getAll);
      const message = last(messages);

      if (isEmpty(message)) {
        continue; // eslint-disable-line no-continue
      }

      const { readAt, id: messageId } = message as MessageType;

      // Close open suggestion if message has been read
      if (close) {
        if (readAt) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      // Message hasn't been read, add suggestion
      if (!readAt) {
        add({ messageId });
      }
    }

    // addTrends suggestion
    if (trigger === 'addTrends') {
      const count: number = yield select(trendsSelectors.count);

      if (close) {
        if (count > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (count === 0) {
        add();
      }
    }

    // addTreatment suggestion
    if (trigger === 'addTreatment') {
      const count: number = yield select(treatmentsSelectors.count);

      if (close) {
        if (count > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (count === 0) {
        add();
      }
    }

    if (trigger === 'addAudioRecording') {
      const count: number = yield select(audioRecordingsSelectors.count);

      if (close) {
        if (count > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (count === 0) {
        add();
      }
    }

    // addFavoriteTrends suggestion
    if (trigger === 'addFavoriteTrends') {
      const favoriteTrendIds: string[] = yield select(
        settingsSelectors.getFavoriteTrendIndicatorIds,
      );
      const count = size(favoriteTrendIds);

      if (close) {
        if (count > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (count === 0) {
        add();
      }
    }

    // addQuestionnaire suggestion
    if (trigger === 'addQuestionnaire') {
      const count: number = yield select(questionnairesSelectors.count);

      if (close) {
        if (count > 1) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      // New users have a default questionnaire
      if (count === 1) {
        add();
      }
    }

    // addDiaryItem suggestion
    if (trigger === 'addDiaryItem') {
      const appointmentsCount: number = yield select(appointmentsSelectors.count);
      const notesCount: number = yield select(notesSelectors.count);
      const photosCount: number = yield select(photosSelectors.count);
      const audioRecordingsCount: number = yield select(audioRecordingsSelectors.count);

      if (close) {
        if (
          appointmentsCount > 0
          || notesCount > 0
          || photosCount > 0
          || audioRecordingsCount > 0
        ) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (
        appointmentsCount === 0
        && notesCount === 0
        && photosCount === 0
        && audioRecordingsCount === 0
      ) {
        add();
      }
    }

    // viewProfileComplete suggestion
    if (trigger === 'viewProfileComplete') {
      const views = 0;
      const { completed: profileCompleted } = yield select(getProfileProgress);

      if (close) {
        if (profileCompleted || views > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (!profileCompleted && views === 0) {
        add();
      }
    }

    // viewTrendsSettings suggestion
    if (trigger === 'viewTrendsSettings') {
      const views = 0;

      if (close) {
        if (views > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (views === 0) {
        add();
      }
    }

    // viewGlossary suggestion
    if (trigger === 'viewGlossary') {
      const views = 0;

      if (close) {
        if (views > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (views === 0) {
        add();
      }
    }

    // viewUsefulLinks suggestion
    if (trigger === 'viewUsefulLinks') {
      const views = 0;

      if (close) {
        if (views > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (views === 0) {
        add();
      }
    }

    // viewQuestionnaires suggestion
    if (trigger === 'viewQuestionnaires') {
      const views = 0;
      const profileCompleted: boolean = yield select(settingsSelectors.getGeneralProfileCompleted);

      if (close) {
        if (profileCompleted && views > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (profileCompleted && views === 0) {
        add();
      }
    }

    // viewTreatmentReport suggestion
    if (trigger === 'viewTreatmentReport') {
      const views = 0;
      const profileCompleted: boolean = yield select(settingsSelectors.getGeneralProfileCompleted);

      if (close) {
        if (profileCompleted && views > 0) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (profileCompleted && views === 0) {
        add();
      }
    }

    // viewProfileQuestionCategories suggestion
    if (trigger === 'viewProfileQuestionCategories') {
      const views = 0;
      const profileCompleted: boolean = yield select(settingsSelectors.getGeneralProfileCompleted);

      if (close) {
        if (profileCompleted && views >= 2) {
          update();
        }

        continue; // eslint-disable-line no-continue
      }

      if (profileCompleted && views < 2) {
        add();
      }
    }
  }

  if (!isEmpty(updateActions)) {
    yield put(dailySuggestionsActions.update(updateActions));
  }

  if (!isEmpty(addActions)) {
    yield put(dailySuggestionsActions.add(addActions));
  }

  if (__DEV__) {
    console.log(sprintf('[dailySuggestions.triggers] %s update(s)', size(updateActions))); // eslint-disable-line no-console
    console.log(sprintf('[dailySuggestions.triggers] %s addition(s)', size(addActions))); // eslint-disable-line no-console
  }

  return true;
}

function* check(action: DailySuggestionsCheckActionType) {
  const { payload } = action;

  // Check if test mode is enabled
  const test = payload === true;

  const dailySuggestions: TDailySuggestion[] = yield select(dailySuggestionsSelectors.getAll);

  const openDailySuggestions = filter(dailySuggestions, { closed: false });
  const openDailySuggestionIds = map(openDailySuggestions, 'id');
  const totalOpenDailySuggestions = size(openDailySuggestions);

  let maxNewDailySuggestions = localConfig.dailySuggestions.max - totalOpenDailySuggestions;

  // Allow all suggestions in test mode
  if (test) {
    maxNewDailySuggestions = size(suggestions);
  }

  if (!isEmpty(openDailySuggestionIds)) {
    if (__DEV__) {
      console.log('[dailySuggestions.check] Execute triggers for open daily suggestions, close if needed'); // eslint-disable-line no-console
    }

    // Close daily suggestion if the trigger condition changed
    yield triggers(openDailySuggestionIds, true);
  }

  // Only display a maximum of 5 daily suggestions
  // There is no maximum in test mode
  if (totalOpenDailySuggestions >= localConfig.dailySuggestions.max) {
    if (__DEV__) {
      console.log('[dailySuggestions.check] Open daily suggestions >= 5, skipping'); // eslint-disable-line no-console
    }

    return false;
  }

  // Get last created daily suggestions
  const latestDailySuggestion = first(orderBy(dailySuggestions, ['createdAt'], ['desc']));

  // Make sure the last created daily suggestions was added > 24hrs ago.
  if (latestDailySuggestion) {
    const { createdAt } = latestDailySuggestion;
    const hoursAgo = differenceInHours(new Date(), fromUnixTime(createdAt));

    // Ignore intervalInHours when in test mode
    if (!test && hoursAgo <= localConfig.dailySuggestions.intervalInHours) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(sprintf(
          '[dailySuggestions.check] Latest daily suggestion was added <= %s hours ago, skipping',
          localConfig.dailySuggestions.intervalInHours,
        ));
      }

      return false;
    }
  }

  // Get new suggestion Ids
  const suggestionIds = uniq(map(orderBy(suggestions, ['order'], ['asc']), 'id'));
  const dailySuggestionIds = uniq(map(dailySuggestions, 'id'));

  const newSuggestionIds = (
    slice(difference(suggestionIds, dailySuggestionIds), 0, maxNewDailySuggestions)
  );

  // Return if there are no new suggestions
  if (size(newSuggestionIds) === 0) {
    if (__DEV__) {
      console.log('[dailySuggestions.check] No new suggestions available'); // eslint-disable-line no-console
    }

    return false;
  }

  if (__DEV__) {
    console.log('[dailySuggestions.check] Executing triggers for new suggestions'); // eslint-disable-line no-console
  }

  // Execute triggers for new suggestions
  yield triggers(newSuggestionIds);

  return true;
}

export default function* watchDailySuggestions() {
  yield takeLatest(dailySuggestionsActionTypes.CHECK, check);
}
