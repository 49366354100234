import difference from 'lodash/difference';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import union from 'lodash/union';
import without from 'lodash/without';

const addItem = (array: any[], item: any) => {
  if (includes(array, item)) {
    return array;
  }

  return union(array, [item]);
};

const addItems = (array: any[], items: any[]) => {
  const itemsToAdd = difference(items, array);

  if (isEmpty(itemsToAdd)) {
    return array;
  }

  return union(array, itemsToAdd);
};

const removeItem = (array: any[], item: any) => {
  if (!includes(array, item)) {
    return array;
  }

  return without(array, item);
};

const removeItems = (array: any[], items: any[]) => {
  const itemsToRemove = intersection(items, array);

  if (isEmpty(itemsToRemove)) {
    return array;
  }

  return difference(array, itemsToRemove);
};

export {
  addItem,
  addItems,
  removeItem,
  removeItems,
};
