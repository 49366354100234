import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import * as path from 'svg-path-properties';
import first from 'lodash/first';
import last from 'lodash/last';
import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import maxBy from 'lodash/maxBy';

import { TGraphData } from '../../types';

const getStartDate = (data: TGraphData[]) => {
  const { date } = first(data) as TGraphData;

  return date;
};

const getEndDate = (data: TGraphData[]) => {
  const { date } = last(data) as TGraphData;

  return date;
};

const getScaleX = (data: TGraphData[], width: number, horizontalPadding: number) => {
  const startDate = getStartDate(data);
  const endDate = getEndDate(data);

  return d3Scale
    .scaleTime()
    .domain([startDate, endDate])
    .range([horizontalPadding, width - horizontalPadding]);
};

const getScaleY = (height: number, min: number, max: number, verticalPadding: number) => (
  d3Scale
    .scaleLinear()
    .domain([min, max])
    .range([height - verticalPadding, verticalPadding])
);

const getLine = (data: TGraphData[], scaleX: Function, scaleY: Function) => {
  const line = d3Shape
    .line()
    .x((d) => scaleX(first(d) as number))
    .y((d) => scaleY(last(d) as number))
    .curve(d3Shape.curveMonotoneX)(map(data, ({ date, value }) => [date, value]));

  if (!line) {
    return {
      line: null,
      properties: null,
    };
  }

  const properties = path.svgPathProperties(line);

  return {
    line,
    properties,
  };
};

const mathLog = (value: number) => {
  // Math.log(0) returns -Infinity, so let's pick the lowest value
  // that returns something meaningful
  if (value === 0) {
    return Math.log(0.1);
  }

  return Math.log(value);
};

const getLogData = (graphData: TGraphData[], xAxisData: TGraphData[], max: number, min: number) => {
  // Get the maximum value of the graph data
  // Note that we add 10%
  const maxValue = toNumber(maxBy(map(graphData, 'value'))) * 1.1;

  // If maxValue <= max, return all data as-is
  if (maxValue <= max) {
    return {
      graphData,
      xAxisData,
      maxValue,
      // We can safely return 0 here, as there we don't Math.log on the data
      minValue: min,
    };
  }

  // If max > max, use Math.log on all values
  // Note that we also use Math.log for maxValue
  return {
    graphData: map(graphData, (data) => ({
      ...data,
      value: mathLog(data.value),
    })),
    xAxisData: map(xAxisData, (data) => ({
      ...data,
      value: mathLog(data.value),
    })),
    maxValue: mathLog(maxValue),
    minValue: mathLog(min),
  };
};

export {
  getStartDate,
  getEndDate,
  getScaleX,
  getScaleY,
  getLine,
  getLogData,
};
