import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useTranslation } from 'react-i18next';

import { PushNotificationType } from 'types';
import { AppState } from '../state/reducers';

import isOfType from '../utils/isOfType';
import { selectors as messagesSelectors } from '../state/messages';
import { selectors as pushNotificationsSelectors } from '../state/notifications';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import MessageListItem from '../components/MessageListItem';

type Params = {
  view: string;
  date?: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Messages = () => {
  const { t } = useTranslation();

  const { view, date } = useParams<Params>();

  const history = useHistory();

  const memoizedGetSelector = useMemo(
    messagesSelectors.makeGetAllByViewAndDate,
    [],
  );

  const messages = useSelector(
    (state: AppState) => memoizedGetSelector(state, view, date, true),
  );

  const memoizePushNotificationsGetAllByViewAndDateSelector = useMemo(
    pushNotificationsSelectors.makeGetAllByViewAndDate,
    [],
  );

  const pushNotifications = useSelector(
    (state: AppState) => memoizePushNotificationsGetAllByViewAndDateSelector(
      state,
      view,
      date,
    ),
  );

  const pushAndMessages = [...pushNotifications, ...messages].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('messages')} showRightButton />
          <button
            type="button"
            className="navigation accent back"
            onClick={history.goBack}
          >
            &lt; {t('back')}
          </button>
          <section className={isEmpty(pushAndMessages) ? 'diary-item' : 'list'}>
            {
              !isEmpty(pushAndMessages)
                ? map(pushAndMessages, (message, index) => {
                  const isPushNotification = isOfType<PushNotificationType>(message, 'showInMailbox');
                  return (
                    <MessageListItem
                      message={message}
                      key={message.id}
                      divider={(index + 1) !== size(pushAndMessages)}
                      isPushNotification={isPushNotification}
                    />
                  );
                })
                : <span className="body light">{t('messages:empty-state-no-messages')}</span>
            }
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Messages;
