import React, { useMemo } from 'react';
import Chart from 'react-google-charts';
import Color from 'color';
import { ReactGoogleChartEvent } from 'react-google-charts/dist/types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import find from 'lodash/find';
import first from 'lodash/first';
import nth from 'lodash/nth';
import isEmpty from 'lodash/isEmpty';
import round from 'lodash/round';
import size from 'lodash/size';

import { AppState } from '../../state/reducers';
import { TTreatmentType, TTreatment } from '../../../types';
import { TreatmentPropType } from '../../../propTypes';

import Button from '../Button';
import { selectors as treatmentTypesSelectors } from '../../state/treatmentTypes';
import getTreatmentTitle from '../../utils/getTreatmentTitle';
import getTreatmentColor from '../../utils/getTreatmentColor';
import getUnixTime from '../../utils/getUnixTime';
import toDate from '../../utils/toDate';
import isOfType from '../../utils/isOfType';

type TPaletteColor = {
  color: string;
  dark: string;
  light: string;
};

type Props = {
  treatments: TTreatment[];
};

const propTypes = {
  treatments: TreatmentPropType.isRequired,
};

const barHeight = 30;
const footerHeight = 40;

const GanttChart = (props: Props) => {
  const {
    treatments,
  } = props;

  const history = useHistory();

  const { t, i18n } = useTranslation();

  const memoizedTreatmentTypesGetByLocaleSelector = useMemo(
    treatmentTypesSelectors.makeGetByLocale,
    [],
  );

  const treatmentTypes = useSelector(
    (state: AppState) => (
      memoizedTreatmentTypesGetByLocaleSelector(state, i18n.language)
    ),
  );

  if (!isOfType<TTreatmentType[]>(treatmentTypes)) {
    return null;
  }

  const columns = [
    { type: 'string', label: 'Treatment ID' },
    { type: 'string', label: 'Treatment Name' },
    { type: 'string', label: 'Treatment Type' },
    { type: 'date', label: 'Start Date' },
    { type: 'date', label: 'End Date' },
    { type: 'number', label: 'Duration1' },
    { type: 'number', label: 'Percent Complete' },
    { type: 'string', label: 'Dependencies' },
  ];

  const palette: TPaletteColor[] = [];

  const rows = map(treatments, (treatment) => {
    const { id, startDateTime, endDateTime } = treatment;

    const now = getUnixTime();
    const start = getUnixTime(startDateTime);
    const end = getUnixTime(endDateTime);

    const range = Math.max(0, (end - start));
    const diff = Math.max(0, (end - now));

    let percentage = 0;

    if (range === 0 || diff === 0) {
      percentage = 100;
    } else if (start >= now) {
      percentage = 0;
    } else {
      percentage = round(100 - ((diff / range) * 100));
    }

    const title = getTreatmentTitle(treatment, treatmentTypes, i18n.language);
    const color = getTreatmentColor(treatment, treatmentTypes);

    if (!find(palette, { color })) {
      palette.push({
        color,
        dark: Color(color).darken(0.5).hex(),
        light: Color(color).lighten(0.5).hex(),
      });
    }

    return [
      id,
      title,
      title,
      toDate(startDateTime),
      toDate(endDateTime),
      null,
      percentage,
      null,
    ];
  });

  const data = [
    columns,
    ...rows,
  ];

  const options = {
    height: (size(treatments) * barHeight) + footerHeight,
    gantt: {
      trackHeight: 30,
      criticalPathEnabled: false,
      sortTasks: false,
      palette,
      labelStyle: {
        fontName: 'Avenir Next, Avenir, Nunito, sans-serif',
      },
    },
  };

  const chartEvents: ReactGoogleChartEvent[] = [
    {
      eventName: 'select',
      callback({ chartWrapper }) {
        const selection = first(chartWrapper.getChart().getSelection()) as any;

        if (isEmpty(selection)) {
          return false;
        }

        const { row } = selection;
        const treatmentId = first(nth(rows, row));

        if (!treatmentId) {
          return false;
        }

        return history.push(`/treatments/${treatmentId}`);
      },
    },
  ];

  return (
    <section className="gantt-chart-container">
      <Chart
        width="100%"
        chartType="Gantt"
        loader={<div>{t('loading')}</div>}
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
      <section className="row end">
        <Button
          labelText={t('add-treatment')}
          size="medium"
          onClick={() => history.push('/treatments/add')}
        />
      </section>
    </section>
  );
};

GanttChart.propTypes = propTypes;

export default GanttChart;
