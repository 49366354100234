import React, { useState, useMemo, useEffect } from 'react';
import Calendar from 'react-calendar';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import formatISO from 'date-fns/formatISO';
import startOfDay from 'date-fns/startOfDay';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

import { PushNotificationType } from 'types';
import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import Tabs from '../components/Tabs';
import Drawer from '../components/Drawer';
import DiaryToday from '../components/DiaryToday';
import DiaryWeek from '../components/DiaryWeek';
import DiaryMonth from '../components/DiaryMonth';
import DiaryAll from '../components/DiaryAll';
import DrawerRightHeader from '../components/DrawerRightHeader';
import AppointmentListItem from '../components/AppointmentListItem';
import NoteListItem from '../components/NoteListItem';
import MessageListItem from '../components/MessageListItem';
import PhotoListItem from '../components/PhotoListItem';
import AudioRecordingListItem from '../components/AudioRecordingListItem';
import DiaryCalendarTile from '../components/DiaryCalendarTile';
import { selectors as appointmentsSelectors } from '../state/appointments';
import { selectors as notesSelectors } from '../state/notes';
import { selectors as photosSelectors } from '../state/photos';
import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import { selectors as messagesSelectors } from '../state/messages';
import { selectors as pushNotificationsSelectors } from '../state/notifications';
import dateFormat from '../utils/dateFormat';
import isOfType from '../utils/isOfType';
import '../css/Diary.css';

type Params = {
  view?: 'today' | 'week' | 'month' | 'alll';
};

const DrawerRight = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { i18n, t } = useTranslation();

  const today = formatISO(startOfDay(selectedDate));

  const memoizedAppointmentsGetByDateSelector = useMemo(
    appointmentsSelectors.makeGetByDate,
    [],
  );

  const appointments = useSelector(
    (state: AppState) => memoizedAppointmentsGetByDateSelector(state, today),
  );

  const memoizedNotesGetByDateSelector = useMemo(
    notesSelectors.makeGetByDate,
    [],
  );

  const notes = useSelector(
    (state: AppState) => memoizedNotesGetByDateSelector(state, today),
  );

  const memoizedMessagsGetByDateSelector = useMemo(
    messagesSelectors.makeGetByDate,
    [],
  );

  const messages = useSelector(
    (state: AppState) => memoizedMessagsGetByDateSelector(state, today),
  );

  const memoizePushNotificationsGetByDateSelector = useMemo(
    pushNotificationsSelectors.makeGetByDate,
    [],
  );

  const pushNotifications = useSelector(
    (state: AppState) => memoizePushNotificationsGetByDateSelector(state, today),
  );

  const memoizedPhotosGetByDateSelector = useMemo(
    photosSelectors.makeGetByDate,
    [],
  );

  const photos = useSelector(
    (state: AppState) => memoizedPhotosGetByDateSelector(state, today),
  );

  const memoizedAudioRecordingsGetByDateSelector = useMemo(
    audioRecordingsSelectors.makeGetByDate,
    [],
  );

  const audioRecordings = useSelector(
    (state: AppState) => memoizedAudioRecordingsGetByDateSelector(state, today),
  );

  const pushAndMessages = [...pushNotifications, ...messages].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  const empty = (
    isEmpty(appointments)
    && isEmpty(notes)
    && isEmpty(pushAndMessages)
    && isEmpty(photos)
    && isEmpty(audioRecordings)
  );

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        <section className="drawer-item">
          <h1 className="heading">{t('calendar')}</h1>
          <Calendar
            value={selectedDate}
            onChange={(date) => setSelectedDate(date as Date)}
            view="month"
            showNeighboringMonth={false}
            minDetail="month"
            tileContent={({ date }) => <DiaryCalendarTile date={date} />}
          />
          <h2 className="button accent">{dateFormat(i18n.language, selectedDate.toISOString(), 'EEEE, dd LLLL yyyy')}</h2>
          {empty ? <p className="body light">{t('empty-state-no-activity-today')}</p> : null}
          {map(appointments, (appointment) => (
            <AppointmentListItem appointment={appointment} key={appointment.id} />
          ))}
          {map(notes, (note) => <NoteListItem note={note} key={note.id} />)}
          {map(pushAndMessages, (message) => {
            const isPushNotification = isOfType<PushNotificationType>(message, 'showInMailbox');
            return (
              <MessageListItem
                message={message}
                key={message.id}
                isPushNotification={isPushNotification}
              />
            );
          })}
          {map(photos, (photo) => <PhotoListItem photo={photo} key={photo.id} />)}
          {map(audioRecordings, (audioRecording) => (
            <AudioRecordingListItem audioRecording={audioRecording} key={audioRecording.id} />
          ))}
        </section>
      </section>
    </Drawer>
  );
};

const Diary = () => {
  const { view } = useParams<Params>();

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('today');

  useEffect(() => {
    if (view) {
      setActiveTab(view);
    }
  }, [view, setActiveTab]);

  const tabs = [
    {
      name: 'today',
      labelText: t('today'),
    },
    {
      name: 'week',
      labelText: t('week'),
    },
    {
      name: 'month',
      labelText: t('month'),
    },
    {
      name: 'all',
      labelText: t('all'),
    },
  ];

  let content;

  switch (activeTab) {
    case 'today': {
      content = <DiaryToday />;
      break;
    }
    case 'week': {
      content = <DiaryWeek />;
      break;
    }
    case 'month': {
      content = <DiaryMonth />;
      break;
    }
    case 'all': {
      content = <DiaryAll />;
      break;
    }
    default: {
      content = <DiaryToday />;
      break;
    }
  }

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title="Diary" showRightButton />
          <Tabs tabs={tabs} onTabClick={(tab) => setActiveTab(tab.name)} activeTab={activeTab} />
          {content}
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Diary;
