import * as actionTypes from './actionTypes';
import {
  SettingsTrendIndicatorsActionTypes,
  SettingsGeneralUpdateActionType,
  SettingsTrendIndicatorsUpdatePayload,
  SettingsTrendIndicatorsUpdateDisabledPayload,
  SettingsTrendIndicatorsUpdateFavoritePayload,
  SettingsTrendIndicatorsUpdateOrderPayload,
  SettingsGeneralUpdatePayload,
  AccountStatusType,
  ChangeAccountStatusActionType,
} from './types';

const trendIndicatorsUpdate = (
  payload: SettingsTrendIndicatorsUpdatePayload,
): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_UPDATE,
  payload,
});

const trendIndicatorsUpdateDisabled = (
  payload: SettingsTrendIndicatorsUpdateDisabledPayload,
): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_UPDATE_DISABLED,
  payload,
});

const trendIndicatorsUpdateFavorite = (
  payload: SettingsTrendIndicatorsUpdateFavoritePayload,
): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_UPDATE_FAVORITE,
  payload,
});

const trendIndicatorsUpdateOrder = (
  payload: SettingsTrendIndicatorsUpdateOrderPayload,
): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_UPDATE_ORDER,
  payload,
});

const trendIndicatorsClear = (): SettingsTrendIndicatorsActionTypes => ({
  type: actionTypes.TREND_INDICATORS_CLEAR,
});

const generalUpdate = (
  payload: SettingsGeneralUpdatePayload,
): SettingsGeneralUpdateActionType => ({
  type: actionTypes.GENERAL_UPDATE,
  payload,
});

const invitationCodesAdd = (payload: string[]) => ({
  type: actionTypes.INVITATION_CODES_ADD,
  payload,
});

const invitationCodesRemove = (payload: string[]) => ({
  type: actionTypes.INVITATION_CODES_REMOVE,
  payload,
});

const hospitalGroupAccessCodesAdd = (payload: string[]) => ({
  type: actionTypes.HOSPITAL_GROUP_ACCESS_CODES_ADD,
  payload,
});

const hospitalGroupAccessCodesRemove = (payload: string[]) => ({
  type: actionTypes.HOSPITAL_GROUP_ACCESS_CODES_REMOVE,
  payload,
});

const bannersTrendsNext = () => ({
  type: actionTypes.BANNERS_TRENDS_NEXT,
});

const clear = () => ({
  type: actionTypes.CLEAR,
});

const changeAccountStatus = (payload: AccountStatusType): ChangeAccountStatusActionType => ({
  type: actionTypes.CHANGE_STATUS,
  payload,
});

export {
  trendIndicatorsUpdate,
  trendIndicatorsUpdateDisabled,
  trendIndicatorsUpdateFavorite,
  trendIndicatorsUpdateOrder,
  trendIndicatorsClear,
  generalUpdate,
  invitationCodesAdd,
  invitationCodesRemove,
  hospitalGroupAccessCodesAdd,
  hospitalGroupAccessCodesRemove,
  bannersTrendsNext,
  clear,
  changeAccountStatus,
};
