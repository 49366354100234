const prefix = 'API';

const AUTH_REGISTER = `${prefix}/AUTH_REGISTER`;
const DEACTIVATE_ACCOUNT = `${prefix}/DEACTIVATE_ACCOUNT`;
const DELETE_ACCOUNT = `${prefix}/DELETE_ACCOUNT`;
const AUTH_LOGIN = `${prefix}/AUTH_LOGIN`;
const AUTH_RESET = `${prefix}/AUTH_RESET`;
const AUTH_EMAIL_VERIFY = `${prefix}/AUTH_EMAIL_VERIFY`;
const AUTH_PASSWORD = `${prefix}/AUTH_PASSWORD`;
const AUTH_PASSWORD_RESET = `${prefix}/AUTH_PASSWORD_RESET`;
const VALIDATE_INVITE = `${prefix}/VALIDATE_INVITE`;
const BLOB_POST = `${prefix}/BLOB_POST`;
const SHARE_TRENDS = `${prefix}/SHARE_TRENDS`;
const SHARE_CUSTOM_TRENDS_BY_ID = `${prefix}/SHARE_CUSTOM_TRENDS_BY_ID`;
const DOWNLOAD_REPORT = `${prefix}/DOWNLOAD_REPORT`;

export {
  AUTH_REGISTER,
  AUTH_LOGIN,
  AUTH_RESET,
  AUTH_EMAIL_VERIFY,
  AUTH_PASSWORD,
  AUTH_PASSWORD_RESET,
  VALIDATE_INVITE,
  BLOB_POST,
  SHARE_TRENDS,
  DOWNLOAD_REPORT,
  DEACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  SHARE_CUSTOM_TRENDS_BY_ID,
};
