import React, { ReactNode, Fragment } from 'react';
import map from 'lodash/map';
import includes from 'lodash/includes';
import union from 'lodash/union';
import without from 'lodash/without';

import '../css/Accordion.css';

type Props = {
  sections: any[];
  renderContent: (item: any, isActive: boolean) => ReactNode;
  renderHeader: (item: any, isActive: boolean) => ReactNode;
  activeSections: number[];
  onChange: (activeSections: number[]) => void;
  keyExtractor: (item: any) => string;
};

const Accordion = (props: Props) => {
  const {
    sections,
    renderContent,
    renderHeader,
    activeSections,
    onChange,
    keyExtractor,
  } = props;

  return (
    <section className="accordion">
      {map(sections, (item, index) => {
        const isActive = includes(activeSections, index);

        const handleChange = () => {
          if (!isActive) {
            return onChange(union(activeSections, [index]));
          }

          return onChange(without(activeSections, index));
        };

        return (
          <Fragment key={keyExtractor(item)}>
            <button type="button" onClick={handleChange}>
              {renderHeader(item, isActive)}
            </button>
            {renderContent(item, isActive)}
          </Fragment>
        );
      })}
    </section>
  );
};

export default Accordion;
