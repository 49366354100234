import { StyleSheet as RNStyleSheet, TextStyle as RNTextStyle } from 'react-native';

import Colors from '../../theme/Colors';

interface Styles {
  [key: string]: RNTextStyle,
  alphabet: RNTextStyle,
  body: RNTextStyle,
  'body-light': RNTextStyle,
  'body-lighter': RNTextStyle,
  'body-accent': RNTextStyle,
  'body-accent-secondary': RNTextStyle,
  'body-contrast': RNTextStyle,
  'body-error': RNTextStyle,
  'button': RNTextStyle,
  'button-accent': RNTextStyle,
  'button-contrast': RNTextStyle,
  'button-accent-secondary': RNTextStyle,
  'divider': RNTextStyle,
  'heading': RNTextStyle,
  'heading-accent': RNTextStyle,
  'heading-large': RNTextStyle,
  'heading-large-contrast': RNTextStyle,
  'heading-contrast': RNTextStyle,
  'heading-secondary': RNTextStyle,
  'heading-secondary-contrast': RNTextStyle,
  navigation: RNTextStyle,
  'navigation-contrast': RNTextStyle,
  'navigation-emphasized': RNTextStyle,
  'navigation-emphasized-contrast': RNTextStyle,
  'numeric-input': RNTextStyle,
}

export default RNStyleSheet.create<Styles>({
  alphabet: {
    fontFamily: 'Avenir-Heavy',
    color: Colors.primary,
    fontSize: 13,
    lineHeight: 18,
  },
  body: {
    fontFamily: 'Avenir-Book',
    color: Colors.black,
    fontSize: 16,
    lineHeight: 20,
  },
  'body-light': {
    fontFamily: 'Avenir-Book',
    color: Colors.darkGray,
    fontSize: 16,
    lineHeight: 20,
  },
  'body-lighter': {
    fontFamily: 'Avenir-Book',
    color: Colors.lightGray,
    fontSize: 16,
    lineHeight: 20,
  },
  'body-accent': {
    fontFamily: 'Avenir-Book',
    color: Colors.primary,
    fontSize: 16,
    lineHeight: 20,
  },
  'body-accent-secondary': {
    fontFamily: 'Avenir-Book',
    color: Colors.secondary,
    fontSize: 16,
    lineHeight: 20,
  },
  'body-contrast': {
    fontFamily: 'Avenir-Book',
    color: Colors.white,
    fontSize: 16,
    lineHeight: 20,
  },
  'body-error': {
    fontFamily: 'Avenir-Book',
    color: Colors.errorRed,
    fontSize: 16,
    lineHeight: 20,
  },
  button: {
    fontFamily: 'Avenir-Heavy',
    color: Colors.black,
    fontSize: 16,
    lineHeight: 20,
  },
  'button-accent': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.primary,
    fontSize: 16,
    lineHeight: 20,
  },
  'button-accent-secondary': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.secondary,
    fontSize: 16,
    lineHeight: 20,
  },
  'button-contrast': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.white,
    fontSize: 16,
    lineHeight: 20,
  },
  divider: {
    fontFamily: 'Avenir-Heavy',
    color: Colors.darkGray,
    fontSize: 14,
    lineHeight: 18,
  },
  heading: {
    fontFamily: 'Avenir-Heavy',
    color: Colors.black,
    fontSize: 18,
    lineHeight: 23,
  },
  'heading-accent': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.primary,
    fontSize: 18,
    lineHeight: 23,
  },
  'heading-large': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.black,
    fontSize: 21,
    lineHeight: 28,
  },
  'heading-large-contrast': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.white,
    fontSize: 21,
    lineHeight: 28,
  },
  'heading-contrast': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.white,
    fontSize: 18,
    lineHeight: 23,
  },
  'heading-secondary': {
    fontFamily: 'Avenir-Medium',
    color: Colors.black,
    fontSize: 18,
    lineHeight: 23,
  },
  'heading-secondary-contrast': {
    fontFamily: 'Avenir-Medium',
    color: Colors.white,
    fontSize: 18,
    lineHeight: 23,
  },
  navigation: {
    fontFamily: 'Avenir-Book',
    color: Colors.primary,
    fontSize: 18,
    lineHeight: 23,
  },
  'navigation-contrast': {
    fontFamily: 'Avenir-Book',
    color: Colors.white,
    fontSize: 18,
    lineHeight: 23,
  },
  'navigation-emphasized': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.primary,
    fontSize: 18,
    lineHeight: 23,
  },
  'navigation-emphasized-contrast': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.white,
    fontSize: 18,
    lineHeight: 23,
  },
  'numeric-input': {
    fontFamily: 'Avenir-Heavy',
    color: Colors.black,
    fontSize: 28,
    lineHeight: 38,
  },
});
