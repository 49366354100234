const prefix = 'DAILY_SUGGESTIONS';

const ADD = `${prefix}/ADD`;
const UPDATE = `${prefix}/UPDATE`;
const CHECK = `${prefix}/CHECK`;
const CLEAR = `${prefix}/CLEAR`;

export {
  ADD,
  UPDATE,
  CHECK,
  CLEAR,
};
