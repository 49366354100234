import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import arrayMove from 'array-move';
import map from 'lodash/map';
import size from 'lodash/size';

import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import SettingTrendIndicatorListItem from '../components/SettingTrendIndicatorListItem';
import Footer from '../components/Footer';
import Button from '../components/Button';
import getAllTrendIndicatorsSelector from '../selectors/getAllTrendIndicators';
import { ReactComponent as IconArrowLeft } from '../assets/icons/ArrowLeft.svg';
import { ReactComponent as IconArrowRight } from '../assets/icons/ArrowRight.svg';
import Colors from '../theme/Colors';
import { actions as settingsActions } from '../state/settings';

type DragResult = {
  source: {
    index: number
  };
  destination?: {
    index: number
  };
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const TrendsSettings = () => {
  const { t } = useTranslation();

  const indicators = useSelector((state: AppState) => getAllTrendIndicatorsSelector(state));

  const dispatch = useDispatch();

  const history = useHistory();

  const onDragEnd = (result: DragResult) => {
    // Ignore when dropped outside the list
    if (!result.destination) {
      return false;
    }

    const data = arrayMove(
      indicators,
      result.source.index,
      result.destination.index,
    );

    const dataIds = map(data, 'id');
    const indicatorIds = map(indicators, 'id');

    // Only update order when there is a difference
    if (shallowEqual(dataIds, indicatorIds)) {
      return false;
    }

    return dispatch(settingsActions.trendIndicatorsUpdateOrder(dataIds));
  };

  return (
    <article className="page trends-settings row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('trends-settings')} />
          <header className="settings-header">
            <div className="row center">
              <IconArrowLeft fill={Colors.primary} className="icon large" />
              <p className="body">{t('trends:settings-favorites')}</p>
            </div>
            <div className="row center">
              <p className="body">{t('trends:settings-track')}</p>
              <IconArrowRight fill={Colors.primary} className="icon large" />
            </div>
          </header>
          <section className="list">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                    ref={provided.innerRef}
                  >
                    {map(indicators, (item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(p) => (
                          <div
                            ref={p.innerRef}
                            {...p.draggableProps} // eslint-disable-line react/jsx-props-no-spreading
                            {...p.dragHandleProps} // eslint-disable-line react/jsx-props-no-spreading
                          >
                            <SettingTrendIndicatorListItem
                              key={item.id}
                              indicator={item}
                              divider={(index + 1) !== size(indicators)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </section>
          <Button
            labelText={t('save')}
            size="medium"
            onClick={() => history.goBack()}
          />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default TrendsSettings;
