import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import { AppState } from '../state/reducers';
import { MessageType, PushNotificationType } from '../../types';

import '../css/Message.css';
import { actions as messagesActions, selectors as messagesSelectors } from '../state/messages';
import { actions as pushNotificationsActions, selectors as pushNotificationsSelectors } from '../state/notifications';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';

type Params = {
  messageId: string;
  isPushNotification?: string
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Message = () => {
  const { i18n, t } = useTranslation();

  const { messageId, isPushNotification } = useParams<Params>();

  const dispatch = useDispatch();

  const isPushMailbox = (isPushNotification === 'true');

  const memoizedMessagesGetByIdSelector = useMemo(
    messagesSelectors.makeGetById,
    [],
  );

  const message = (
    useSelector((state: AppState) => memoizedMessagesGetByIdSelector(state, messageId))
  ) as MessageType;

  const memoizedPushNotificationsGetByIdSelector = useMemo(
    pushNotificationsSelectors.makeGetById,
    [],
  );

  const pushNotification = (
    useSelector((state: AppState) => memoizedPushNotificationsGetByIdSelector(state, messageId))
  ) as PushNotificationType;

  useEffect(() => {
    if (isPushMailbox) {
      dispatch(pushNotificationsActions.setReadAt([pushNotification.id]));
    } else {
      dispatch(messagesActions.setReadAt([message.id]));
    }
  }, [dispatch, isPushMailbox, message, pushNotification]);

  const title = isPushMailbox ? get(pushNotification, ['title', i18n.language]) : get(message, ['title', i18n.language]) ?? message.title;
  const body = isPushMailbox ? get(pushNotification, ['body', i18n.language]) : get(message, ['body', i18n.language]) ?? message.body;

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('message')} showRightButton />
          <section className="message">
            <h1 className="heading-secondary">{title}</h1>
            <p className="body" dangerouslySetInnerHTML={{ __html: body }} />
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Message;
