import * as actionTypes from './actionTypes';
import {
  ApiAuthRegisterPayload,
  ApiAuthLoginPayload,
  ApiAuthResetPayload,
  ApiAuthEmailVerifyPayload,
  ApiAuthPasswordResetPayload,
  ApiAuthPasswordPayload,
  ApiAuthEmailVerifyActionType,
  ApiAuthPasswordResetActionType,
  ApiValidateInvitePayload,
  ApiBLOBPostPayload,
  ApiShareTrendsPayload,
  ApiDownloadReportPayload,
  ApiAuthRegisterActionType,
  ApiAuthLoginActionType,
  ApiAuthResetActionType,
  ApiAuthPasswordActionType,
  ApiValidateInviteActionType,
  ApiBLOBPostActionType,
  ApiShareTrendsActionType,
  ApiDownloadReportActionType,
  ApiDeactivateAccountPayload,
  ApiDeleteAccountPayload,
  ApiDeactivateAccountActionType,
  ApiDeleteAccountActionType, ApiShareCustomTrendsByIdPayload, ApiShareCustomTrendsByIdActionType,
} from './types';

const authRegister = (payload: ApiAuthRegisterPayload): ApiAuthRegisterActionType => ({
  type: actionTypes.AUTH_REGISTER,
  payload,
});

const authLogin = (payload: ApiAuthLoginPayload): ApiAuthLoginActionType => ({
  type: actionTypes.AUTH_LOGIN,
  payload,
});

const authReset = (payload: ApiAuthResetPayload): ApiAuthResetActionType => ({
  type: actionTypes.AUTH_RESET,
  payload,
});

const authEmailVerify = (payload: ApiAuthEmailVerifyPayload): ApiAuthEmailVerifyActionType => ({
  type: actionTypes.AUTH_EMAIL_VERIFY,
  payload,
});

const authPassword = (payload: ApiAuthPasswordPayload): ApiAuthPasswordActionType => ({
  type: actionTypes.AUTH_PASSWORD,
  payload,
});

const authPasswordReset = (
  payload: ApiAuthPasswordResetPayload,
): ApiAuthPasswordResetActionType => ({
  type: actionTypes.AUTH_PASSWORD_RESET,
  payload,
});

const validateInvite = (payload: ApiValidateInvitePayload): ApiValidateInviteActionType => ({
  type: actionTypes.VALIDATE_INVITE,
  payload,
});

const BLOBPost = (payload: ApiBLOBPostPayload): ApiBLOBPostActionType => ({
  type: actionTypes.BLOB_POST,
  payload,
});

const shareTrends = (payload: ApiShareTrendsPayload): ApiShareTrendsActionType => ({
  type: actionTypes.SHARE_TRENDS,
  payload,
});

const shareCustomTrendsById = (
  payload: ApiShareCustomTrendsByIdPayload,
): ApiShareCustomTrendsByIdActionType => ({
  type: actionTypes.SHARE_CUSTOM_TRENDS_BY_ID,
  payload,
});

const downloadReport = (payload: ApiDownloadReportPayload): ApiDownloadReportActionType => ({
  type: actionTypes.DOWNLOAD_REPORT,
  payload,
});

const deactivateAccount = (
  payload: ApiDeactivateAccountPayload,
): ApiDeactivateAccountActionType => ({
  type: actionTypes.DEACTIVATE_ACCOUNT,
  payload,
});

const deleteAccount = (
  payload: ApiDeleteAccountPayload,
): ApiDeleteAccountActionType => ({
  type: actionTypes.DELETE_ACCOUNT,
  payload,
});

export {
  authRegister,
  authLogin,
  authReset,
  authEmailVerify,
  authPassword,
  authPasswordReset,
  validateInvite,
  BLOBPost,
  shareTrends,
  shareCustomTrendsById,
  downloadReport,
  deactivateAccount,
  deleteAccount,
};
