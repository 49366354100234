import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import includes from 'lodash/includes';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { useSelector } from 'react-redux';
import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import Button from '../components/Button';
import Colors from '../theme/Colors';
import ListItem from '../components/ListItem';
import { List as TrendList } from '../components/TrendsGrid';
import Tabs from '../components/Tabs';
import TrendsShareModal from '../components/TrendsShareModal';
import { ReactComponent as IconSettings } from '../assets/icons/Settings.svg';
import { ReactComponent as IconShare } from '../assets/icons/Share.svg';
import '../css/Trends.css';
import { TrendIndicatorType } from '../../types';
import Checkbox from '../components/Checkbox';
import getLocalizedText from '../utils/getLocalizedText';
import i18n from '../services/i18n';
import { AppState } from '../state/reducers';
import getEnabledTrendIndicatorsSelector from '../selectors/getEnabledTrendIndicators';

type TDrawerRight = {
  trendIndicators: TrendIndicatorType[],
  disabledTrends: TrendIndicatorType[],
  clickCheckbox: (trendIndicator: TrendIndicatorType) => (e?: Event) => void
  selectAll: () => void,
  deselectAll: () => void,
  isCombinedGraph: boolean,
};

export type TGraphTab = 'separate' | 'combined';

const DrawerRight = ({
  selectAll,
  deselectAll,
  trendIndicators,
  disabledTrends,
  clickCheckbox,
  isCombinedGraph,
}: TDrawerRight) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const enabledTrends = filter(
    trendIndicators, (trendIndicator) => !includes(disabledTrends, trendIndicator),
  );
  const trendsForShare = map(enabledTrends, (trendIndicator) => trendIndicator.id);

  return (
    <>
      <Drawer position="right">
        <section>
          <DrawerRightHeader />
          <section className="drawer-item">
            <ListItem
              primaryText={t('trends-settings')}
              leftIcon={<IconSettings fill={Colors.primary} className="icon" />}
              divider
              to="/trends/settings"
            />
            <ListItem
              primaryText={t('share-trends')}
              leftIcon={<IconShare fill={Colors.primary} className="icon" />}
              onClick={() => setVisible(true)}
            />
          </section>
          <section>
            <h1 className="heading">
              {t('show-graphs')}
            </h1>
            <div className="select-button-wrapper">
              <Button onClick={selectAll} labelText={t('select-all')} />
              <Button onClick={deselectAll} labelText={t('deselect-all')} />
            </div>
            {trendIndicators.map((trendIndicator) => (

              <Checkbox
                additionalLeftIcon={isCombinedGraph && <div className="trend-legend-line" style={{ backgroundColor: trendIndicator.color }} />}
                key={trendIndicator.id}
                onClick={clickCheckbox(trendIndicator)}
                checked={!includes(disabledTrends, trendIndicator)}
                label={getLocalizedText(trendIndicator.title, i18n.language)}
              />
            ))}
          </section>
        </section>
      </Drawer>
      <TrendsShareModal
        type="id"
        data={{ trends: trendsForShare }}
        visible={visible}
        hideModal={() => setVisible(false)}
      />
    </>
  );
};

const Trends = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const [activeTab, setActiveTab] = useState('week');
  const [activeGraphTab, setActiveGraphTab] = useState<TGraphTab>('separate');
  const [disabledTrends, setDisabledTrends] = useState<TrendIndicatorType[]>([]);

  const tabs = [
    {
      name: 'week',
      labelText: t('week'),
    },
    {
      name: 'month',
      labelText: t('month'),
    },
    {
      name: 'year',
      labelText: t('year'),
    },
  ];

  const graphTabs: { name: TGraphTab, labelText: string }[] = [
    {
      name: 'separate',
      labelText: t('trendsDashboard:separate'),
    },
    {
      name: 'combined',
      labelText: t('trendsDashboard:combined'),
    },
  ];

  const enabledTrendIndicators = useSelector(
    (state: AppState) => getEnabledTrendIndicatorsSelector(state),
  ) as TrendIndicatorType[];

  const clickCheckbox = (trendIndicator: TrendIndicatorType) => (e?: Event) => {
    if (e) {
      e.stopPropagation();
    }
    setDisabledTrends((prev) => {
      if (includes(prev, trendIndicator)) {
        return filter(prev, (trend) => trend !== trendIndicator);
      }
      return [...prev, trendIndicator];
    });
  };

  const filteredTrends = enabledTrendIndicators.filter(
    (enabledTrend) => !includes(disabledTrends, enabledTrend),
  );

  const selectAll = () => {
    setDisabledTrends([]);
  };

  const deselectAll = () => {
    setDisabledTrends(enabledTrendIndicators);
  };

  return (
    <article className="page trends row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('trends')} showRightButton />
          <div className="trends-tabs-wrapper">
            <Tabs
              tabs={tabs}
              onTabClick={(tab) => setActiveTab(tab.name)}
              activeTab={activeTab}
            />
            <Tabs
              tabs={graphTabs}
              onTabClick={(tab) => setActiveGraphTab(tab.name as TGraphTab)}
              activeTab={activeGraphTab}
            />
          </div>
          <TrendList
            trendIndicators={filteredTrends}
            view={activeTab}
            type="all"
            combined={activeGraphTab === 'combined'}
          />
          <Button
            labelText={t('add-trends')}
            size="medium"
            onClick={() => history.push('/trends/add')}
          />
        </section>
        <Footer />
      </section>
      <DrawerRight
        trendIndicators={enabledTrendIndicators}
        disabledTrends={disabledTrends}
        clickCheckbox={clickCheckbox}
        selectAll={selectAll}
        deselectAll={deselectAll}
        isCombinedGraph={activeGraphTab === 'combined'}
      />
    </article>
  );
};

export default Trends;
