import React, { useState, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useHistory } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import trim from 'lodash/trim';

import '../css/Login.css';
import Colors from '../theme/Colors';
import { actions as apiActions } from '../state/api';
import { actions as UIActions } from '../state/ui';
import {
  passwordChange as passwordChangeSchema,
  password as passwordSchema,
} from '../utils/schemas';
import { formValidation, useFormFieldValidation } from '../utils/formValidation';
import defaultGet from '../utils/defaultGet';
import Button from '../components/Button';
import Modal from '../components/Modal';
import AnimatedTextInput from '../components/AnimatedTextInput';
import logo from '../assets/images/logo.svg';

type TFormErrors = {
  newPassword?: string;
  confirmNewPassword?: string;
};

type Params = {
  token: string;
};

const ResetPassword = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { token } = useParams<Params>();

  const [errors, setErrors] = useState<TFormErrors>({});
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useFormFieldValidation(
    passwordSchema,
    'newPassword',
    '',
    submitted,
    errors,
    setErrors,
  );

  const [confirmNewPassword, setConfirmNewPassword] = useFormFieldValidation(
    passwordSchema,
    'confirmNewPassword',
    '',
    submitted,
    errors,
    setErrors,
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    setSubmitted(true);
    setErrors({});

    if (!formValidation(passwordChangeSchema, { newPassword, confirmNewPassword }, setErrors)) {
      return false;
    }

    setLoading(true);

    return dispatch(apiActions.authPasswordReset({
      password: newPassword,
      password2: confirmNewPassword,
      token,
      onSuccess: () => {
        setLoading(false);
        setVisible(true);
      },
      onError: (error: Error) => {
        const errorCode = defaultGet(error, 'code', 'err_unknown');
        const text = t([`errorCodes.resetPassword.${errorCode}`, 'errorCodes.err_unknown']);

        dispatch(UIActions.addNotification({ text, type: 'error' }));

        setLoading(false);
      },
    }));
  };

  return (
    <article className="page reset-password unauth">
      <section className="content">
        <section className="image-container">
          <header>
            <img src={logo} alt="Owise" />
            <a href={t('urls.website')} className="button contrast">
              {t('back-to-owise', { url: trim(t('urls.website'), 'https://') })}
            </a>
          </header>
        </section>
        <form onSubmit={handleSubmit}>
          <h1 className="heading-large">{t('password-reset-title')}</h1>
          <AnimatedTextInput
            value={newPassword}
            labelText={t('new-password')}
            onChange={(event) => setNewPassword(event.currentTarget.value)}
            inputType="password"
            errorText={t(errors.newPassword as string)}
          />
          <AnimatedTextInput
            value={confirmNewPassword}
            labelText={t('confirm-password')}
            onChange={(event) => setConfirmNewPassword(event.currentTarget.value)}
            inputType="password"
            errorText={t(errors.confirmNewPassword as string)}
          />
          <Button buttonType="submit" disabled={loading} size="large">
            {
              loading
                ? <Loader type="TailSpin" color={Colors.white} height={20} width={20} />
                : t('save')
            }
          </Button>
          <Link to="/login" className="button accent">{t('login')}</Link>
        </form>
      </section>
      <Modal
        visible={visible}
        hideModal={() => setVisible(false)}
        actions={[
          {
            title: t('login'),
            onClick: () => {
              setVisible(false);
              history.push('/login');
            },
            primary: true,
          },
        ]}
      >
        <>
          <h1 className="heading">{t('password-changed')}</h1>
          <p className="body">{t('password-changed-description')}</p>
        </>
      </Modal>
    </article>
  );
};

export default ResetPassword;
