import { all } from 'redux-saga/effects';

import { watchSync } from './watchSync';
import watchApi from './watchApi';
import watchAuth from './watchAuth';
import watchUI from './watchUI';
import watchAudioRecordings from './watchAudioRecordings';
import watchPhotos from './watchPhotos';
import watchDailySuggestions from './watchDailySuggestions';
import watchProfileQuestionAnswers from './watchProfileQuestionAnswers';
import watchReports from './watchReports';

const DEV = process.env.NODE_ENV !== 'production';

export default function* rootSaga() {
  try {
    yield all([
      watchApi(),
      watchAuth(),
      watchSync(),
      watchUI(),
      watchAudioRecordings(),
      watchPhotos(),
      watchDailySuggestions(),
      watchProfileQuestionAnswers(),
      watchReports(),
    ]);
  } catch (error) {
    if (DEV) {
      console.log('[rootSaga]', 'catch', error); // eslint-disable-line no-console
    }
  }
}
