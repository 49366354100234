import { takeLatest, select, put } from 'redux-saga/effects';

import { ProfileQuestionType, TProfileQuestionAnswerState } from '../../types';

import { selectors as profileQuestionsSelectors } from '../state/profileQuestions';
import {
  actionTypes as profileQuestionAnswersActionTypes,
  selectors as profileQuestionAnswersSelectors,
} from '../state/profileQuestionAnswers';
import { actions as tagsActions } from '../state/tags';
import isOfType from '../utils/isOfType';
import getRiskProfile from '../utils/getRiskProfile';

function* riskProfile() {
  try {
    // TODO: Fix typings
    // @ts-ignore
    const questions: any = yield select(profileQuestionsSelectors.getAll);
    // @ts-ignore
    const answers: any = yield select(profileQuestionAnswersSelectors.getAll);

    if (
      isOfType<ProfileQuestionType[]>(questions)
      && isOfType<TProfileQuestionAnswerState[]>(answers)
    ) {
      const value = getRiskProfile(questions, answers);

      if (value) {
        yield put(tagsActions.add([{ key: 'riskProfile', value }]));

        return true;
      }
    }

    yield put(tagsActions.remove(['riskProfile']));

    return true;
  } catch (error) {
    if (__DEV__) {
      console.log('[profileQuestionAnswers.riskProfile]', 'catch', error); // eslint-disable-line no-console
    }

    return false;
  }

  return true;
}

export default function* watchProfileQuestionAnswers() {
  yield takeLatest(profileQuestionAnswersActionTypes.ADD, riskProfile);
  yield takeLatest(profileQuestionAnswersActionTypes.UPDATE, riskProfile);
}
