import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import Colors from '../../theme/Colors';

import { TOOLTIP_X_OFFSET } from './tooltips';

interface SvgContentProps {
  height: number;
  width: number;
  xPadding: number;
  yPadding: number;
  showGuideText: boolean;
  showTooltipTitle: boolean;
  onMouseOver: () => void;
  onMouseOut: () => void;
  children: ReactNode;
}

const propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  xPadding: PropTypes.number,
  yPadding: PropTypes.number,
  showGuideText: PropTypes.bool,
  showTooltipTitle: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  children: PropTypes.node,
};

const SvgTrendPlot = ({
  height,
  width,
  xPadding,
  yPadding,
  showGuideText,
  showTooltipTitle,
  onMouseOver,
  onMouseOut,
  children,
}: SvgContentProps) => {
  const tooltipHeight = showTooltipTitle ? 75 : 50;
  const tooltipDateHeight = showTooltipTitle ? 18 : -2;

  return (
    <>
      {children}
      <line
        className="center-line"
        transform={`translate(0, ${height / 2})`}
        x1={xPadding}
        x2={width - xPadding}
      />
      <g
        className="axis-line"
        transform={`translate(0, ${height - yPadding})`}
      />

      {showGuideText && (
        <>
          <svg y={height * 0.20}>
            <path d="M18.0419162,2.50565553 C19.0523952,2.73035597 19.7634731,3.21720691 20.1002994,3.96620836 C20.8488024,5.50166133 19.988024,7.7112156 18.9026946,10.1080202 C18.7904192,10.2952706 18.9401198,10.5574211 19.1646707,10.5948712 L19.8008982,10.6697713 L19.8008982,10.6697713 L23.992515,10.3327207 C25.1526946,10.2952706 26.0883234,11.1566223 26.238024,12.2801244 L26.5,15.0514298 L26.5,15.0514298 L26.3502994,18.3095861 C26.3502994,18.3470362 25.5269461,21.6800926 25.5269461,21.6800926 L25.002994,23.964547 C24.8158683,24.8633488 23.992515,25.5 23.0943114,25.5 L15.3098802,25.5 C15.1601796,25.5 15.010479,25.4625499 14.8982036,25.3501997 L11.8667665,23.0282952 C11.7919162,22.9908451 11.754491,22.9533951 11.6796407,22.9533951 L10.0329341,22.9533951 C9.84580838,22.9533951 9.69610778,22.8035948 9.69610778,22.6163444 L9.69610778,12.7295253 C9.69610778,12.5422749 9.84580838,12.3924747 10.0329341,12.3924747 L10.5568862,12.3924747 L10.5568862,12.3924747 C10.7065868,12.3924747 10.8188623,12.2801244 10.8562874,12.1677742 C11.6422156,9.43391893 13.1017964,8.46021705 14.6736527,7.48651517 L14.7859281,7.37416495 L14.7859281,7.37416495 C16.5823353,4.67775973 17.2934132,2.9550564 17.2934132,2.91760633 C17.4431138,2.61800575 17.7050898,2.46820546 18.0419162,2.50565553 Z M8.1991018,10.8195716 C8.38622754,10.8195716 8.57335329,10.9693719 8.57335329,11.1940723 L8.57335329,11.1940723 L8.57335329,23.8147467 C8.57335329,24.0019971 8.42365269,24.1892475 8.1991018,24.1892475 L8.1991018,24.1892475 L1.8742515,24.1892475 C1.68712575,24.1892475 1.5,24.0394472 1.5,23.8147467 L1.5,23.8147467 L1.5,11.1940723 C1.5,10.9693719 1.6497006,10.8195716 1.8742515,10.8195716 L1.8742515,10.8195716 Z" fill={Colors.primary} fillRule="evenodd" />
          </svg>
          <svg y={height * 0.65}>
            <path d="M12.6901198,2.5 C12.8398204,2.5 12.989521,2.53745007 13.1017964,2.64980029 L16.1332335,4.97170478 C16.2080838,5.00915485 16.245509,5.04660492 16.3203593,5.04660492 L17.9670659,5.04660492 C18.1541916,5.04660492 18.3038922,5.19640521 18.3038922,5.38365558 L18.3038922,15.2704747 C18.3038922,15.4577251 18.1541916,15.6075253 17.9670659,15.6075253 L17.4431138,15.6075253 L17.4431138,15.6075253 C17.2934132,15.6075253 17.1811377,15.7198756 17.1437126,15.8322258 C16.3577844,18.5660811 14.8982036,19.539783 13.3263473,20.5134848 L13.2140719,20.6258351 L13.2140719,20.6258351 C11.4176647,23.3222403 10.7065868,25.0449436 10.7065868,25.0823937 C10.5568862,25.3819942 10.2949102,25.5317945 9.95808383,25.4943445 C8.94760479,25.269644 8.23652695,24.7827931 7.8997006,24.0337916 C7.1511976,22.4983387 8.01197605,20.2887844 9.09730539,17.8919798 C9.20958084,17.7047294 9.05988024,17.4425789 8.83532934,17.4051288 L8.1991018,17.3302287 L8.1991018,17.3302287 L4.00748503,17.6672793 C2.84730539,17.7047294 1.91167665,16.8433777 1.76197605,15.7198756 L1.5,12.9485702 L1.5,12.9485702 L1.6497006,9.6904139 C1.6497006,9.65296383 2.47305389,6.31990739 2.47305389,6.31990739 L2.99700599,4.03545297 C3.18413174,3.13665123 4.00748503,2.5 4.90568862,2.5 L12.6901198,2.5 Z M26.1257485,3.54027979 C26.3128743,3.54027979 26.5,3.69008008 26.5,3.91478051 L26.5,3.91478051 L26.5,16.5354549 C26.5,16.7601554 26.3502994,16.9099556 26.1257485,16.9099556 L26.1257485,16.9099556 L19.8008982,16.9099556 C19.6137725,16.9099556 19.4266467,16.7601554 19.4266467,16.5354549 L19.4266467,16.5354549 L19.4266467,3.91478051 C19.4266467,3.72753015 19.5763473,3.54027979 19.8008982,3.54027979 L19.8008982,3.54027979 Z" fill={Colors.primary} fillRule="evenodd" />
          </svg>
        </>
      )}

      <g className="trend-graph-focus">
        <rect
          className="trend-graph-tooltip"
          height={tooltipHeight}
          x={TOOLTIP_X_OFFSET}
          y={-22}
          rx={4}
          ry={4}
        />
        {showTooltipTitle && <text className="trend-graph-tooltip-title" x={18} y={-2} />}
        <text className="trend-graph-tooltip-date" x={18} y={tooltipDateHeight} />
        <text className="trend-graph-tooltip-value" x={18} y={tooltipDateHeight + 20} />
      </g>

      <circle className="trend-graph-focus-circle" style={{ display: 'none' }} r={5} />

      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <rect
        className="trend-graph-tooltip-overlay"
        width={width}
        height={height}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      />
    </>
  );
};

SvgTrendPlot.propTypes = propTypes;

export default SvgTrendPlot;
