import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import '../css/Navigation.css';
import logo from '../assets/images/logo.svg';
import Drawer from './Drawer';
import Colors from '../theme/Colors';
import { ReactComponent as IconHome } from '../assets/icons/Home.svg';
import { ReactComponent as IconTreatmentPlan } from '../assets/icons/TreatmentPlan.svg';
import { ReactComponent as IconDiary } from '../assets/icons/Diary.svg';
import { ReactComponent as IconTrend } from '../assets/icons/Trend.svg';
import { ReactComponent as IconQuestionnaire } from '../assets/icons/Questionnaire.svg';
import { ReactComponent as IconProfileCard } from '../assets/icons/ProfileCard.svg';
import { ReactComponent as IconSettings } from '../assets/icons/Settings.svg';
import { ReactComponent as IconExit } from '../assets/icons/Exit.svg';
import { ReactComponent as IconLibrary } from '../assets/icons/Library.svg';
import { ReactComponent as IconPhoneBook } from '../assets/icons/PhoneBook.svg';
import { actions as authActions } from '../state/auth';
import versionJSON from '../version.json';

const Navigation = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(authActions.logout());
  };

  return (
    <Drawer>
      <section className="menu">
        <NavLink to="/">
          <header>
            <img src={logo} alt="Owise" />
          </header>
        </NavLink>
        <nav>
          <section>
            <NavLink className="navigation" activeClassName="emphasized" exact to="/">
              <IconHome fill={Colors.black} className="icon" />
              <span>{t('overview')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" to="/treatments">
              <IconTreatmentPlan fill={Colors.black} className="icon" />
              <span>{t('plan')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" to="/diary">
              <IconDiary fill={Colors.black} className="icon" />
              <span>{t('diary')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" to="/trends">
              <IconTrend fill={Colors.black} className="icon" />
              <span>{t('trends')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" to="/questionnaires">
              <IconQuestionnaire fill={Colors.black} className="icon" />
              <span>{t('questionnaires')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" to="/profile">
              <IconProfileCard fill={Colors.black} className="icon" />
              <span>{t('profile')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" to="/library">
              <IconLibrary fill={Colors.black} className="icon" />
              <span>{t('library')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" to="/contacts">
              <IconPhoneBook fill={Colors.black} className="icon" />
              <span>{t('phone-book')}</span>
            </NavLink>
            <NavLink className="navigation" activeClassName="emphasized" to="/settings">
              <IconSettings fill={Colors.black} className="icon" />
              <span>{t('settings')}</span>
            </NavLink>
            <button type="button" className="logout navigation" onClick={logout}>
              <IconExit fill={Colors.black} className="icon" />
              {t('logout')}
            </button>
          </section>
        </nav>
        <p className="body light version">{`${versionJSON.version} (${versionJSON.buildNumber})`}</p>
      </section>
    </Drawer>
  );
};

export default Navigation;
