import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import toUpper from 'lodash/toUpper';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import ListItem from '../components/ListItem';
import Modal from '../components/Modal';
import Button from '../components/Button';
import Colors from '../theme/Colors';
import { selectors as settingsSelectors, actions as settingsActions } from '../state/settings';
import { ReactComponent as IconCode } from '../assets/icons/Code.svg';
import { ReactComponent as IconDelete } from '../assets/icons/Delete.svg';
import { actions as apiActions } from '../state/api';
import config from '../config';

import '../css/InvitationCodes.css';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const InvitationCodes = () => {
  const [modal, setModal] = useState({ visible: false, onClick: noop });
  const [loading, setLoading] = useState(false);
  const [newCode, setNewCode] = useState('');
  const [showInvalidCodeModal, setShowInvalidCodeModal] = useState(false);

  const invitationCodes = (
    useSelector((state: AppState) => settingsSelectors.getAllInvitationCodes(state))
  );
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const showModal = (onClick: () => void) => setModal({ visible: true, onClick });

  const hideModal = () => setModal({ visible: false, onClick: noop });

  const showErrorCodeModal = () => {
    setShowInvalidCodeModal(true);
  };

  const hideErrorCodeModal = () => {
    setNewCode('');
    setShowInvalidCodeModal(false);
  };

  const handleCodeAdd = () => {
    if (!newCode) {
      return false;
    }

    setLoading(true);

    return dispatch(apiActions.validateInvite({
      code: newCode,
      onSuccess: () => {
        dispatch(settingsActions.invitationCodesAdd([newCode]));
        if (includes(config.testUserCodePatterns, toLower(newCode))) {
          // to stop tracking user data call
          window.Countly.q.push(['opt_out']);
        }

        setLoading(false);
        setNewCode('');
      },
      onError: () => {
        if (includes(config.testUserCodePatterns, toLower(newCode))) {
          window.Countly.q.push(['opt_out']);
        }

        setLoading(false);
        showErrorCodeModal();
        setNewCode('');
      },
    }));
  };

  const forceWindowReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const handleCodeDelete = (code: string) => {
    dispatch(settingsActions.invitationCodesRemove([code]));
    if (includes(config.testUserCodePatterns, toLower(code))) {
      // to stop tracking user data call
      window.Countly.opt_in();
      forceWindowReload();
    }
  };

  return (
    <article className="page diary row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('invitation-codes')} showRightButton />
          <section className="diary-item">
            <div className="add-code">
              <label htmlFor="new-code">
                <span className="body light">{t('add-code')}</span>
                <input
                  value={newCode}
                  onChange={(event) => setNewCode(toUpper(event.currentTarget.value))}
                  id="new-code"
                  name="new-code"
                />
              </label>
              {
                !loading
                  ? <Button onClick={handleCodeAdd} labelText={t('add')} />
                  : <Loader type="TailSpin" color={Colors.primary} height={24} width={24} />
              }
            </div>
            <h2 className="heading-secondary">{t('my-codes')}</h2>
            {
              !isEmpty(invitationCodes)
                ? map(invitationCodes, (code) => (
                  <ListItem
                    key={code}
                    primaryText={code}
                    leftIcon={<IconCode fill={Colors.primary} className="icon" />}
                    rightIcon={(
                      <button
                        type="button"
                        onClick={() => showModal(() => {
                          handleCodeDelete(code);
                          hideModal();
                        })}
                      >
                        <IconDelete fill={Colors.destructiveRed} className="icon" />
                      </button>
                    )}
                    divider
                  />
                ))
                : <p className="body light">{t('empty-state-my-codes')}</p>
            }
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
      <Modal
        visible={modal.visible}
        hideModal={hideModal}
        actions={[
          {
            title: t('cancel'),
            onClick: hideModal,
          },
          {
            title: t('delete'),
            onClick: modal.onClick,
            destructive: true,
          },
        ]}
      >
        <>
          <h1 className="heading">{t('delete-code')}</h1>
          <p className="body">{t('delete-code-confirm')}</p>
        </>
      </Modal>

      <Modal
        className="default-modal  align-center"
        visible={showInvalidCodeModal}
        hideModal={hideErrorCodeModal}
        actions={[
        ]}
      >
        <p>{t('modals.invalid-code.title')}</p>
        <Button labelText={t('close')} size="medium" onClick={hideErrorCodeModal} />
      </Modal>
    </article>
  );
};

export default InvitationCodes;
