import get from 'lodash/get';
import defaultTo from 'lodash/defaultTo';

/**
 * Combination of _.get and _.defaultTo. Gets the value at path of object.
 * If the resolved value is NaN, null, or undefined the defaultValue is returned in its place.
 * The customizer function option allows you to use _.toNumber, _.toString etc on the value from
 * _.get().
 *
 * {@link utils/defaultGet}
 * @exports utils/defaultGet
 * @param {Object} object - The object to query
 * @param {String} path - The path of the property to get.
 * @param {*} defaultValue - The value returned for Nan, null or undefined resolved values.
 * @returns {*} Returns the resolved value.
 */
const defaultGet = (
  obj: object | undefined,
  path: string | (string | number)[],
  defaultValue: any,
  customizer?: Function,
): any => {
  const result = get(obj, path, defaultValue);

  if (customizer && typeof customizer === 'function') {
    return defaultTo(customizer(result), defaultValue);
  }

  return defaultTo(result, defaultValue);
};

export default defaultGet;
