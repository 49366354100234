import isEmpty from 'lodash/isEmpty';
import concat from 'lodash/concat';

import {
  TrendsState,
  TrendsAddActionType,
} from './types';

const add = (state: TrendsState, action: TrendsAddActionType) => {
  const { payload } = action;

  if (isEmpty(payload)) {
    return state;
  }

  return concat(state, payload);
};

const clear = () => ([]);

export {
  add,
  clear,
};
