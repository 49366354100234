import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import noop from 'lodash/noop';
import 'reset-css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';

import './css/variables.css';
import './css/index.css';
import './css/Text.css';
import './css/Page.css';
import './css/Calendar.css';
import Router from './router';
import configureStore from './store/configureStore';
import './services/i18n';
import ErrorBoundary from './components/ErrorBoundary';

const { store, persistor } = configureStore();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <ErrorBoundary errorHandler={noop}>
    <Suspense fallback="loading">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router />
        </PersistGate>
      </Provider>
    </Suspense>
  </ErrorBoundary>,
);
