import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from '../state/reducers';
import { ReactComponent as IconProfileCard } from '../assets/icons/ProfileCard.svg';

import Modal from './Modal';
import { selectors as uiSelectors, actions as uiActions } from '../state/ui';
import { actions as settingsActions, selectors as settingsSelectors } from '../state/settings';
import getProfileProgressSelector from '../selectors/getProfileProgress';
import Colors from '../theme/Colors';

const ProfileCompletedModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const profileCompleteModalShownSetting = (
    useSelector((state: AppState) => settingsSelectors.getGeneralProfileCompleteModalShown(state))
  );

  const { percentage } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  const visible = useSelector((state: AppState) => (
    uiSelectors.getProfileCompleteModalVisible(state)
  ));

  useEffect(() => {
    if (percentage === 0 && !profileCompleteModalShownSetting) {
      dispatch(uiActions.profileCompleteModalShow());
    }
  }, [dispatch, percentage, profileCompleteModalShownSetting]);

  const handleClose = () => {
    dispatch(settingsActions.generalUpdate({ profileCompleteModalShown: true }));
    dispatch(uiActions.profileCompleteModalHide());
  };

  const handlePrimaryButtonClick = () => {
    handleClose();

    history.push('/profile/complete');
  };

  return (
    <Modal
      visible={visible}
      hideModal={handleClose}
      actions={[
        {
          title: t('not-now'),
          onClick: handleClose,
        },
        {
          title: t('create-profile'),
          onClick: handlePrimaryButtonClick,
          primary: true,
        },
      ]}
      className="profile-complete"
    >
      <h1 className="heading-large">{t('profile-setup-title')}</h1>
      <h2 className="heading-secondary">{t('profile-setup-subtitle')}</h2>
      <IconProfileCard fill={Colors.secondary} className="icon" />
      <p className="body">{t('profile-setup-body')}</p>
    </Modal>
  );
};

export default ProfileCompletedModal;
