import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import classNames from 'classnames';
import EventListener from 'react-event-listener';

import '../css/Modal.css';

type Props = {
  visible: boolean;
  hideModal: () => void;
  children: ReactNode;
  actions: {
    title: string;
    onClick: () => void;
    primary?: boolean;
    disabled?: boolean;
    destructive?: boolean;
  }[];
  className?: string;
};

const propTypes = {
  visible: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    primary: PropTypes.bool,
    disabled: PropTypes.bool,
    destructive: PropTypes.bool,
  })).isRequired,
  className: PropTypes.string,
};

const Modal = (props: Props) => {
  const {
    visible,
    hideModal,
    children,
    actions,
    className = '',
  } = props;

  if (!visible) {
    return null;
  }

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      hideModal();
    }
  };

  return (
    <>
      <section // eslint-disable-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
        className="overlay"
        onClick={hideModal}
      />
      <section className={classNames('modal', className)}>
        <section className="modal-content">{children}</section>
        <section className="buttons">
          {map(actions, (action, index) => {
            const {
              title,
              onClick,
              destructive,
              primary,
              disabled,
            } = action;

            return (
              <button
                className={classNames('button', { destructive, accent: primary })}
                type="button"
                onClick={onClick}
                key={index}
                disabled={disabled}
              >
                {title}
              </button>
            );
          })}
        </section>
        {
          visible
            ? <EventListener target="window" onKeyUp={handleKeyUp} />
            : null
        }
      </section>
    </>
  );
};

Modal.propTypes = propTypes;

export default Modal;
