import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { AppState } from '../state/reducers';

import OverviewScreen from '../screens/Overview';
import DiaryScreen from '../screens/Diary';
import ProfileScreen from '../screens/Profile';
import QuestionnairesScreen from '../screens/Questionnaires';
import QuestionnaireAddScreen from '../screens/QuestionnaireAdd';
import QuestionnaireScreen from '../screens/Questionnaire';
import TreatmentPlanScreen from '../screens/TreatmentPlan';
import TrendsScreen from '../screens/Trends';
import SettingsScreen from '../screens/Settings';
import LoginScreen from '../screens/Login';
import SignupScreen from '../screens/Signup';
import ForgotPasswordScreen from '../screens/ForgotPassword';
import ResetPasswordScreen from '../screens/ResetPassword';
import VerifyEmailScreen from '../screens/VerifyEmail';
import getApiTokenAsync from '../utils/getApiTokenAsync';
import AppointmentsScreen from '../screens/Appointments';
import NotesScreen from '../screens/Notes';
import MessageScreen from '../screens/Message';
import MessagesScreen from '../screens/Messages';
import PhotosScreen from '../screens/Photos';
import AudioRecordingsScreen from '../screens/AudioRecordings';
import ProfileChangeScreen from '../screens/ProfileChange';
import ProfileCompleteScreen from '../screens/ProfileComplete';
import TrendsSettingsScreen from '../screens/TrendsSettings';
import TreatmentAddScreen from '../screens/TreatmentAdd';
import TreatmentScreen from '../screens/Treatment';
import UsefulLinksScreen from '../screens/UsefulLinks';
import GlossaryScreen from '../screens/Glossary';
import ReportScreen from '../screens/Report';
import InvitationCodesScreen from '../screens/InvitationCodes';
import TrendsAddScreen from '../screens/TrendsAdd';
import DecisionAidScreen from '../screens/DecisionAid';
import ContactsScreen from '../screens/Contacts';
import ContactScreen from '../screens/Contact';
import ContactAddScreen from '../screens/ContactAdd';
import ChangePasswordScreen from '../screens/ChangePassword';
import ChangeAccountSettings from '../screens/ChangeAccountSettings';
import LibraryScreen from '../screens/Library';
import AppointmentScreen from '../screens/Appointment';
import AppointmentAddScreen from '../screens/AppointmentAdd';
import PhotoScreen from '../screens/Photo';
import PhotoAdd from '../screens/PhotoAdd';
import AudioRecordingScreen from '../screens/AudioRecording';
import NoteScreen from '../screens/Note';
import NoteAddScreen from '../screens/NoteAdd';
import BlockedAccount from '../screens/BlockedAccount';

import QuestionAddModal from '../components/QuestionAddModal';
import Notification from '../components/Notification';
import Countly from '../components/Countly';
import { actions as authActions, selectors as authSelectors } from '../state/auth';
import getEmailAsync from '../utils/getEmailAsync';
import checkIsTestUser from '../utils/checkIsTestUser';
import { selectors as settingsSelectors, actions as settingsActions } from '../state/settings';
import { getLocale as i18nGetLocale } from '../services/i18n';

const Router = () => {
  const auth = useSelector((state: AppState) => authSelectors.get(state));
  const status = useSelector((state:AppState) => state.settings.accountStatus.status);
  const invitationCodes = useSelector((state:AppState) => state.settings.invitationCodes);

  const appLanguage = useSelector(
    (state: AppState) => settingsSelectors.getGeneralAppLanguage(state),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const apiTokenAsync = async () => {
      try {
        await getApiTokenAsync();

        await getEmailAsync();

        dispatch(authActions.set(true));
      } catch (error) {
        dispatch(authActions.set(false));
      }
    };

    if (!status || status === 'active') {
      apiTokenAsync();
    }
  });

  const unauthSwitch = (
    <Switch>
      <Route path="/login" component={LoginScreen} />
      <Route path="/forgot-password" component={ForgotPasswordScreen} />
      <Route path="/reset-password/:token" component={ResetPasswordScreen} />
      <Route path="/verify-email/:token" component={VerifyEmailScreen} />
      <Route path="/signup" component={SignupScreen} />
      <Route path="/" component={LoginScreen} />
    </Switch>
  );

  const blockedSwitch = (
    <Switch>
      <Route path="/" component={BlockedAccount} />
    </Switch>
  );

  const authSwitch = (
    <>
      <Switch>
        <Route path="/appointments/add" component={AppointmentAddScreen} />
        <Route path="/appointment/:appointmentId" component={AppointmentScreen} />
        <Route path="/appointments/:view/:date?" component={AppointmentsScreen} />
        <Route path="/notes/add" component={NoteAddScreen} />
        <Route path="/note/:noteId" component={NoteScreen} />
        <Route path="/notes/:view/:date?" component={NotesScreen} />
        <Route path="/message/:messageId/:isPushNotification?" component={MessageScreen} />
        <Route path="/messages/:view/:date?" component={MessagesScreen} />
        <Route path="/photo/:photoId" component={PhotoScreen} />
        <Route path="/photos/add" component={PhotoAdd} />
        <Route path="/photos/:view/:date?" component={PhotosScreen} />
        <Route path="/recording/:audioRecordingId" component={AudioRecordingScreen} />
        <Route path="/recordings/:view/:date?" component={AudioRecordingsScreen} />
        <Route path="/reports/:reportId" component={ReportScreen} />
        <Route path="/treatments/decision-aid" component={DecisionAidScreen} />
        <Route path="/treatments/add" component={TreatmentAddScreen} />
        <Route path="/treatments/:treatmentId" component={TreatmentScreen} />
        <Route path="/treatments" component={TreatmentPlanScreen} />
        <Route path="/diary/:view?" component={DiaryScreen} />
        <Route path="/trends/settings" component={TrendsSettingsScreen} />
        <Route path="/trends/add" component={TrendsAddScreen} />
        <Route path="/trends" component={TrendsScreen} />
        <Route path="/profile/complete/:questionId?" component={ProfileCompleteScreen} />
        <Route path="/profile/:questionId" component={ProfileChangeScreen} />
        <Route path="/profile" component={ProfileScreen} />
        <Route path="/settings/change-password" component={ChangePasswordScreen} />
        <Route path="/settings/change-account-settings" component={ChangeAccountSettings} />
        <Route path="/settings/invitation-codes" component={InvitationCodesScreen} />
        <Route path="/settings" component={SettingsScreen} />
        <Route path="/questionnaires/add" component={QuestionnaireAddScreen} />
        <Route path="/questionnaires/:questionnaireId" component={QuestionnaireScreen} />
        <Route path="/questionnaires" component={QuestionnairesScreen} />
        <Route path="/useful-links" component={UsefulLinksScreen} />
        <Route path="/glossary" component={GlossaryScreen} />
        <Route path="/contacts/add" component={ContactAddScreen} />
        <Route path="/contacts/:contactId" component={ContactScreen} />
        <Route path="/contacts" component={ContactsScreen} />
        <Route path="/library" component={LibraryScreen} />
        <Route path="/" component={OverviewScreen} />
      </Switch>
      <QuestionAddModal />
    </>
  );

  let routes = auth ? authSwitch : unauthSwitch;

  if (status && status !== 'active') {
    routes = blockedSwitch;
  }

  // Add appLanguage to existing logged in users
  if (auth && !appLanguage) {
    dispatch(settingsActions.generalUpdate({ appLanguage: i18nGetLocale() }));
  }

  const isTestUser = checkIsTestUser(invitationCodes);
  return (
    <BrowserRouter>
      <Countly isTestUser={isTestUser}>
        {routes}
      </Countly>
      <Notification />
    </BrowserRouter>
  );
};

export default Router;
