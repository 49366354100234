/* eslint @typescript-eslint/quotes: 0 */
export default {
  "en-GB": `# Disclaimer
*Please note: This function is still in a test phase (also called "beta version"). It has been developed for prostate cancer patients in England and Scotland. If you are from another country, you may find that the information provided does not correspond to your country's guidelines for the treatment of prostate cancer.*

*This section of **OWise** is aimed at giving you personalised support in choosing a treatment that suits you. As it is still in a testing phase, some errors may occur. If you find a mistake or if the information is unclear, please email us right away at [feedback@owise.uk](mailto:feedback@owise.uk). Thank you very much.*

This Decision Report is based on the preferences, summarised below, that you have provided. It does not offer a prognosis or medical advice. Please read through the information below and if you think the treatment options do not suit you, return to the Decision Aid to adjust your preferences and generate a new Decision Report. The information in this report is based on recognised guidelines for the treatment of prostate cancer and is based on evidence-based medicine. Sources used for the treatment report include guidelines and information from [NICE](https://www.nice.org.uk/guidance/ng131), [National Prostate Cancer Audit](https://www.npca.org.uk/) and [European Association of Urology](https://uroweb.org/guideline/prostate-cancer/).

# Your preferences
You have selected the following preferences that form the basis of this Decision report:

$answers

# Introduction
Prostate cancer treatment depends on the diagnosis, such as the stage and grade of the cancer, and your personal preferences. It can be difficult to decide on your treatment but the OWise Decision Aid can help you prepare for conversations with your doctor and help you choose the right treatment. With the OWise Decision Report and the Diagnostic Summary at hand you can discuss your preferred options with your doctor and nurse in more detail. Your doctor will talk to you about the different factors you should consider when making treatment decisions.
You can use the Share button to send a copy to yourself, your doctor or a trusted contact by e-mail. You can also print a copy of the report and bring it along to your next doctor’s appointment. Please be aware that the report only provides you with an overview of possible treatments. It is up to you and your doctor to decide which treatment plan is best for you.

# Treatment summary
Your treatment options depend on various factors, including your personal preferences as well as the type of prostate cancer that you have been diagnosed with. The information below explains the advantages and disadvantages of your treatment options as well as practical issues. Make sure you discuss these with your nurse or doctor to understand how the treatment option may affect you.

$pathwayOptions

# Next steps
If you do not think these options are not appropriate for you, please go back to the Decision Aid and answer the questions again to come to more suitable treatment options. If you think one or more of the options above are appropriate for you discuss these with your care team and use the OWise app to get access to more information and tools such as symptom tracking (Trends) to support you during your treatment or chosen pathway.
If you would like to discuss your treatment options with a nurse please contact:
* [Prostate Cancer UK](https://prostatecanceruk.org/) to speak with a clinical nurse specialist at 0800 074 8383,
* [Macmillan Cancer Support](https://www.macmillan.org.uk/) to speak with a cancer nurse at 0808 808 0000 or
* [Maggie’s Centres](https://www.maggies.org/), a wonderful support network, to speak with a specialist nurse or qualified support worker at 0300 123 1801`,
  "nl-NL": `# Disclaimer
*Please note: This function is still in a test phase (also called "beta version"). It has been developed for prostate cancer patients in England and Scotland. If you are from another country, you may find that the information provided does not correspond to your country's guidelines for the treatment of prostate cancer.*

*This section of **OWise** is aimed at giving you personalised support in choosing a treatment that suits you. As it is still in a testing phase, some errors may occur. If you find a mistake or if the information is unclear, please email us right away at [feedback@owise.uk](mailto:feedback@owise.uk). Thank you very much.*

This Decision Report is based on the preferences, summarised below, that you have provided. It does not offer a prognosis or medical advice. Please read through the information below and if you think the treatment options do not suit you, return to the Decision Aid to adjust your preferences and generate a new Decision Report. The information in this report is based on recognised guidelines for the treatment of prostate cancer and is based on evidence-based medicine. Sources used for the treatment report include guidelines and information from [NICE](https://www.nice.org.uk/guidance/ng131), [National Prostate Cancer Audit](https://www.npca.org.uk/) and [European Association of Urology](https://uroweb.org/guideline/prostate-cancer/).

# Your preferences
You have selected the following preferences that form the basis of this Decision report:

$answers

# Introduction
Prostate cancer treatment depends on the diagnosis, such as the stage and grade of the cancer, and your personal preferences. It can be difficult to decide on your treatment but the OWise Decision Aid can help you prepare for conversations with your doctor and help you choose the right treatment. With the OWise Decision Report and the Diagnostic Summary at hand you can discuss your preferred options with your doctor and nurse in more detail. Your doctor will talk to you about the different factors you should consider when making treatment decisions.
You can use the Share button to send a copy to yourself, your doctor or a trusted contact by e-mail. You can also print a copy of the report and bring it along to your next doctor’s appointment. Please be aware that the report only provides you with an overview of possible treatments. It is up to you and your doctor to decide which treatment plan is best for you.

# Treatment summary
Your treatment options depend on various factors, including your personal preferences as well as the type of prostate cancer that you have been diagnosed with. The information below explains the advantages and disadvantages of your treatment options as well as practical issues. Make sure you discuss these with your nurse or doctor to understand how the treatment option may affect you.

$pathwayOptions

# Next steps
If you do not think these options are not appropriate for you, please go back to the Decision Aid and answer the questions again to come to more suitable treatment options. If you think one or more of the options above are appropriate for you discuss these with your care team and use the OWise app to get access to more information and tools such as symptom tracking (Trends) to support you during your treatment or chosen pathway.
If you would like to discuss your treatment options with a nurse please contact:
* [Prostate Cancer UK](https://prostatecanceruk.org/) to speak with a clinical nurse specialist at 0800 074 8383,
* [Macmillan Cancer Support](https://www.macmillan.org.uk/) to speak with a cancer nurse at 0808 808 0000 or
* [Maggie’s Centres](https://www.maggies.org/), a wonderful support network, to speak with a specialist nurse or qualified support worker at 0300 123 1801`,
};
