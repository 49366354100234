import reduce from 'lodash/reduce';
import assignIn from 'lodash/assignIn';
import omit from 'lodash/omit';

import { TagsState, TagsAddActionType, TagsRemoveActionType } from './types';

const add = (state: TagsState, action: TagsAddActionType) => {
  const { payload } = action;

  const tags = reduce(
    payload,
    (result, { key, value }: { key: string, value: string }) => assignIn(result, { [key]: value }),
    {},
  );

  return {
    ...state,
    ...tags,
  };
};

const remove = (state: TagsState, action: TagsRemoveActionType) => {
  const { payload } = action;

  return omit(state, payload);
};

const clear = () => ({});

export {
  add,
  remove,
  clear,
};
