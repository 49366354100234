import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import nl2br from 'react-nl2br';

import { AppState } from '../state/reducers';

import Button from './Button';
import { selectors as tagsSelectors } from '../state/tags';
import images from '../images';

type Props = {
  next: () => void;
};

const DecisionAidIntro = (props: Props) => {
  const { next } = props;

  const { t } = useTranslation();

  const memoizedTagsGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  const riskProfileTagValue = useSelector(
    (reduxState: AppState) => memoizedTagsGetValueByKey(reduxState, 'riskProfile'),
  );

  return (
    <section className="decision-aid-intro">
      <img src={images.icons.sdm.default} alt={t('decision-aid')} />
      <h1 className="heading">{t('decisionAid:title')}</h1>
      <p className="body">
        {nl2br(t(`decisionAid:introduction.${riskProfileTagValue}`))}
      </p>
      <Button labelText={t('decisionAid:begin')} onClick={next} />
    </section>
  );
};

export default DecisionAidIntro;
