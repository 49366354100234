import * as actionTypes from './actionTypes';
import { MessageType } from '../../../types';
import { MessagesActionTypes } from './types';

const add = (payload: MessageType[]): MessagesActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const remove = (payload: string[]): MessagesActionTypes => ({
  type: actionTypes.REMOVE,
  payload,
});

const setReadAt = (payload: string[]): MessagesActionTypes => ({
  type: actionTypes.SET_READAT,
  payload,
});

const setAlertShownAt = (payload: string[]): MessagesActionTypes => ({
  type: actionTypes.SET_ALERTSHOWNAT,
  payload,
});

const clear = (): MessagesActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  remove,
  setReadAt,
  setAlertShownAt,
  clear,
};
