import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { AppState } from '../state/reducers';

import Button from './Button';
import { selectors as appointmentsSelectors } from '../state/appointments';
import { selectors as notesSelectors } from '../state/notes';
import { selectors as photosSelectors } from '../state/photos';
import { selectors as audioRecordingsSelectors } from '../state/audioRecordings';
import { selectors as messagesSelectors } from '../state/messages';
import { selectors as pushNotificationsSelectors } from '../state/notifications';
import tWithPlurals from '../utils/tWithPlurals';

const DiaryAll = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const appointmentsTotal = useSelector((state: AppState) => appointmentsSelectors.count(state));

  const notesTotal = useSelector((state: AppState) => notesSelectors.count(state));

  const messagesTotal = useSelector((state: AppState) => messagesSelectors.count(state));

  const pushNotificationsTotal = useSelector(
    (state: AppState) => pushNotificationsSelectors.count(state),
  );

  const photosTotal = useSelector((state: AppState) => photosSelectors.count(state));

  const audioRecordingsTotal = (
    useSelector((state: AppState) => audioRecordingsSelectors.count(state))
  );

  return (
    <>
      <div className="diary-header" />
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-appointments')}</h1>
        <Button labelText={t('add')} onClick={() => history.push('/appointments/add')} />
      </header>
      <section className="list empty">
        <div className="row between center">
          <p className="body light">{tWithPlurals(t, 'you-have-appointments-count', appointmentsTotal)}</p>
          <Link to="/appointments/all" className="navigation accent">{t('see-all')} &gt;</Link>
        </div>
      </section>
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-notes')}</h1>
        <Button labelText={t('add')} onClick={() => history.push('/notes/add')} />
      </header>
      <section className="list empty">
        <div className="row between center">
          <p className="body light">{tWithPlurals(t, 'you-have-notes-count', notesTotal)}</p>
          <Link to="/notes/all" className="navigation accent">{t('see-all')} &gt;</Link>
        </div>
      </section>
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-messages')}</h1>
      </header>
      <section className="list empty">
        <div className="row between center">
          <p className="body light">{tWithPlurals(t, 'you-have-messages-count', messagesTotal + pushNotificationsTotal)}</p>
          <Link to="/messages/all" className="navigation accent">{t('see-all')} &gt;</Link>
        </div>
      </section>
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-photos')}</h1>
        <Button labelText={t('add')} onClick={() => history.push('/photos/add')} />
      </header>
      <section className="list empty">
        <div className="row between center">
          <p className="body light">{tWithPlurals(t, 'you-have-photos-count', photosTotal)}</p>
          <Link to="/photos/all" className="navigation accent">{t('see-all')} &gt;</Link>
        </div>
      </section>
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-audio-recordings')}</h1>
      </header>
      <section className="list empty">
        <div className="row between center">
          <p className="body light">{tWithPlurals(t, 'you-have-recordings-count', audioRecordingsTotal)}</p>
          <Link to="/recordings/all" className="navigation accent">{t('see-all')} &gt;</Link>
        </div>
      </section>
    </>
  );
};

export default DiaryAll;
