import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';

import { TDecisionAidQuestion } from '../../types';
import Button from './Button';
import getLocalizedText from '../utils/getLocalizedText';

type TState = {
  answers: any;
  scores: any;
};

type Props = {
  back: () => void;
  state: TState;
  decisionAidQuestions: TDecisionAidQuestion[];
};

const DecisionAidPreferences = (props: Props) => {
  const {
    back,
    state,
    decisionAidQuestions,
  } = props;

  const { t, i18n } = useTranslation();

  return (
    <section className="decision-aid-preferences">
      <button type="button" onClick={back}>
        <p className="navigation accent">&lt; {t('decisionAid:back-to-report')}</p>
      </button>
      <h1 className="heading">{t('decisionAid:your-preferences')}</h1>
      {map(decisionAidQuestions, (question) => {
        const answerId = get(state, ['answers', question.id]);
        const answer = find(question.answers, { id: answerId });

        return (
          <Fragment key={`preference-${question.id}`}>
            <p className="body">{getLocalizedText(question, i18n.language, 'title')}</p>
            <Button labelText={getLocalizedText(answer, i18n.language, 'title')} />
          </Fragment>
        );
      })}
    </section>
  );
};

export default DecisionAidPreferences;
