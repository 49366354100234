import React from 'react';

import Colors from '../theme/Colors';
import '../css/Progress.css';

type Props = {
  progress: number;
  barColor?: string;
  trackColor?: string;
};

const Progress = (props: Props) => {
  const {
    progress,
    barColor = Colors.primary,
    trackColor = Colors.softPrimary,
  } = props;

  return (
    <section style={{ backgroundColor: trackColor }} className="progress">
      <section
        style={{ width: `${progress * 100}%`, backgroundColor: barColor }}
        className="progress-bar"
      />
    </section>
  );
};

export default Progress;
