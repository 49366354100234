/* eslint no-restricted-syntax:0 */
import map from 'lodash/map';
import find from 'lodash/find';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';

import { ProfileQuestionType, TProfileQuestionAnswerState } from '../../types';

import isOfType from './isOfType';
import includesAny from './includesAny';

import c1_0JSON from '../../data/1_0.json';
import c1_1JSON from '../../data/1_1.json';
import c1_2JSON from '../../data/1_2.json';
import c1_3JSON from '../../data/1_3.json';
import c1_4JSON from '../../data/1_4.json';
import c1_5JSON from '../../data/1_5.json';

const getLabel = (questions: ProfileQuestionType[], questionId: string, answerId: string) => {
  const question = find(questions, { id: questionId });

  if (!isOfType<ProfileQuestionType>(question, 'id')) {
    return '';
  }

  const answer = find(question.answers, { id: answerId });

  if (!isOfType<TProfileQuestionAnswerState>(answer, 'id') || !answer.label) {
    return '';
  }

  return answer.label;
};

const processConditions = (labels: string[], type: string, content: any) => {
  if (type === 'answer') {
    return includesAny(labels, [content]);
  }

  for (const condition of content) {
    const result = processConditions(labels, condition.type, condition.content);

    if (type === 'and' && !result) return false;
    if (type === 'or' && result) return true;
  }

  return type === 'and';
};

const getRiskProfile = (
  questions: ProfileQuestionType[],
  answers: TProfileQuestionAnswerState[],
): string => {
  const filteredAnswers = filter(answers, 'profileQuestionAnswerIds');

  const labels = compact(flatten(map(
    filteredAnswers, ({ profileQuestionId, profileQuestionAnswerIds }) => (
      flatten(compact(map(profileQuestionAnswerIds, (answerId) => (
        getLabel(questions, profileQuestionId, answerId)
      ))))
    ),
  )));

  if (
    processConditions(labels, c1_2JSON.type, c1_2JSON.content)
    || processConditions(labels, c1_3JSON.type, c1_3JSON.content)
    || processConditions(labels, c1_4JSON.type, c1_4JSON.content)
    || processConditions(labels, c1_5JSON.type, c1_5JSON.content)
  ) {
    return 'high';
  }

  if (processConditions(labels, c1_1JSON.type, c1_1JSON.content)) {
    return 'intermediate';
  }

  if (processConditions(labels, c1_0JSON.type, c1_0JSON.content)) {
    return 'low';
  }

  return '';
};

export default getRiskProfile;
