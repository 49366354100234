import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from '../state/reducers';
import { ReactComponent as IconProfileCard } from '../assets/icons/ProfileCard.svg';

import Modal from './Modal';
import { selectors as uiSelectors, actions as uiActions } from '../state/ui';
import { actions as settingsActions, selectors as settingsSelectors } from '../state/settings';
import Colors from '../theme/Colors';

const ProfileCompletedModal = () => {
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const profileIncompleteModalShownSetting = (
    useSelector((state: AppState) => settingsSelectors.getGeneralProfileIncompleteModalShown(state))
  );

  const profileIncompleteModalVisible = useSelector((state: AppState) => (
    uiSelectors.getProfileIncompleteModalVisible(state)
  ));

  useEffect(() => {
    if (profileIncompleteModalVisible && !profileIncompleteModalShownSetting) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [profileIncompleteModalVisible, profileIncompleteModalShownSetting]);

  const handleClose = () => {
    dispatch(uiActions.profileIncompleteModalHide());
    dispatch(settingsActions.generalUpdate({ profileIncompleteModalShown: true }));
  };

  const handlePrimaryButtonClick = () => {
    handleClose();

    history.push('/');
  };

  return (
    <Modal
      visible={visible}
      hideModal={handleClose}
      actions={[
        {
          title: t('not-now'),
          onClick: handleClose,
        },
        {
          title: t('get-started'),
          onClick: handlePrimaryButtonClick,
          primary: true,
        },
      ]}
      className="profile-complete"
    >
      <h1 className="heading-large">{t('profile-incomplete-title')}</h1>
      <IconProfileCard fill={Colors.secondary} className="icon" />
      <p className="body">{t('profile-incomplete-body')}</p>
    </Modal>
  );
};

export default ProfileCompletedModal;
