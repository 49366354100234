import get from 'lodash/get';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import size from 'lodash/size';
import toArray from 'lodash/toArray';

import { AppState } from '../reducers';
import { DecisionAidQuestionsState } from './types';
import { TDecisionAidQuestionReturn, TDecisionAidQuestionsReturn } from '../../../types';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

import EMPTY_OBJECT from '../../utils/empty-object';

const getByIdSelector = (
  state: DecisionAidQuestionsState, id: string,
): TDecisionAidQuestionReturn => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.decisionAidQuestions,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByIdsSelector = (
  state: DecisionAidQuestionsState,
  ids: string[],
): TDecisionAidQuestionsReturn => (
  filter(state, (question) => includes(ids, question.id))
);

const makeGetByIds = () => (
  createDeepEqualSelector(
    (state: AppState) => state.decisionAidQuestions,
    (state: any, ids: string[]) => ids,
    getByIdsSelector,
  )
);

const getAllSelector = (state: DecisionAidQuestionsState): TDecisionAidQuestionsReturn => (
  toArray(state)
);

const getAll = createDeepEqualSelector(
  (state: AppState) => state.decisionAidQuestions,
  getAllSelector,
);

const countSelector = (state: DecisionAidQuestionsState) => size(state);

const count = createDeepEqualSelector(
  (state: AppState) => state.decisionAidQuestions,
  countSelector,
);

export {
  makeGetById,
  makeGetByIds,
  getAll,
  count,
};
