/* eslint react/require-default-props: 0 */
import React, { ReactNode, memo } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { View as RNView, ViewStyle as RNViewStyle } from 'react-native';
import RNSlider from '@react-native-community/slider';

import Text from '../Text';
import Divider, { DividerProps } from '../Divider';
import Colors from '../../theme/Colors';
import styles from './styles';

interface Props {
  labelText?: string,
  onSlidingStart?: (value: number) => void,
  onSlidingComplete?: (value: number) => void,
  onValueChange?: (value: number) => void,
  value?: number,
  minimumValue?: number,
  maximumValue?: number,
  step?: number,
  style?: RNViewStyle,
  divider?: boolean,
  dividerProps?: DividerProps,
  showValue?: boolean,
  minimumValueLabelText?: string,
  maximumValueLabelText?: string,
  minimumTrackTintColor?: string,
  maximumTrackTintColor?: string,
  pristine?: boolean;
  valueLabelText?: string;
  headerButton?: ReactNode;
}

const propTypes = {
  labelText: PropTypes.node,
  onSlidingStart: PropTypes.func,
  onSlidingComplete: PropTypes.func,
  onValueChange: PropTypes.func,
  value: PropTypes.number,
  minimumValue: PropTypes.number,
  maximumValue: PropTypes.number,
  step: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  divider: PropTypes.bool,
  dividerProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  showValue: PropTypes.bool,
  minimumValueLabelText: PropTypes.string,
  maximumValueLabelText: PropTypes.string,
  minimumTrackTintColor: PropTypes.string,
  maximumTrackTintColor: PropTypes.string,
  pristine: PropTypes.bool,
  valueLabelText: PropTypes.string,
  headerButton: PropTypes.node,
};

const Slider = (props: Props) => {
  const {
    labelText = '',
    onSlidingStart = noop,
    onSlidingComplete = noop,
    onValueChange = noop,
    value = 0,
    minimumValue = 0,
    maximumValue = 10,
    step = 0.5,
    style = {},
    divider = false,
    dividerProps = {},
    showValue = true,
    minimumValueLabelText = '',
    maximumValueLabelText = '',
    minimumTrackTintColor = Colors.lightPrimary,
    maximumTrackTintColor = Colors.lightGray,
    pristine = true,
    valueLabelText = '',
    headerButton = null,
  } = props;

  return (
    <RNView>
      <RNView style={[styles.container, style]}>
        {
          labelText || showValue || headerButton
            ? (
              <RNView style={styles.header}>
                <Text>
                  {labelText && <Text type="heading" style={styles.label}>{labelText}</Text>}
                  {
                    showValue
                      ? <Text style={styles.value} numberOfLines={1}>{value}</Text>
                      : null
                  }
                </Text>
                {headerButton}
              </RNView>
            )
            : null
        }
        {
          valueLabelText
            ? <Text style={styles.value} type="heading-accent" numberOfLines={1}>{valueLabelText}</Text>
            : null
        }
        <RNSlider
          style={styles.slider}
          value={value}
          step={step}
          onSlidingStart={onSlidingStart}
          onSlidingComplete={onSlidingComplete}
          onValueChange={onValueChange}
          thumbTintColor={pristine ? Colors.softPrimary : Colors.primary}
          minimumValue={minimumValue}
          maximumValue={maximumValue}
          minimumTrackTintColor={pristine ? Colors.softPrimary : minimumTrackTintColor}
          maximumTrackTintColor={pristine ? Colors.softerPrimary : maximumTrackTintColor}
        />
        <RNView style={styles.row}>
          <Text type="body-light">
            {minimumValueLabelText || minimumValue}
          </Text>
          <Text type="body-light">
            {maximumValueLabelText || maximumValue}
          </Text>
        </RNView>
      </RNView>
      {
        divider
          ? <Divider {...dividerProps} /> // eslint-disable-line react/jsx-props-no-spreading
          : null
      }
    </RNView>
  );
};

Slider.whyDidYouRender = true;

Slider.propTypes = propTypes;

export default memo(Slider);
