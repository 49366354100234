import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { useHistory, Link } from 'react-router-dom';
import classNames from 'classnames';

import { AppState } from '../state/reducers';
import { TTreatmentType, TReportType, TTreatment } from '../../types';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import TreatmentListItem from '../components/TreatmentListItem';
import Button from '../components/Button';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import ListItem from '../components/ListItem';
import ReportListItem from '../components/ReportListItem';
import GanttChart from '../components/GanttChart';
import { selectors as treatmentsSelectors } from '../state/treatments';
import { selectors as treatmentTypesSelectors } from '../state/treatmentTypes';
import { ReactComponent as IconReport } from '../assets/icons/Report.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import { ReactComponent as IconDecisionAid } from '../assets/icons/DecisionAid.svg';
import { selectors as generatedReportsSelectors } from '../state/generatedReports';
import { actions as reportsActions, selectors as reportsSelectors } from '../state/reports';
import { actions as settingsActions } from '../state/settings';
import getProfileProgressSelector from '../selectors/getProfileProgress';
import { actions as uiActions } from '../state/ui';
import getLocalizedText from '../utils/getLocalizedText';
import getUnixTime from '../utils/getUnixTime';
import Colors from '../theme/Colors';
import showDecisionAidSelector from '../selectors/showDecisionAid';
import images from '../images';
import '../css/Treatments.css';

const DrawerRight = () => {
  const history = useHistory();

  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  const reports = useSelector((state: AppState) => reportsSelectors.getMostRecentByType(state));

  const generatedReports = useSelector(
    (state: AppState) => generatedReportsSelectors.getAll(state),
  );

  const reportCounts = useSelector((state: AppState) => reportsSelectors.counts(state));

  const showDecisionAid = useSelector((state: AppState) => showDecisionAidSelector(state));

  const { completed: profileCompleted } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  const addReport = (type: TReportType) => {
    if (!profileCompleted) {
      dispatch(settingsActions.generalUpdate({ profileIncompleteModalShown: false }));
      return dispatch(uiActions.modalShow({ type: 'reportProfileIncomplete' }));
    }

    const report = getLocalizedText(generatedReports, i18n.language, type);

    if (!report) {
      return dispatch(uiActions.addNotification({
        type: 'warning',
        text: t('reports:failed-notification'),
      }));
    }

    const reportId = uuidv4();
    const count = get(reportCounts, [type]) || 0;

    dispatch(reportsActions.add([{
      id: reportId,
      createdAt: getUnixTime(),
      type,
      title: `${t(`reports:${type}`)} ${count + 1}`,
      content: {
        body: report,
      },
    }]));

    return history.push(`/reports/${reportId}`);
  };

  return (
    <Drawer position="right">
      <section>
        <DrawerRightHeader />
        {
          showDecisionAid
            ? (
              <>
                <header className="row between center">
                  <h1 className="heading">{t('tools')}</h1>
                </header>
                <ListItem
                  leftIcon={<IconDecisionAid fill={Colors.primary} className="icon" />}
                  primaryText={t('decision-aid')}
                  primaryTextClassName="button"
                  secondaryText={t('decision-aid-subtitle.short')}
                  rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
                  to="/treatments/decision-aid"
                />
              </>
            )
            : null
        }
        <header className="row between center">
          <h1 className="heading">{t('reports')}</h1>
        </header>
        <ListItem
          leftIcon={<IconReport fill={Colors.primary} className="icon" />}
          primaryText={t('reports:diagnostic-full')}
          secondaryText={t('generate-report')}
          rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
          divider
          onClick={debounce(() => addReport('diagnostic-full'), 200)}
        />
        <ListItem
          leftIcon={<IconReport fill={Colors.primary} className="icon" />}
          primaryText={t('reports:treatment-full')}
          secondaryText={t('generate-report')}
          rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
          onClick={debounce(() => addReport('treatment-full'), 200)}
        />
        <header className="row between center">
          <h1 className="heading">{t('library')}</h1>
          <Link to="/library" className="button accent">{t('see-all')}</Link>
        </header>
        {
          !isEmpty(reports)
            ? map(reports, (report) => <ReportListItem key={report.id} report={report} />)
            : <span className="body light">{t('reports:empty-state-no-reports')}</span>
        }
      </section>
    </Drawer>
  );
};

type ViewProps = {
  treatments: TTreatment[];
};

const TreatmentList = (props: ViewProps) => {
  const { treatments } = props;

  const { t, i18n } = useTranslation();

  const memoizedTreatmentTypesGetByLocaleSelector = useMemo(
    treatmentTypesSelectors.makeGetByLocale,
    [],
  );

  const treatmentTypes = useSelector(
    (state: AppState) => (
      memoizedTreatmentTypesGetByLocaleSelector(state, i18n.language)
    ),
  );

  if (isEmpty(treatments) || isEmpty(treatmentTypes)) {
    return null;
  }

  return (
    <section className="list-container">
      <header className="list-header">
        <h1 className="heading-secondary accent">{t('my-treatments')}</h1>
      </header>
      <section className={classNames('list', { empty: isEmpty(treatments) })}>
        {map(treatments, (treatment, index) => (
          <TreatmentListItem
            key={treatment.id}
            treatment={treatment}
            treatmentTypes={treatmentTypes as TTreatmentType[]}
            divider={(index + 1) !== size(treatments)}
          />
        ))}
      </section>
    </section>
  );
};

const TreatmentGraph = (props: ViewProps) => {
  const { treatments } = props;

  return <GanttChart treatments={treatments} />;
};

const TreatmentPlan = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const treatments = useSelector(
    (state: AppState) => treatmentsSelectors.getAll(state),
  );

  const showDecisionAid = useSelector((state: AppState) => showDecisionAidSelector(state));

  return (
    <article className="page treatment-plan row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('treatment-plan')} showRightButton />
          <header className="list-header">
            <h1 className="heading-secondary accent">{t('chart')}</h1>
          </header>
          {
            !isEmpty(treatments)
              ? <TreatmentGraph treatments={treatments as TTreatment[]} />
              : (
                <section className="overview-empty-state">
                  <h1 className="heading">{t('treatments:empty-state-home-heading')}</h1>
                  <p className="body">{t('treatments:empty-state-home-body')}</p>
                  <Button
                    labelText={t('add-treatment')}
                    size="medium"
                    onClick={() => history.push('/treatments/add')}
                  />
                </section>
              )
          }
          <section className={classNames('row', { showDecisionAid })}>
            <TreatmentList treatments={treatments as TTreatment[]} />
            {
              showDecisionAid
                ? (
                  <section className="decision-aid-tile">
                    <h1 className="heading-secondary">{t('decision-aid')}</h1>
                    <img src={images.icons.sdm.default} alt={t('decision-aid')} />
                    <p className="body">{t('decision-aid-subtitle.full')}</p>
                    <Button
                      labelText={t('decisionAid:begin')}
                      size="medium"
                      onClick={() => history.push('/treatments/decision-aid')}
                    />
                  </section>
                )
                : null
            }
          </section>
          <Button
            labelText={t('add-treatment')}
            size="medium"
            onClick={() => history.push('/treatments/add')}
          />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default TreatmentPlan;
