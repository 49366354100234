/* eslint-disable max-len */
import get from 'lodash/get';
import toArray from 'lodash/toArray';
import size from 'lodash/size';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import isSameDay from 'date-fns/isSameDay';
import isSameWeek from 'date-fns/isSameWeek';
import isSameMonth from 'date-fns/isSameMonth';

import { AppState } from '../reducers';
import { PushNotificationState } from './types';
import { TDate, PushNotificationReturnType, PushNotificationsReturnType } from '../../../types';

import EMPTY_OBJECT from '../../utils/empty-object';
import toDate from '../../utils/toDate';
import createDeepEqualSelector from '../../selectors/createDeepEqualSelector';

const sort = (collection: PushNotificationsReturnType | PushNotificationState): PushNotificationsReturnType => (
  orderBy(collection, ['createdAt'], ['desc'])
);

const getFilteredByShowInMailbox = (state: PushNotificationState) => (
  filter(state, (notification) => notification.showInMailbox) || EMPTY_OBJECT
);

const getByIdSelector = (state: PushNotificationState, id: string): PushNotificationReturnType => (
  get(state, [id]) || EMPTY_OBJECT
);

const makeGetById = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notifications,
    (state: any, id: string) => id,
    getByIdSelector,
  )
);

const getByDateSelector = (state: PushNotificationState, date?: TDate) => sort(filter(
  getFilteredByShowInMailbox(state),
  (notification) => isSameDay(toDate(notification.createdAt), toDate(date)),
));

const makeGetByDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notifications,
    (state: any, date: TDate) => date,
    getByDateSelector,
  )
);

const getByWeekSelector = (state: PushNotificationState, date?: TDate) => sort(filter(
  getFilteredByShowInMailbox(state),
  (notification) => isSameWeek(toDate(notification.createdAt), toDate(date)),
));

const makeGetByWeek = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notifications,
    (state: any, date: TDate) => date,
    getByWeekSelector,
  )
);

const getByMonthSelector = (state: PushNotificationState, date?: TDate) => sort(filter(
  getFilteredByShowInMailbox(state),
  (notification) => isSameMonth(toDate(notification.createdAt), toDate(date)),
));

const makeGetByMonth = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notifications,
    (state: any, date: TDate) => date,
    getByMonthSelector,
  )
);

const getAllSelector = (state: PushNotificationState) => sort(toArray(getFilteredByShowInMailbox(state)));

const getAll = createDeepEqualSelector(
  (state: AppState) => state.notifications,
  getAllSelector,
);

const getAllByViewAndDateSelector = (state: PushNotificationState, view = 'all', date: TDate): PushNotificationsReturnType => {
  switch (view) {
    case 'all': return getAllSelector(state);
    case 'day': return getByDateSelector(state, date);
    case 'week': return getByWeekSelector(state, date);
    case 'month': return getByMonthSelector(state, date);
    default:
  }

  return getAllSelector(state);
};

const makeGetAllByViewAndDate = () => (
  createDeepEqualSelector(
    (state: AppState) => state.notifications,
    (state: any, view: string) => view,
    (state: any, view: string, date: TDate) => date,
    getAllByViewAndDateSelector,
  )
);

const countSelector = (state: PushNotificationState) => size(getFilteredByShowInMailbox(state));

const count = createDeepEqualSelector(
  (state: AppState) => state.notifications,
  countSelector,
);

const unreadSelector = (state: PushNotificationState) => size(filter(
  getFilteredByShowInMailbox(state),
  (notification) => !notification.readAt,
));

const unread = createDeepEqualSelector(
  (state: AppState) => state.notifications,
  unreadSelector,
);

export {
  makeGetById,
  makeGetByDate,
  makeGetByWeek,
  makeGetByMonth,
  getAll,
  makeGetAllByViewAndDate,
  count,
  unread,
};
