const black = '#373737';

const darkGray = '#717274';

const lightGray = '#A0A0A2';

const softGray = '#E8E8E8';

const white = '#FFF';

const offWhite = '#F9F9F9';

const darkPrimary = '#084637';

const primary = '#0B643D';

const lightPrimary = '#B5D0C4';

const softPrimary = '#EEF5F2';

const softerPrimary = '#F5F8F5';

const secondary = '#3943F6';

const lightSecondary = '#D4D7FF';

const softSecondary = '#A0A4F6';

const errorRed = '#EE1C10';

const destructiveRed = '#EE1C10';

const warningOrange = '#E97509';

const informationalYellow = '#F8E8CE';

const successGreen = '#00901E';

const navigationInactive = '#B5D0C4';

const navigationActive = '#0B643D';

const recordingRed = '#E8101E';

const secondaryOrange = '#DF8659';

const calendar = {
  calendarBackground: softPrimary,
  textSectionTitleColor: primary,
  selectedDayBackgroundColor: secondary,
  todayTextColor: secondary,
  dayTextColor: primary,
  dotColor: primary,
  arrowColor: secondary,
  monthTextColor: secondary,
  textMonthFontFamily: 'Avenir-Book',
  textDayHeaderFontFamily: 'Avenir-Book',
};

export default {
  black,
  darkGray,
  lightGray,
  softGray,
  white,
  offWhite,
  darkPrimary,
  primary,
  lightPrimary,
  softPrimary,
  softerPrimary,
  secondary,
  lightSecondary,
  softSecondary,
  errorRed,
  destructiveRed,
  warningOrange,
  informationalYellow,
  successGreen,
  navigationInactive,
  navigationActive,
  calendar,
  recordingRed,
  secondaryOrange,
};
