import React, { useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MarkdownView from 'react-showdown';
import DatePicker from 'react-datepicker';
import map from 'lodash/map';
import slice from 'lodash/slice';
import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import compact from 'lodash/compact';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import get from 'lodash/get';
import join from 'lodash/join';
import split from 'lodash/split';
import replace from 'lodash/replace';

import { AppState } from '../../state/reducers';

import { selectors as reportsSelectors, actions as reportsActions } from '../../state/reports';
import Navigation from '../../components/Navigation';
import Footer from '../../components/Footer';
import Drawer from '../../components/Drawer';
import DrawerButtons from '../../components/DrawerButtons';
import DrawerRightHeader from '../../components/DrawerRightHeader';
import Accordion from '../../components/Accordion';
import ListItem from '../../components/ListItem';
import Button from '../../components/Button';
import { selectors as decisionAidQuestionsSelectors } from '../../state/decisionAidQuestions';
import markdown from './markdown';
import getLocalizedText from '../../utils/getLocalizedText';
import toDate from '../../utils/toDate';
import getUnixTime from '../../utils/getUnixTime';
import { pathwayIcons } from '../../theme/Icons';
import { ReactComponent as IconChevronUp } from '../../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../../assets/icons/ChevronDown.svg';
import Colors from '../../theme/Colors';
import '../../css/Report.css';

import pathwayOptionsJSON from '../../../data/pathwayOptions.json';

type Params = {
  reportId: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

type Item = {
  id: string;
  createdAt: number;
  title: {
    'en-GB': string;
    'nl-NL': string;
  }
  description: {
    'en-GB': string;
    'nl-NL': string;
  }
};

const keyExtractor = (item: Item) => item.id;

const renderHeader = (language: string) => (item: Item, isActive: boolean) => {
  const title = getLocalizedText(item, language, 'title');
  const { id } = item;
  const icon = get(pathwayIcons, id);

  return (
    <>
      <ListItem
        primaryText={title}
        leftIcon={icon}
        rightIcon={
          isActive
            ? <IconChevronUp fill={Colors.primary} className="icon small" />
            : <IconChevronDown fill={Colors.primary} className="icon small" />
        }
        divider
      />
    </>
  );
};

const renderContent = (language: string) => (item: Item, isActive: boolean) => {
  const { description: itemDescription } = item;
  const description = getLocalizedText(itemDescription, language);

  if (!isActive) {
    return null;
  }

  return <section className="report-container"><MarkdownView markdown={description} /></section>;
};

const DecisionAidReport = () => {
  const { reportId } = useParams<Params>();

  const history = useHistory();

  const memoizedReportsGetByIdSelector = useMemo(
    reportsSelectors.makeGetById,
    [],
  );

  const report = useSelector(
    (reduxState: AppState) => memoizedReportsGetByIdSelector(reduxState, reportId),
  );

  const decisionAidQuestions = useSelector(
    (reduxState: AppState) => decisionAidQuestionsSelectors.getAll(reduxState),
  );

  const [activeSections, setActiveSections] = useState<number[]>([]);
  const [title, setTitle] = useState(get(report, 'title'));
  const [createdAt, setCreatedAt] = useState(toDate(get(report, 'createdAt')));

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const options = sortBy(
    map(pathwayOptionsJSON, (option) => {
      const score = sum(compact(map(report.content.scores, (s) => (
        get(s, [option.id])
      ))));

      return {
        ...option,
        score,
      };
    }),
    'score',
  );

  // Filter options (max 3) based on risk profile
  const filteredOptions = slice(
    filter(
      options,
      ({ riskProfiles }) => includes(riskProfiles, report.content.riskProfile),
    ),
    0,
    3,
  );

  const answers = join(
    map(decisionAidQuestions, (question) => {
      const answerId = get(report, ['content', 'answers', question.id]);
      const answer = find(question.answers, { id: answerId });

      const questionTitle = getLocalizedText(question, i18n.language, 'title');
      const answerTitle = getLocalizedText(answer, i18n.language, 'title');

      return `* ${questionTitle}: **${answerTitle}**`;
    }),
    '\n',
  );

  // Split on $pathwayOptions so we can show the carousel in between
  const [part1, part2] = split(
    replace(get(markdown, [i18n.language]), '$answers', answers),
    '$pathwayOptions',
  );

  const handleSave = () => {
    dispatch(reportsActions.update([{
      ...report,
      createdAt: getUnixTime(createdAt),
      updatedAt: getUnixTime(),
      title,
    }]));

    history.push('/treatments');
  };

  return (
    <article className="page decision-aid-report row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={title} showRightButton />
          <label htmlFor="title">
            <span className="body light">{t('title')}</span>
            <input
              value={title}
              onChange={(event) => setTitle(event.currentTarget.value)}
              name="title"
              id="title"
            />
          </label>
          <label // eslint-disable-line jsx-a11y/label-has-associated-control
            htmlFor="created-at"
          >
            <span className="body light">{t('date-time')}</span>
            <DatePicker
              selected={createdAt}
              onChange={(date: Date) => setCreatedAt(date)}
              showTimeSelect
              dateFormat="dd/LL/yyyy HH:mm"
              timeFormat="HH:mm"
              id="created-at"
            />
          </label>
          <section className="decision-aid-report-container">
            <section className="report-container">
              <MarkdownView markdown={part1} />
            </section>
            <hr className="divider" />
            <Accordion
              sections={filteredOptions}
              activeSections={activeSections}
              onChange={setActiveSections}
              renderHeader={renderHeader(i18n.language)}
              renderContent={renderContent(i18n.language)}
              keyExtractor={keyExtractor}
            />
            <section className="report-container">
              <MarkdownView markdown={part2} />
            </section>
          </section>
          <Button labelText={t('save')} size="medium" onClick={handleSave} />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default DecisionAidReport;
