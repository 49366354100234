import React, { useState, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MarkdownView from 'react-showdown';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { AppState } from '../state/reducers';

import { selectors as reportsSelectors, actions as reportsActions } from '../state/reports';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import Button from '../components/Button';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import DecisionAidReport from './DecisionAidReport';
import Modal from '../components/Modal';
import '../css/Report.css';
import Colors from '../theme/Colors';
import getUnixTime from '../utils/getUnixTime';

type Params = {
  reportId: string;
};

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Report = () => {
  const { t } = useTranslation();

  const { reportId } = useParams<Params>();

  const [visible, setVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const history = useHistory();

  const dispatch = useDispatch();

  const location = useLocation();

  const fromProfileComplete = get(location, 'state.fromProfileComplete');

  const memoizedReportsGetByIdSelector = useMemo(
    reportsSelectors.makeGetById,
    [],
  );

  const report = useSelector(
    (state: AppState) => memoizedReportsGetByIdSelector(state, reportId),
  );

  let title = t('report');

  if (!isEmpty(report)) {
    title = t(`reports:${report.type}`);
  }

  if (report.type === 'decisionAid-full') {
    return <DecisionAidReport />;
  }

  const handleBackClick = () => {
    if (fromProfileComplete) {
      return setVisible(true);
    }

    return history.goBack();
  };

  const handleDeleteReport = () => {
    dispatch(reportsActions.remove([report.id]));

    history.goBack();
  };

  const handleSave = () => {
    dispatch(reportsActions.update([{
      ...report,
      createdAt: getUnixTime(report.createdAt),
      updatedAt: getUnixTime(),
      title,
    }]));

    history.goBack();
  };

  return (
    <>
      <article className="page report row">
        <Navigation />
        <section className="container">
          <section className="content">
            <DrawerButtons title={title} showRightButton />
            <button
              type="button"
              className="navigation accent back"
              onClick={handleBackClick}
            >
              &lt; {t('back')}
            </button>
            {
              !isEmpty(report)
                ? (
                  <section className="report-container">
                    <MarkdownView markdown={get(report, 'content.body')} />

                    {!fromProfileComplete ? (
                      <div className="report-button-wrapper">
                        <Button
                          labelText={t('save')}
                          onClick={handleSave}
                        />
                        <Button
                          labelText={t('delete')}
                          extraClass="delete-report"
                          color={Colors.destructiveRed}
                          onClick={toggleDeleteModal}
                        />
                      </div>
                    ) : null}
                  </section>
                )
                : <p className="body light">{t('reports:empty-state-not-found-heading')}</p>
            }
            {
              fromProfileComplete
                ? (
                  <Button
                    labelText={t('next')}
                    size="medium"
                    onClick={() => history.push('/')}
                  />
                )
                : null
            }
          </section>
          <Footer />
        </section>
        <DrawerRight />
      </article>
      <Modal
        visible={visible}
        hideModal={() => setVisible(false)}
        actions={[
          {
            title: t('cancel'),
            onClick: () => setVisible(false),
          },
          {
            title: t('continue'),
            onClick: () => {
              setVisible(false);
              history.push('/');
            },
            destructive: true,
          },
        ]}
      >
        <h1 className="heading">{t('alerts.profile-complete-report-close.title')}</h1>
        <p className="body">{t('alerts.profile-complete-report-close.body')}</p>
      </Modal>

      <Modal
        visible={showDeleteModal}
        hideModal={toggleDeleteModal}
        actions={[
          {
            title: t('cancel'),
            onClick: toggleDeleteModal,
          },
          {
            title: t('delete'),
            onClick: handleDeleteReport,
            destructive: true,
          },
        ]}
      >
        <h1 className="heading">{t('alerts.delete.title', { w: t(`reports:${report.type}`) })}</h1>
        <p className="body">{t('alerts.delete.body')}</p>
      </Modal>
    </>
  );
};

export default Report;
