const config = {
  api: {
    timeout: 20000,
  },
  authApi: {
    dev: {
      url: 'https://auth.dev.uk.owise.net/api/1/auth',
    },
    staging: {
      url: 'https://auth.stg.uk.owise.net/api/1/auth',
    },
    prod: {
      url: 'https://auth.owise.net/api/1/auth',
    },
    timeout: 20000,
  },
  environments: [
    'dev',
    'staging',
    'prod',
  ],
  showEnvironments: true,
  document: {
    id: 'ab854cda-e48c-4c4c-a120-eb4ab9b38168',
    systemDocumentRefreshInterval: 60 * 60 * 1000,
    userDocumentRefreshInterval: 1 * 60 * 1000,
    failureRetryDelay: 1 * 60 * 1000,
  },
  dailySuggestions: {
    max: 5,
    intervalInHours: 24,
  },
  languageMenuEnable: true,
  developmentMenuEnable: true,
  photoUploadButtonEnable: false,
  photoDownloadButtonEnable: false,
  audioRecordingUploadButtonEnable: false,
  audioRecordingDownloadButtonEnable: false,
  enableGraphListFullScreen: false,
  shareMyDataEnable: false,
  hospitalAccesCodeEnable: false,
  availableLanguages: {
    uk: [
      { name: 'English (UK)', language: 'en-GB', default: true },
    ],
    nl: [
      { name: 'Nederlands (NL)', language: 'nl-NL', default: true },
    ],
  },
  isPC: true,
  isBC: false,
  countly: {
    url: 'https://analytics.pxhealthcare.com',
    appKey: '1aeb95e9a48134386a97de68bed8375bb2ec1a24',
  },
  testUserCodePatterns: ['TEST', 'LYN', 'DM1', 'DM2', 'ENH'],
};

export default config;
