import { takeLatest, delay, put } from 'redux-saga/effects';
import localforage from 'localforage';
import Crypto from 'crypto-js';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import noop from 'lodash/noop';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import random from 'lodash/random';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import isArray from 'lodash/isArray';

import authApi from '../services/authApi';
import api from '../services/api';
import { getLocale as i18nGetLocale } from '../services/i18n';
import {
  actionTypes as apiActionTypes,
  ApiAuthRegisterActionType,
  ApiAuthLoginActionType,
  ApiAuthResetActionType,
  ApiAuthPasswordResetActionType,
  ApiAuthPasswordActionType,
  ApiAuthEmailVerifyActionType,
  ApiValidateInviteActionType,
  ApiShareTrendsActionType,
  ApiDeactivateAccountActionType,
  ApiDeleteAccountActionType,
  ApiBLOBPostActionType, ApiShareCustomTrendsByIdActionType,
} from '../state/api';
import { actions as authActions } from '../state/auth';
import { actions as tokenActions } from '../state/token';
import { actions as settingsActions } from '../state/settings';
import { actions as photosActions } from '../state/photos';
import { syncUserData } from './watchSync';
import formatLocale from '../utils/formatLocale';
import getBrowserLanguage from '../utils/getBrowserLanguage';
import getApiTokenAsync from '../utils/getApiTokenAsync';

import invitationCodesJSON from '../data/invitation-codes.json';
import localConfig from '../config';
import getConfigAsync from '../utils/getConfigAsync';
import getEmailAsync from '../utils/getEmailAsync';
import { TPhoto } from '../../types';
import getUnixTime from '../utils/getUnixTime';

const DEV = process.env.NODE_ENV !== 'production';

function* authRegister(action: ApiAuthRegisterActionType) {
  const {
    payload: {
      email: actionEmail,
      password,
      environment,
      additionalData,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const email = toLower(actionEmail);
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!email) {
    if (DEV) {
      console.log('[authRegister]', 'Missing email'); // eslint-disable-line no-console
    }

    return onError({ code: 'err_malformed_email' });
  }

  if (!password) {
    if (DEV) {
      console.log('[authRegister]', 'Missing password'); // eslint-disable-line no-console
    }

    return onError({ code: 'err_malformed_request' });
  }

  if (!isEmpty() && !isPlainObject(additionalData)) {
    if (DEV) {
      console.log('[authRegister]', 'Invalid additionalData', additionalData); // eslint-disable-line no-console
    }

    return onError({ code: 'err_malformed_request' });
  }

  try {
    const data = {
      email,
      password,
      locale: i18nGetLocale(),
      additionalData,
      requestDocumentId: localConfig.document.id,
      deviceLocale: formatLocale(getBrowserLanguage()),
      authType: 1,
    };

    // Make sure environment setting does not exist
    yield localforage.removeItem('@Settings:environment');

    // Create setting when not using prod env. This setting is used in the authApi
    // interceptor.
    if (
      environment
      && environment !== 'prod'
      && includes(localConfig.environments, environment)
    ) {
      yield localforage.setItem('@Settings:environment', environment);
    }

    const response: AxiosResponse<any> = yield authApi.post('/register', data);

    if (response.status !== 204) {
      if (DEV) {
        console.log('[authRegister]', 'Received invalid HTTP status code', response); // eslint-disable-line no-console
      }

      const errorCode = get(response, 'data.code');

      return onError({ code: errorCode });
    }

    return onSuccess(response);
  } catch (error: any) {
    if (DEV) {
      console.log('[authRegister]', 'catch', error); // eslint-disable-line no-console
      console.log('[authRegister]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* authLogin(action: ApiAuthLoginActionType) {
  const {
    payload: {
      email: actionEmail,
      password,
      environment,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const email = toLower(actionEmail);
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!email || !password) {
    if (DEV) {
      console.log('[authLogin]', 'Missing email and/or password'); // eslint-disable-line no-console
    }

    return onError({ code: 'err_invalid_credentials' });
  }

  const hash = Crypto.SHA256(`${localConfig.document.id}-${email}`);
  const emailHash = hash.toString(Crypto.enc.Hex);

  try {
    const data = {
      emailHash,
      password,
      locale: i18nGetLocale(),
      requestDocumentId: localConfig.document.id,
    };

    // Make sure environment setting does not exist
    yield localforage.removeItem('@Settings:environment');

    // Create setting when not using prod env. This setting is used in the authApi
    // interceptor.
    if (
      environment
      && environment !== 'prod'
      && includes(localConfig.environments, environment)
    ) {
      yield localforage.setItem('@Settings:environment', environment);
    }

    const response: AxiosResponse<any> = yield authApi.post('/login', data);

    const token = get(response, 'data.token');
    const invitationCodes = get(response, 'data.additionalData.invitationCodes');

    if (!token) {
      if (DEV) {
        console.log('[authLogin]', 'No token'); // eslint-disable-line no-console
      }

      return onError({ code: 'err_invalid_credentials' });
    }

    yield localforage.setItem('@api/token', token);
    yield localforage.setItem('email', email);

    const userSynced = yield* syncUserData(true);

    if (!userSynced) {
      throw new Error('Error loading user data');
    }

    if (isArray(invitationCodes) && !isEmpty(invitationCodes)) {
      yield put(settingsActions.invitationCodesAdd(invitationCodes));
    }

    yield put(settingsActions.generalUpdate({ appLanguage: i18nGetLocale() }));

    yield put(authActions.set(true));

    return onSuccess({ ...response });
  } catch (error: any) {
    if (DEV) {
      console.log('[authLogin]', 'catch', error); // eslint-disable-line no-console
      console.log('[authLogin]', 'catch', error.response); // eslint-disable-line no-console
    }

    // Remove token
    yield localforage.removeItem('@api/token');

    return onError(error);
  }
}

function* authReset(action: ApiAuthResetActionType) {
  const {
    payload: {
      email: actionEmail,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const email = toLower(actionEmail);
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!email) {
    return onError({ code: 'err_malformed_request' });
  }

  try {
    const data = {
      email,
      locale: i18nGetLocale(),
      requestDocumentId: localConfig.document.id,
      authType: 1,
    };

    const response: AxiosResponse<any> = yield authApi.post('/reset', data);

    if (response.status !== 204) {
      if (DEV) {
        console.log('[authReset]', 'Received invalid HTTP status code', response); // eslint-disable-line no-console
      }

      return onError({ code: 'err_unknown' });
    }

    return onSuccess(response);
  } catch (error: any) {
    if (DEV) {
      console.log('[authReset]', 'catch', error); // eslint-disable-line no-console
      console.log('[authReset]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* authPasswordReset(action: ApiAuthPasswordResetActionType) {
  const {
    payload: {
      password,
      password2,
      token,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!password || !password2 || !token) {
    return onError({ code: 'err_malformed_request' });
  }

  try {
    const data = {
      password,
      password2,
      token,
      locale: i18nGetLocale(),
      requestDocumentId: localConfig.document.id,
      authType: 1,
    };

    // The request should use 'content-type: application/x-www-form-urlencoded'
    const response: AxiosResponse<any> = yield authApi.post('/reset', qs.stringify(data));

    if (response.status !== 200) {
      if (DEV) {
        console.log('[authPasswordReset]', 'Received invalid HTTP status code', response); // eslint-disable-line no-console
      }

      return onError({ code: 'err_unknown' });
    }

    return onSuccess(response);
  } catch (error: any) {
    if (DEV) {
      console.log('[authPasswordReset]', 'catch', error); // eslint-disable-line no-console
      console.log('[authPasswordReset]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* authPassword(action: ApiAuthPasswordActionType) {
  const {
    payload: {
      password,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!password) {
    return onError({ code: 'err_malformed_request' });
  }

  try {
    const data = {
      password,
    };

    const token: string = yield getApiTokenAsync();

    if (!token) {
      if (DEV) {
        console.error('[authPassword] Invalid or missing token'); // eslint-disable-line no-console
      }

      throw new Error('Invalid or missing token');
    }

    const headers = {
      authentication: `Bearer ${token}`,
    };

    const response: AxiosResponse<any> = yield authApi.post('/password', data, { headers });

    if (response.status !== 204) {
      if (DEV) {
        console.log('[authPassword]', 'Received invalid HTTP status code', response); // eslint-disable-line no-console
      }

      const errorCode = get(response, 'data.code');

      return onError({ code: errorCode });
    }

    // TODO: Update token somehow

    return onSuccess(response);
  } catch (error: any) {
    if (DEV) {
      console.log('[authPassword]', 'catch', error); // eslint-disable-line no-console
      console.log('[authPassword]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* authEmailVerify(action: ApiAuthEmailVerifyActionType) {
  const {
    payload: {
      token,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!token) {
    return onError({ code: 'err_malformed_request' });
  }

  try {
    const params = {
      'register-token': token,
      locale: i18nGetLocale(),
      authType: 1,
    };

    const response: AxiosResponse<any> = yield authApi.get('/register', { params });

    if (response.status !== 200) {
      if (DEV) {
        console.log('[authEmailVerify]', 'Received invalid HTTP status code', response); // eslint-disable-line no-console
      }

      return onError({ code: 'err_unknown' });
    }

    return onSuccess(response);
  } catch (error: any) {
    if (DEV) {
      console.log('[authEmailVerify]', 'catch', error); // eslint-disable-line no-console
      console.log('[authEmailVerify]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* validateInvite(action: ApiValidateInviteActionType) {
  const {
    payload: {
      code,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  if (!code) {
    return onError({ code: 'err_invalid_code' });
  }

  try {
    // const data = {
    //   code,
    // };

    // const response = yield api.post('/validate/invite', data);

    // Mock api response delay
    yield delay(random(1000, 2000));

    // Mock response
    let response = {
      status: 204,
      data: {},
    };

    if (!includes(invitationCodesJSON, toLower(code))) {
      response = {
        status: 400,
        data: {
          code: 'err_invalid_code',
        },
      };
    }

    if (response.status === 400) {
      const errorCode = get(response, 'data.code');

      return onError({ code: errorCode });
    }

    return onSuccess(response);
  } catch (error: any) {
    if (DEV) {
      console.log('[validateInvite]', 'catch', error); // eslint-disable-line no-console
      console.log('[validateInvite]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

function* BLOBPost(action: ApiBLOBPostActionType): Generator<any, any, any> {
  const {
    payload: {
      data,
      type,
      contentType,
      file,
      onSuccess: actionOnSuccess,
      onError: actionOnError,
    },
  } = action;

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  const id = get(data, 'id');
  const uri = get(data, 'uri');

  if (
    isEmpty(data)
    || !isPlainObject(data)
    || !id
    || !uri
    || !file
  ) {
    if (__DEV__) {
      console.log('[BLOBPost]', 'Invalid data object', data); // eslint-disable-line no-console
    }

    return onError({ code: 'err_unknown' });
  }

  if (type !== 'photo' && type !== 'audioRecording') {
    if (__DEV__) {
      console.log('[BLOBPost]', 'Invalid type', type); // eslint-disable-line no-console
    }

    return onError({ code: 'err_unknown' });
  }

  if (!contentType) {
    if (__DEV__) {
      console.log('[BLOBPost]', 'Missing contentType', contentType); // eslint-disable-line no-console
    }

    return onError({ code: 'err_unknown' });
  }

  try {
    const responseBLOB = yield api.post('/blob', { size: file.size });

    if (responseBLOB.status !== 200) {
      if (__DEV__) {
        console.log('[BLOBPost]', 'Received invalid HTTP status code', responseBLOB); // eslint-disable-line no-console
      }

      // TODO: Retry failed uploads

      return onError({ code: 'err_unknown' });
    }

    const uploadUrl = get(responseBLOB, 'data.uploadUrl');
    const uploadMethod = get(responseBLOB, 'data.uploadMethod');
    const contentPermalink = get(responseBLOB, 'data.contentPermalink');

    if (!uploadUrl || !uploadMethod || !contentPermalink) {
      if (__DEV__) {
        console.log('[BLOBPost]', 'uploadUrl, uploadMethod or contentPermalink empty', responseBLOB); // eslint-disable-line no-console
      }

      return onError({ code: 'err_unknown' });
    }

    const responseUpload = yield fetch(uploadUrl, {
      method: uploadMethod,
      body: file,
    });

    const status = yield responseUpload.status;

    if (status !== 200 && status !== 204) {
      if (__DEV__) {
        console.log('Upload failed', responseUpload); // eslint-disable-line no-console
      }
    }

    if (status !== 200 && status !== 204) {
      if (__DEV__) {
        console.log('Upload failed', responseUpload); // eslint-disable-line no-console
      }
      return onError({ code: 'err_unknown' });
    }

    if (type === 'photo') {
      yield put(photosActions.add([{
        ...data as TPhoto,
        uploadedAt: getUnixTime(),
        uri: contentPermalink,
      }]));
    }

    return onSuccess();
  } catch (error: any) {
    if (__DEV__) {
      console.log('[BLOBPost]', 'catch', error); // eslint-disable-line no-console
      console.log('[BLOBPost]', 'catch', error.response); // eslint-disable-line no-console
    }

    // TODO: Retry failed uploads

    return onError(error);
  }
}

function* shareTrends(action: ApiShareTrendsActionType) {
  const {
    payload: {
      onLoadLinkStart: actionOnLoadStart,
      onLoadLinkSuccess: actionOnSuccess,
      onLoadLinkError: actionOnError,
    },
  } = action;

  const onLoad = isFunction(actionOnLoadStart) ? actionOnLoadStart : noop;
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  try {
    onLoad();

    const response: AxiosResponse<any> = yield api.post(`/user/current/${localConfig.document.id}/share/link`);

    if (response.status === 401) {
      if (__DEV__) {
        console.log('[shareTrends]', 'Refresh token', response.status); // eslint-disable-line no-console
      }

      yield put(tokenActions.refresh());

      return onError();
    }

    if (response.status !== 201) {
      if (__DEV__) {
        console.log('[shareTrends]', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    const link = get(response, 'data.link');

    if (isEmpty(link)) {
      if (__DEV__) {
        console.log('[shareTrends]', 'Empty link response', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    return onSuccess(link);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[shareTrends]', 'catch', error); // eslint-disable-line no-console
      console.log('[shareTrends]', 'catch', error.response); // eslint-disable-line no-console
    }
    return onError();
  }
}

function* shareCustomTrendsById(
  action: ApiShareCustomTrendsByIdActionType,
):Generator<any, any, any> {
  const {
    payload: {
      onLoadLinkStart: actionOnLoadStart,
      onLoadLinkSuccess: actionOnSuccess,
      onLoadLinkError: actionOnError,
      trends,
      startDate,
      endDate,
    },
  } = action;

  const onLoad = isFunction(actionOnLoadStart) ? actionOnLoadStart : noop;
  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  const data: { data_list: string[], start_date?: number, end_date?: number } = {
    data_list: trends,
  };

  if (startDate) {
    data.start_date = getUnixTime(startDate);
  }

  if (endDate) {
    data.end_date = getUnixTime(endDate);
  }

  try {
    onLoad();
    const { userId } = yield getConfigAsync();

    const response = yield api.post(
      `/user/${userId}/${localConfig.document.id}/share/link?source=document&type=shareById`,
      data,
    );

    if (response.status === 401) {
      if (__DEV__) {
        console.log('[shareCustomTrendsByID]', 'Refresh token', response.status); // eslint-disable-line no-console
      }

      yield put(tokenActions.refresh());

      return onError();
    }

    if (response.status !== 201) {
      if (__DEV__) {
        console.log('[shareCustomTrendsByID]', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    const link = get(response, 'data.link');
    if (isEmpty(link)) {
      if (__DEV__) {
        console.log('[shareCustomTrendsByID]', 'Empty link response', response.status); // eslint-disable-line no-console
      }

      return onError();
    }

    return onSuccess(link);
  } catch (error: any) {
    if (__DEV__) {
      console.log('[shareCustomTrendsByID]', 'catch', error); // eslint-disable-line no-console
      console.log('[shareCustomTrendsByID]', 'catch', error.response); // eslint-disable-line no-console
    }
    return onError();
  }
}

function* deleteAccount(action: ApiDeleteAccountActionType): Generator<any, any, any> {
  const {
    payload: {
      onSuccess: actionOnSuccess,
      onError: actionOnError,
      action: deletionAction,
      partial,
    },
  } = action;

  const email = yield getEmailAsync();

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  const { userId } = yield getConfigAsync();

  const locale = i18nGetLocale();
  try {
    const stringPartial = partial.toString();

    yield api.post(
      `/user/${userId}/${localConfig.document.id}/delete?action=${deletionAction}&partial=${stringPartial}&locale=${locale}`,
      { email },
    );

    const userSynced = yield syncUserData(true);

    if (!userSynced) {
      throw new Error('Error loading user data');
    }

    return onSuccess();
  } catch (error: any) {
    if (__DEV__) {
      console.log('[deleteAccount]', 'catch', error); // eslint-disable-line no-console
      console.log('[deleteAccount]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}
function* deactivateAccount(action: ApiDeactivateAccountActionType): Generator<any, any, any> {
  const {
    payload: {
      onSuccess: actionOnSuccess,
      onError: actionOnError,
      action: deactivationAction,
    },
  } = action;

  const email = yield getEmailAsync();

  const onSuccess = isFunction(actionOnSuccess) ? actionOnSuccess : noop;
  const onError = isFunction(actionOnError) ? actionOnError : noop;

  const token = yield getApiTokenAsync();
  const { userId } = yield getConfigAsync();
  const locale = i18nGetLocale();
  try {
    yield authApi.post(
      `/deactivate?action=${deactivationAction}&userId=${userId}&locale=${locale}`,
      { email, requestDocumentId: localConfig.document.id },
      { headers: { Authentication: `Bearer ${token}` } },
    );

    const userSynced = yield syncUserData(true);

    if (!userSynced) {
      throw new Error('Error loading user data');
    }

    return onSuccess();
  } catch (error: any) {
    if (__DEV__) {
      console.log('[deactivateAccount]', 'catch', error); // eslint-disable-line no-console
      console.log('[deactivateAccount]', 'catch', error.response); // eslint-disable-line no-console
    }

    return onError(error);
  }
}

export default function* watchApi() {
  yield takeLatest(apiActionTypes.AUTH_REGISTER, authRegister);
  yield takeLatest(apiActionTypes.AUTH_LOGIN, authLogin);
  yield takeLatest(apiActionTypes.DEACTIVATE_ACCOUNT, deactivateAccount);
  yield takeLatest(apiActionTypes.DELETE_ACCOUNT, deleteAccount);
  yield takeLatest(apiActionTypes.AUTH_RESET, authReset);
  yield takeLatest(apiActionTypes.AUTH_PASSWORD, authPassword);
  yield takeLatest(apiActionTypes.AUTH_PASSWORD_RESET, authPasswordReset);
  yield takeLatest(apiActionTypes.AUTH_EMAIL_VERIFY, authEmailVerify);
  yield takeLatest(apiActionTypes.VALIDATE_INVITE, validateInvite);
  yield takeLatest(apiActionTypes.SHARE_TRENDS, shareTrends);
  yield takeLatest(apiActionTypes.SHARE_CUSTOM_TRENDS_BY_ID, shareCustomTrendsById);
  yield takeLatest(apiActionTypes.BLOB_POST, BLOBPost);
}
