import * as actionTypes from './actionTypes';
import {
  TActions,
  TUpdateTreatmentTypeIdPayload,
  TUpdateTreatmentTypeOptionIdPayload,
  TUpdateTreatmentTypeTextPayload,
  TUpdateStartAtPayload,
  TUpdateEndAtPayload,
  TUpdateDurationPayload,
  TUpdateCompletedPayload,
} from './types';

const updateTreatmentTypeId = (payload: TUpdateTreatmentTypeIdPayload): TActions => ({
  type: actionTypes.UPDATE_TREATMENT_TYPE_ID,
  payload,
});

const updateTreatmentTypeOptionId = (payload: TUpdateTreatmentTypeOptionIdPayload): TActions => ({
  type: actionTypes.UPDATE_TREATMENT_TYPE_OPTION_ID,
  payload,
});

const updateTreatmentTypeText = (payload: TUpdateTreatmentTypeTextPayload): TActions => ({
  type: actionTypes.UPDATE_TREATMENT_TYPE_TEXT,
  payload,
});

const updateStartAt = (payload: TUpdateStartAtPayload): TActions => ({
  type: actionTypes.UPDATE_START_AT,
  payload,
});

const updateEndAt = (payload: TUpdateEndAtPayload): TActions => ({
  type: actionTypes.UPDATE_END_AT,
  payload,
});

const updateDuration = (payload: TUpdateDurationPayload): TActions => ({
  type: actionTypes.UPDATE_DURATION,
  payload,
});

const updateCompleted = (payload: TUpdateCompletedPayload): TActions => ({
  type: actionTypes.UPDATE_COMPLETED,
  payload,
});

export {
  updateTreatmentTypeId,
  updateTreatmentTypeOptionId,
  updateTreatmentTypeText,
  updateStartAt,
  updateEndAt,
  updateDuration,
  updateCompleted,
};
