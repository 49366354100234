import { combineReducers } from 'redux';
import jsonpatch from 'fast-json-patch';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import authReducer from '../auth/reducer';
import appointmentsReducer from '../appointments/reducer';
import messagesReducer from '../messages/reducer';
import notificationsReducer from '../notifications/reducer';
import notesReducer from '../notes/reducer';
import photosReducer from '../photos/reducer';
import audioRecordingsReducer from '../audioRecordings/reducer';
import treatmentTypesReducer from '../treatmentTypes/reducer';
import treatmentsReducer from '../treatments/reducer';
import trendIndicatorsReducer from '../trendIndicators/reducer';
import trendsReducer from '../trends/reducer';
import templatesReducer from '../templates/reducer';
import questionnairesReducer from '../questionnaires/reducer';
import profileQuestionsReducer from '../profileQuestions/reducer';
import profileQuestionCategoriesReducer from '../profileQuestionCategories/reducer';
import profileQuestionAnswersReducer from '../profileQuestionAnswers/reducer';
import hospitalsReducer from '../hospitals/reducer';
import hospitalAccessCodesReducer from '../hospitalAccessCodes/reducer';
import dailySuggestionsReducer from '../dailySuggestions/reducer';
import settingsReducer from '../settings/reducer';
import syncReducer from '../sync/reducer';
import glossaryReducer from '../glossary/reducer';
import usefulLinksReducer from '../usefulLinks/reducer';
import uiReducer from '../ui/reducer';
import runtimeReducer from '../runtime/reducer';
import draftsReducer from '../drafts/reducer';
import sharedDataReducer from '../sharedData/reducer';
import tagsReducer from '../tags/reducer';
import decisionAidQuestionsReducer from '../decisionAidQuestions/reducer';
import generatedReportsReducer from '../generatedReports/reducer';
import reportsReducer from '../reports/reducer';
import contactsReducer from '../contacts/reducer';
import generatedSuggestedQuestionsReducer from '../generatedSuggestedQuestions/reducer';
import { UPDATE_USER_DATA } from '../sync/actionTypes';
// eslint-disable-next-line import/no-cycle
import { getCurrentUserData, getLastUserData } from '../sync/selectors';

const appReducer = combineReducers({
  auth: authReducer,
  appointments: appointmentsReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  notes: notesReducer,
  photos: photosReducer,
  audioRecordings: audioRecordingsReducer,
  treatmentTypes: treatmentTypesReducer,
  treatments: treatmentsReducer,
  trendIndicators: trendIndicatorsReducer,
  trends: trendsReducer,
  questionnaires: questionnairesReducer,
  profileQuestions: profileQuestionsReducer,
  profileQuestionCategories: profileQuestionCategoriesReducer,
  profileQuestionAnswers: profileQuestionAnswersReducer,
  hospitals: hospitalsReducer,
  hospitalAccessCodes: hospitalAccessCodesReducer,
  dailySuggestions: dailySuggestionsReducer,
  sync: syncReducer,
  settings: settingsReducer,
  glossary: glossaryReducer,
  usefulLinks: usefulLinksReducer,
  ui: uiReducer,
  runtime: runtimeReducer,
  drafts: draftsReducer,
  sharedData: sharedDataReducer,
  tags: tagsReducer,
  decisionAidQuestions: decisionAidQuestionsReducer,
  generatedReports: generatedReportsReducer,
  reports: reportsReducer,
  contacts: contactsReducer,
  generatedSuggestedQuestions: generatedSuggestedQuestionsReducer,
  templates: templatesReducer,
});

const patchUserData = (state: any, payload: any): any => {
  // Fetch all the userdata fields from the state
  const userdata = getCurrentUserData(state);

  // Load the last stored data from the server
  const lastData = getLastUserData(state);

  if (!lastData) {
    // If there is no data yet, then use the downloaded data as new state.
    return payload;
  }

  // Generate a patch between the userdata and the original data
  const patch = jsonpatch.compare(lastData, userdata);

  // Return the new user state if nothing changed
  if (!patch || patch.length < 1) {
    return payload;
  }

  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.info('[patchUserData] Applying patch', JSON.stringify(patch));
  }

  // Apply the changes on the new serverdata
  const updatedState = cloneDeep(payload);

  patch.forEach((operation, index) => {
    try {
      jsonpatch.applyOperation(updatedState, operation, true, true, true, index);
    } catch (e) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.warn('[patchUserData] Failed to apply patch operation, ignoring', e);
      }
    }
  });

  return updatedState;
};

const rootReducer = (state: any, action: any) => {
  let newState = state;

  switch (action.type) {
    case 'RESET': {
      // Clear all data in redux store to initial state
      newState = undefined;
      break;
    }
    case UPDATE_USER_DATA: {
      // Fetch the changes made to the userdata
      const patchedUserData = patchUserData(state, action.payload);

      // Merge patchedUserData into current state
      const patchedState = {
        ...state,
        ...patchedUserData,
      };

      // Only update the state if patchedState and state are not equal.
      // This will prevent unnecessary re-renders.
      if (!isEqual(patchedState, state)) {
        newState = patchedState;
      }

      break;
    }
    default:
      break;
  }

  return appReducer(newState, action);
};

export type AppState = ReturnType<typeof appReducer>;

export default rootReducer;
