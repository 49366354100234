import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner';

import Footer from '../components/Footer';
import Modal from '../components/Modal';
import Button from '../components/Button';
import '../css/BlockedAccount.css';
import { actions as apiActions } from '../state/api';
import { AppState } from '../state/reducers';
import { actions as UIActions } from '../state/ui';
import { actions as authActions } from '../state/auth';
import Colors from '../theme/Colors';

const BlockedAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const status = useSelector((state: AppState) => state.settings.accountStatus.status);

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const reactivateAccountButtonPress = () => {
    setLoading(true);

    if (status === 'deactivated') {
      dispatch(apiActions.deactivateAccount({
        action: 'reactivation',
        onError: () => {
          dispatch(UIActions.addNotification({ text: t('unknown-error'), type: 'error' }));
          setLoading(false);
        },
        onSuccess: () => {
          toggleModal();
          setLoading(false);
        },
      }));
      return;
    }

    dispatch(apiActions.deleteAccount({
      action: 'cancel-deletion',
      onError: () => {
        dispatch(UIActions.addNotification({ text: t('unknown-error'), type: 'error' }));
        setLoading(false);
      },
      onSuccess: () => {
        toggleModal();
        setLoading(false);
      },
      partial: false,
    }));
  };

  const logoutButtonPress = () => {
    dispatch(authActions.logout());
  };

  return (
    <article className="page blocked-account row">
      <section className="container">
        <section className="content">
          <h1 className="heading-large">{t(`screens.changeAccount.${status}.title`)}</h1>
          <p> {t(`screens.changeAccount.${status}.subtitle`)}</p>
          <Button labelText={t(`screens.changeAccount.${status}.button`)} onClick={reactivateAccountButtonPress} />
          <p className="explanation">{t('screens.changeAccount.explanation')}</p>
          <p className="explanation">{t('screens.changeAccount.checkSpam')}</p>
          <Button labelText={t('logout')} onClick={logoutButtonPress} />
          {loading
            ? <div className="loader-wrapper"> <Loader type="TailSpin" color={Colors.primary} height={100} width={100} /> </div>
            : null}
        </section>
        <Footer />
      </section>

      <Modal
        visible={showModal}
        hideModal={toggleModal}
        actions={[
          {
            title: t('close'),
            onClick: toggleModal,
          },
        ]}
      >
        <>
          <h1 className="heading">{t(`screens.changeAccount.${status}.modalText`)}</h1>
        </>
      </Modal>
    </article>
  );
};

export default BlockedAccount;
