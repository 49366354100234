import * as actionTypes from './actionTypes';
import {
  DailySuggestionsActionTypes,
  DailySuggestionsAddPayload,
  DailySuggestionsUpdatePayload,
} from './types';

const add = (payload: DailySuggestionsAddPayload): DailySuggestionsActionTypes => ({
  type: actionTypes.ADD,
  payload,
});

const update = (payload: DailySuggestionsUpdatePayload): DailySuggestionsActionTypes => ({
  type: actionTypes.UPDATE,
  payload,
});

const check = (payload?: boolean): DailySuggestionsActionTypes => ({
  type: actionTypes.CHECK,
  payload,
});

const clear = (): DailySuggestionsActionTypes => ({
  type: actionTypes.CLEAR,
});

export {
  add,
  update,
  check,
  clear,
};
