/* eslint global-require: 0 */

export default {
  icons: {
    sdm: require('../assets/images/icons/sdm.png'),
  },
  personalize: {
    personalize: require('../assets/images/personalize/personalize.png'),
  },
  profileQuestions: {
    '962b5407-86ed-4bf2-9d3b-f21850350b65': {
      'en-GB': {
        '5694d29f-a780-48ce-b0b2-b2069c67f21a': require('../assets/images/profileQuestions/962b5407-86ed-4bf2-9d3b-f21850350b65/5694d29f-a780-48ce-b0b2-b2069c67f21a.png'),
        '866efcdf-f58b-4503-a11e-ffd4282a539f': require('../assets/images/profileQuestions/962b5407-86ed-4bf2-9d3b-f21850350b65/866efcdf-f58b-4503-a11e-ffd4282a539f.png'),
        '96f9370b-1cea-4da6-b7ed-c2870b3b8abd': require('../assets/images/profileQuestions/962b5407-86ed-4bf2-9d3b-f21850350b65/96f9370b-1cea-4da6-b7ed-c2870b3b8abd.png'),
        'd0a4e0e1-4fd8-48c5-a48c-e2d9d2fec60a': require('../assets/images/profileQuestions/962b5407-86ed-4bf2-9d3b-f21850350b65/d0a4e0e1-4fd8-48c5-a48c-e2d9d2fec60a.png'),
      },
      'nl-NL': {
        '5694d29f-a780-48ce-b0b2-b2069c67f21a': require('../assets/images/profileQuestions/962b5407-86ed-4bf2-9d3b-f21850350b65/5694d29f-a780-48ce-b0b2-b2069c67f21a.png'),
        '866efcdf-f58b-4503-a11e-ffd4282a539f': require('../assets/images/profileQuestions/962b5407-86ed-4bf2-9d3b-f21850350b65/866efcdf-f58b-4503-a11e-ffd4282a539f.png'),
        '96f9370b-1cea-4da6-b7ed-c2870b3b8abd': require('../assets/images/profileQuestions/962b5407-86ed-4bf2-9d3b-f21850350b65/96f9370b-1cea-4da6-b7ed-c2870b3b8abd.png'),
        'd0a4e0e1-4fd8-48c5-a48c-e2d9d2fec60a': require('../assets/images/profileQuestions/962b5407-86ed-4bf2-9d3b-f21850350b65/d0a4e0e1-4fd8-48c5-a48c-e2d9d2fec60a.png'),
      },
    },
  },
};
