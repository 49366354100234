/* eslint-disable no-console */
import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Countly from 'countly-sdk-web';
import localforage from 'localforage';

import localConfig from '../config';

type Props = {
  children: ReactElement;
  isTestUser: boolean;
};

const Location = (props: Props) => {
  const { children, isTestUser } = props;

  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        window.Countly = Countly;

        Countly.init({
          app_key: localConfig.countly.appKey,
          url: localConfig.countly.url,
          session_update: 10,
          use_session_cookie: true,
          debug: false,
          require_consent: true,
          namespace: 'prostate',
          inactivity_time: 1,
          offline_mode: false,
        });

        Countly.q.push(['group_features', {
          activity: ['sessions', 'events', 'views'],
          interaction: ['scrolls', 'clicks'],
        }]);

        let consents = await localforage.getItem('consents');

        if (consents) {
          Countly.q.push(['add_consent', JSON.parse(consents as string)]);
        } else {
          consents = ['activity', 'interaction'];
          Countly.q.push(['add_consent', consents]);

          await localforage.setItem('consents', JSON.stringify(consents));
        }

        Countly.q.push(['track_sessions']);
        Countly.q.push(['track_scrolls']);
        Countly.q.push(['track_clicks']);
        Countly.q.push(['track_links']);
      } catch (error) {
        // Ignore errors
      }
    })();
  }, []);

  useEffect(() => {
    if (!isTestUser) {
      try {
        Countly.q.push(['track_pageview', location.pathname]);
      } catch (error) {
      // Ignore errors
      }
    }
  }, [location, isTestUser]);

  return children;
};

export default Location;
