import React, { useMemo } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppState } from '../state/reducers';
import { TrendIndicatorType } from '../../types';

import ListItem from './ListItem';
import { ReactComponent as IconHeart } from '../assets/icons/Heart.svg';
import { ReactComponent as IconHeartOutline } from '../assets/icons/HeartOutline.svg';
import { ReactComponent as IconChecked } from '../assets/icons/Checked.svg';
import { ReactComponent as IconUnchecked } from '../assets/icons/Unchecked.svg';
import { actions as settingsActions, selectors as settingsSelectors } from '../state/settings';
import getMaxReachedFavoriteTrendIndicators from '../selectors/getMaxReachedFavoriteTrendIndicators';
import defaultGet from '../utils/defaultGet';
import getLocalizedText from '../utils/getLocalizedText';
import Colors from '../theme/Colors';

type Props = {
  indicator: TrendIndicatorType;
  divider?: boolean;
};

const SettingTrendIndicatorListItem = (props: Props) => {
  const { indicator, divider } = props;

  const memoizedSettingsGetTrendIndicatorByIdSelector = useMemo(
    () => settingsSelectors.makeGetTrendIndicatorById(indicator.id),
    [indicator],
  );

  const setting = useSelector(
    (state: AppState) => memoizedSettingsGetTrendIndicatorByIdSelector(state),
  );

  const maxFavoritesReached = useSelector(
    (state: AppState) => getMaxReachedFavoriteTrendIndicators(state),
  );

  const trendDisabled = defaultGet(setting, 'disabled', false);
  const favorite = defaultGet(setting, 'favorite', false);

  const dispatch = useDispatch();

  const onValueChange = (value: boolean) => {
    if (!value && favorite) {
      batch(() => {
        dispatch(settingsActions.trendIndicatorsUpdateDisabled([{
          id: indicator.id,
          disabled: !value,
          order: indicator.order,
        }]));
        dispatch(settingsActions.trendIndicatorsUpdateFavorite([{
          id: indicator.id,
          favorite: false,
        }]));
      });
    }

    return dispatch(settingsActions.trendIndicatorsUpdateDisabled([{
      id: indicator.id,
      disabled: !value,
      order: indicator.order,
    }]));
  };

  const updateFavorite = () => {
    if (favorite) {
      return dispatch(settingsActions.trendIndicatorsUpdateFavorite([{
        id: indicator.id,
        favorite: false,
      }]));
    }

    if (trendDisabled || maxFavoritesReached) {
      return false;
    }

    return dispatch(settingsActions.trendIndicatorsUpdateFavorite([{
      id: indicator.id,
      disabled: trendDisabled,
      favorite: !favorite,
    }]));
  };

  const { i18n } = useTranslation();

  return (
    <ListItem
      primaryText={getLocalizedText(indicator, i18n.language, 'title')}
      divider={divider}
      leftIcon={(
        <button type="button" onClick={updateFavorite} disabled={(trendDisabled || maxFavoritesReached) && !favorite}>
          {
            favorite
              ? <IconHeart fill={Colors.primary} className="icon" />
              : <IconHeartOutline fill={Colors.primary} className="icon" />
          }
        </button>
      )}
      rightIcon={(
        <button type="button" onClick={() => onValueChange(trendDisabled)}>
          {
            !trendDisabled
              ? <IconChecked fill={Colors.primary} className="icon" />
              : <IconUnchecked fill={Colors.primary} className="icon" />
          }
        </button>
      )}
    />
  );
};

export default SettingTrendIndicatorListItem;
