import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import filter from 'lodash/filter';
import toUpper from 'lodash/toUpper';
import size from 'lodash/size';
import { useTranslation } from 'react-i18next';

import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Drawer from '../components/Drawer';
import DrawerButtons from '../components/DrawerButtons';
import DrawerRightHeader from '../components/DrawerRightHeader';
import { selectors as usefulLinksSelectors } from '../state/usefulLinks';
import { ReactComponent as IconExternalLink } from '../assets/icons/ExternalLink.svg';
import { ReactComponent as IconClose } from '../assets/icons/Close.svg';
import Colors from '../theme/Colors';
import '../css/UsefulLinks.css';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const UsefulLinks = () => {
  const { i18n, t } = useTranslation();

  const [query, setQuery] = useState('');

  const usefulLinks = useSelector(
    (state: AppState) => usefulLinksSelectors.getByLocale(state, i18n.language),
  );

  let links = usefulLinks;

  if (query) {
    links = filter(usefulLinks, (item) => (
      toUpper(item.title).includes(toUpper(query))
      || toUpper(item.body).includes(toUpper(query))
    ));
  }

  return (
    <article className="page useful-links row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('useful-links')} showRightButton />
          <section className="search">
            <input
              value={query}
              placeholder={t('search')}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
              ) => setQuery(event.currentTarget.value)}
            />
            {
              query
                ? (
                  <button type="button" onClick={() => setQuery('')}>
                    <IconClose fill={Colors.lightGray} className="icon small" />
                  </button>
                )
                : null
            }
          </section>
          <section className="list">
            {map(links, (link, index) => (
              <>
                <a className="useful-link" href={link.url} target="_blank" rel="noreferrer" key={index}>
                  <header>
                    <h1 className="heading">{link.title}</h1>
                    <IconExternalLink fill={Colors.primary} className="icon" />
                  </header>
                  <p>{link.body}</p>
                </a>
                {(index + 1) !== size(links) ? <hr className="divider" /> : null}
              </>
            ))}
          </section>
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default UsefulLinks;
