import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';

import { TRiskProfile } from '../../types';
import { AppState } from '../state/reducers';

import ListItem from './ListItem';
import DecisionAidListItems from './DecisionAidListItems';
import Button from './Button';
import { selectors as tagsSelectors } from '../state/tags';
import { actions as reportsActions, selectors as reportsSelectors } from '../state/reports';
import getUnixTime from '../utils/getUnixTime';
import { ReactComponent as IconPreferences } from '../assets/icons/Preferences.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';

type TState = {
  answers: any;
  scores: any;
};

type Props = {
  next: () => void;
  state: TState;
};

const DecisionAidReport = (props: Props) => {
  const { next, state } = props;

  const memoizedTagsGetValueByKey = useMemo(
    tagsSelectors.makeGetValueByKey,
    [],
  );

  const riskProfileTagValue = useSelector(
    (reduxState: AppState) => memoizedTagsGetValueByKey(reduxState, 'riskProfile'),
  );

  const reportCounts = useSelector((reduxState: AppState) => reportsSelectors.counts(reduxState));

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const handleSave = () => {
    const reportId = uuidv4();
    const type = 'decisionAid-full';
    const count = get(reportCounts, [type]) || 0;

    dispatch(reportsActions.add([{
      id: reportId,
      createdAt: getUnixTime(),
      type,
      title: `${t(`reports:${type}`)} ${count + 1}`,
      content: {
        answers: state.answers,
        scores: state.scores,
        riskProfile: riskProfileTagValue as TRiskProfile,
      },
    }]));

    return history.push(`/reports/${reportId}`);
  };

  return (
    <section className="decision-aid-report">
      <h1 className="heading">{t('summary')}</h1>
      <p className="body">{t('decisionAid:report-intro')}</p>
      <hr className="divider" />
      <ListItem
        primaryText={t('your-preferences')}
        rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
        leftIcon={<IconPreferences fill={Colors.primary} className="icon" />}
        divider
        onClick={next}
      />
      <DecisionAidListItems report riskProfile={riskProfileTagValue} state={state} />
      <Button labelText={t('read-full-report')} onClick={handleSave} />
    </section>
  );
};

export default DecisionAidReport;
