import * as d3 from 'd3';
import clamp from 'lodash/clamp';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { FULL_DATE_FORMAT } from './constants';
import { TrendIndicatorType, TGraphData, TrendSeries } from '../../../types';
import getLocalizedText from '../../utils/getLocalizedText';

export class Point {
  x: number;

  y: number;

  id: string;

  labelValue: number;

  originalValue?: number;

  color?: string;

  constructor(x = 0, y = 0, id = '', labelValue = 0, originalValue?: number, color?: string) {
    this.x = x;
    this.y = y;
    this.id = id;
    this.labelValue = labelValue;
    this.originalValue = originalValue;
    this.color = color;
  }

  squaredDistance(point: Point): number {
    return (point.x - this.x) ** 2 + (point.y - this.y) ** 2;
  }

  getClosestPoint(points: Point[]): Point {
    let closestPoint = points[0];
    let closestDistance = this.squaredDistance(points[0]);

    for (let i = 1; i < points.length; i += 1) {
      const point = points[i];
      const testDistance = this.squaredDistance(point);

      if (testDistance < closestDistance) {
        closestPoint = point;
        closestDistance = testDistance;
      }
    }

    return closestPoint;
  }
}

const tooltipTimeFormatter = d3.timeFormat(FULL_DATE_FORMAT);

export const TOOLTIP_X_OFFSET = 10;

const generateTooltip = (
  titleText: string,
  valueText: string,
  width: number,
  height: number,
  tooltipHeight: number,
  point: Point,
  svg: d3.Selection<null, unknown, null, undefined>,
  datum: TGraphData,
  shouldFocusCircle: boolean = true,
) => {
  const datumDate = new Date(datum.date);

  const date = tooltipTimeFormatter(datumDate);

  const characterValueWidth = 7.75;
  const characterTitleWidth = 8.5;

  const valueTextLength = valueText.length;
  const titleTextLength = titleText.length;
  const dateTextLength = date.length;

  let characterWidth = characterValueWidth;
  let charactersLength = valueTextLength;

  if (titleTextLength > charactersLength) {
    charactersLength = titleTextLength;
    characterWidth = characterTitleWidth;
  }

  if (dateTextLength > charactersLength) {
    charactersLength = titleTextLength;
    characterWidth = characterTitleWidth;
  }

  const tooltipWidth = Math.max(100, charactersLength * characterWidth);

  const tooltipX = clamp(point.x, 0, width - tooltipWidth - TOOLTIP_X_OFFSET);
  const tooltipY = clamp(point.y, 0, height - tooltipHeight);

  const focus = svg.select('.trend-graph-focus');

  focus.attr('transform', `translate(${tooltipX}, ${tooltipY})`);

  if (shouldFocusCircle) {
    svg
      .select('.trend-graph-focus-circle')
      .attr('transform', `translate(${point.x}, ${point.y})`);
  }

  focus.select('.trend-graph-tooltip-title').text(titleText);
  focus.select('.trend-graph-tooltip-date').text(date);
  focus.select('.trend-graph-tooltip-value').text(valueText);
  focus.select('.trend-graph-tooltip').attr('width', tooltipWidth);
};

export const handleTooltipMouseMove = (
  context: any,
  scaleX: d3.ScaleTime<number, number>,
  scaleY: d3.ScaleLinear<number, number>,
  trendIndicator: TrendIndicatorType,
  data: TGraphData[],
  generateTooltipText: (
    datum: TGraphData,
    trendSeries: TrendIndicatorType,
    language: string,
  ) => string,
  svg: d3.Selection<null, unknown, null, undefined>,
  tooltipHeight: number,
  language: string,
): void => {
  const [mouseX, mouseY] = d3.mouse(context);
  const width = parseInt(svg.attr('width'), 10);
  const height = parseInt(svg.attr('height'), 10);

  // Get all points from graph data
  const points: Point[] = map(data, ({ date, value, labelValue }) => (
    new Point(
      clamp(scaleX(date) || 0, 0, width) as number,
      scaleY(value) as number,
      trendIndicator.id,
      labelValue,
    )
  ));

  if (isEmpty(points)) {
    return;
  }

  // Get point closest to mouse.
  const point = new Point(mouseX, mouseY).getClosestPoint(points);

  if (!point) {
    return;
  }

  const datum: TGraphData = {
    date: scaleX.invert(point.x).getTime(),
    value: scaleY.invert(point.y),
    labelValue: point.labelValue,
  };

  const titleText = getLocalizedText(trendIndicator, language, 'title');
  const valueText = generateTooltipText(datum, trendIndicator, language);

  generateTooltip(
    titleText,
    valueText,
    width,
    height,
    tooltipHeight,
    point,
    svg,
    datum,
    false,
  );
};

export const handleCombinedTooltipMouseMove = (
  context: any,
  scaleX: d3.ScaleTime<number, number>,
  scaleY: d3.ScaleLinear<number, number>,
  bisectDate: d3.Bisector<TGraphData, unknown>,
  trendSeriesList: TrendSeries[],
  generateTooltipText: (
    datum: TGraphData,
    trendSeries: TrendSeries,
    language: string,
  ) => string,
  svg: d3.Selection<null, unknown, null, undefined>,
  tooltipHeight: number,
  language: string,
  isFitnessResult: boolean = false,
): void => {
  if (trendSeriesList.length === 0) {
    return;
  }

  const [mouseX, mouseY] = d3.mouse(context);
  const width = parseInt(svg.attr('width'), 10);
  const height = parseInt(svg.attr('height'), 10);
  // Get date corresponding to mouse x pixel position.
  const mouseXDate = scaleX.invert(mouseX);

  const points: Point[] = [];

  // Get points that could be hovered over.
  trendSeriesList.forEach((trendSeries) => {
    const index = bisectDate.left(trendSeries.data, mouseXDate, 1);
    if (index <= 0 || index >= trendSeries.data.length) {
      return;
    }

    const getPoint = (i: number): Point => {
      const datum = trendSeries.data[i];
      return new Point(
        clamp(scaleX(datum.date) || 0, 0, width) as number,
        scaleY(datum.value) as number,
        trendSeries.id,
        datum.labelValue,
        datum.originalValue,
      );
    };

    trendSeries.data.forEach((item, idx) => item.selectable && points.push(getPoint(idx)));
  });

  if (points.length < 1) {
    return;
  }

  // Get point closest to mouse.
  const point = new Point(mouseX, mouseY).getClosestPoint(points);

  if (!point) {
    return;
  }

  const datum: TGraphData = {
    date: scaleX.invert(point.x).getTime(),
    value: scaleY.invert(point.y),
    labelValue: point.labelValue,
    originalValue: point.originalValue,
  };

  const trendSeries = trendSeriesList.filter(
    (series) => series.id === point.id,
  )[0];

  const titleText = getLocalizedText(trendSeries, language, 'title');
  const valueText = generateTooltipText(datum, trendSeries, language);

  generateTooltip(
    titleText,
    valueText,
    width,
    height,
    tooltipHeight,
    point,
    svg,
    datum,
    !isFitnessResult,
  );
};
