import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import size from 'lodash/size';
import classNames from 'classnames';

import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import Button from '../components/Button';
import QuestionnaireListItem from '../components/QuestionnaireListItem';
import Drawer from '../components/Drawer';
import DrawerRightHeader from '../components/DrawerRightHeader';
import { selectors as questionnairesSelectors } from '../state/questionnaires';

const DrawerRight = () => (
  <Drawer position="right">
    <section>
      <DrawerRightHeader />
    </section>
  </Drawer>
);

const Questionnaires = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const questionnaires = useSelector(
    (state: AppState) => questionnairesSelectors.getAll(state),
    shallowEqual,
  );

  return (
    <article className="page questionnaires row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('questionnaires')} showRightButton />
          <header className="list-header">
            <h1 className="heading-secondary accent">{t('my-questionnaires')}</h1>
          </header>
          <section className={classNames('list', { empty: isEmpty(questionnaires) })}>
            {
              !isEmpty(questionnaires)
                ? map(questionnaires, (questionnaire, index) => (
                  <QuestionnaireListItem
                    questionnaire={questionnaire}
                    key={questionnaire.id}
                    divider={(index + 1 !== size(questionnaires))}
                  />
                ))
                : <span className="body light">{t('questionnaires:empty-state-no-questionnaires')}</span>
            }
          </section>
          <Button extraClass="wide" labelText={t('add')} onClick={() => history.push('/questionnaires/add')} />
        </section>
        <Footer />
      </section>
      <DrawerRight />
    </article>
  );
};

export default Questionnaires;
