import { createSelector } from 'reselect';
import pick from 'lodash/pick';

// TODO: Depedency cycle
// import { AppState } from '../reducers';

const USER_DATA_KEYS = [
  'appointments',
  'audioRecordings',
  'hospitalAccessCodes',
  'messages',
  'notifications',
  'notes',
  'photos',
  'profileQuestionAnswers',
  'questionnaires',
  'settings',
  'treatments',
  'trends',
  'dailySuggestions',
  'sharedData',
  'tags',
  'reports',
  'generatedReports',
  'generatedSuggestedQuestions',
  'contacts',
];

const getCurrentUserData = (state: any) => pick(state, USER_DATA_KEYS);

const getCurrentUserDataByKeysSelector = (state: any, keys: string[]) => (
  pick(getCurrentUserData(state), keys)
);

const getCurrentUserDataByKeys = createSelector(
  (state: any) => state,
  (state: any, keys: string[]) => keys,
  getCurrentUserDataByKeysSelector,
);

const makeGetCurrentUserDataByKeys = () => (
  createSelector(
    (state: any) => state,
    (state: any, keys: string[]) => keys,
    getCurrentUserDataByKeysSelector,
  )
);

const getLastUserData = (state: any) => state.sync.lastLoadedUserData;

const getLastUserDataByKeysSelector = (state: any, keys: string[]) => (
  pick(state, keys)
);

const getLastUserDataByKeys = createSelector(
  (state: any) => state.sync.lastLoadedUserData,
  (state: any, keys: string[]) => keys,
  getLastUserDataByKeysSelector,
);

const makeGetLastUserDataByKeys = () => (
  createSelector(
    (state: any) => state.sync.lastLoadedUserData,
    (state: any, keys: string[]) => keys,
    getLastUserDataByKeysSelector,
  )
);

export {
  getCurrentUserData,
  getCurrentUserDataByKeys,
  makeGetCurrentUserDataByKeys,
  getLastUserData,
  getLastUserDataByKeys,
  makeGetLastUserDataByKeys,
};
