import React, { useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import localforage from 'localforage';
import { AppState } from '../state/reducers';

import Navigation from '../components/Navigation';
import DrawerButtons from '../components/DrawerButtons';
import Footer from '../components/Footer';
import ListItem from '../components/ListItem';
import { ReactComponent as IconProfileCard } from '../assets/icons/ProfileCard.svg';
import { ReactComponent as IconAccount } from '../assets/icons/Account.svg';
import { ReactComponent as IconCode } from '../assets/icons/Code.svg';
import { ReactComponent as IconChevronRight } from '../assets/icons/ChevronRight.svg';
import Colors from '../theme/Colors';
import getProfileProgressSelector from '../selectors/getProfileProgress';
import '../css/Settings.css';

const Settings = () => {
  const { t } = useTranslation();

  const { percentage, completed } = useSelector(
    (state: AppState) => getProfileProgressSelector(state),
  );

  const [email, setEmail] = useState('');

  useLayoutEffect(() => {
    const getEmail = async () => {
      const userEmail = await localforage.getItem('email') as string;
      if (userEmail) {
        setEmail(userEmail);
      }
    };

    getEmail();
  }, []);

  return (
    <article className="page settings row">
      <Navigation />
      <section className="container">
        <section className="content">
          <DrawerButtons title={t('settings')} />
          <section className="list-header">
            <div className="row center">
              <IconCode fill={Colors.primary} className="icon" />
              <h1 className="heading-secondary accent">{t('my-codes')}</h1>
            </div>
          </section>
          <section className="list">
            <ListItem
              primaryText={t('add-code')}
              rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
              to="/settings/invitation-codes"
            />
          </section>
          <section className="list-header">
            <div className="row center">
              <IconProfileCard fill={Colors.primary} className="icon" />
              <h1 className="heading-secondary accent">{t('profile')}</h1>
            </div>
            <p className="body light">{t('profile-completed', { percentage })}</p>
          </section>
          <section className="list">
            <ListItem
              primaryText={t(completed ? 'change-profile' : 'complete-profile')}
              rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
              to={completed ? '/profile' : '/profile/complete'}
            />
          </section>
          <section className="list-header">
            <div className="row center">
              <IconAccount fill={Colors.primary} className="icon" />
              <h1 className="heading-secondary accent">{t('my-account')}</h1>
            </div>
          </section>
          <section className="list">
            <ListItem
              primaryText={t('change-password')}
              rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
              to="/settings/change-password"
              divider
            />
            <ListItem
              primaryText={t('change-account-settings')}
              rightIcon={<IconChevronRight fill={Colors.primary} className="icon small" />}
              to="/settings/change-account-settings"
              divider
            />
            <ListItem
              primaryText={t('email')}
              secondaryText={email}
            />
          </section>
        </section>
        <Footer />
      </section>
    </article>
  );
};

export default Settings;
