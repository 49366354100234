import React, { useReducer, useState } from 'react';
import Dropdown from 'react-dropdown';
import DatePicker from 'react-datepicker';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import find from 'lodash/find';
import map from 'lodash/map';
import set from 'lodash/set';
import parseInt from 'lodash/parseInt';

import { TUnit, TState } from './types';
import { TTreatment, TTreatmentType } from '../../../types';

import '../../css/Treatment.css';
import reducer from './reducer';
import * as actions from './actions';
import DurationDropdown from '../DurationDropdown';
import Modal from '../Modal';
import Button from '../Button';
import { formValidation } from '../../utils/formValidation';
import {
  options as optionsSchema,
  text as textSchema,
  date as dateSchema,
  duration as durationSchema,
  boolean as booleanSchema,
} from '../../utils/schemas';
import { actions as treatmentsActions } from '../../state/treatments';
import getLocalizedText from '../../utils/getLocalizedText';
import dateAddDuration from '../../utils/dateAddDuration';
import isOfType from '../../utils/isOfType';
import getUnixTime from '../../utils/getUnixTime';
import getDateTime from '../../utils/getDateTime';
import defaultGet from '../../utils/defaultGet';
import toDate from '../../utils/toDate';
import { ReactComponent as IconChecked } from '../../assets/icons/Checked.svg';
import { ReactComponent as IconUnchecked } from '../../assets/icons/Unchecked.svg';
import Colors from '../../theme/Colors';

type TOption = {
  label: string;
  value: string;
};

type TFormErrors = {
  treatmentTypeId?: string;
  treatmentTypeOptionId?: string;
  treatmentTypeText?: string;
  startAt?: string;
  endAt?: string;
  duration?: string;
  completed?: string;
};

type Props = {
  treatment?: TTreatment;
  treatmentTypes: TTreatmentType[];
};

const Treatment = (props: Props) => {
  const {
    treatment = {},
    treatmentTypes,
  } = props;

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const [errors, setErrors] = useState<TFormErrors>({});
  const [modal, setModal] = useState({ visible: false });

  const treatmentTypeId = defaultGet(treatment, 'treatmentTypeId', '');
  const treatmentTypeOptionId = defaultGet(treatment, 'treatmentTypeOptionId', '');
  const treatmentTypeText = defaultGet(treatment, 'treatmentTypeText', '');
  const startAt = toDate(defaultGet(treatment, 'startDateTime', new Date()));
  const endAt = toDate(defaultGet(
    treatment,
    'endDateTime',
    dateAddDuration(new Date(), 1, 'days'),
  ));
  const duration = defaultGet(treatment, 'duration', { amount: 1, unit: 'days' });
  const completed = defaultGet(treatment, 'completed', false);

  const initialState: TState = {
    treatmentTypeId,
    treatmentTypeOptionId,
    treatmentTypeText,
    startAt,
    endAt,
    duration,
    completed,
  };

  const [state, localDispatch] = useReducer(reducer, initialState);

  const treatmentTypeOptions = map(treatmentTypes, (type) => ({
    label: getLocalizedText(type, i18n.language, 'title'),
    value: `${type.id}`,
  }));

  const treatmentType = find(treatmentTypes, { id: state.treatmentTypeId });

  let treatmentTypeOptionOptions: TOption[] = [];

  if (treatmentType && treatmentType.subType === 'option') {
    treatmentTypeOptionOptions = map(treatmentType.options, (option) => ({
      label: getLocalizedText(option, i18n.language, 'title'),
      value: `${option.id}`,
    }));
  }

  const showModal = () => setModal({ visible: true });

  const hideModal = () => setModal({ visible: false });

  const handleDelete = () => {
    if (!isOfType<TTreatment>(treatment, 'id')) {
      return false;
    }

    dispatch(treatmentsActions.remove([treatment.id]));

    hideModal();

    return history.push('/treatments');
  };

  const handleSave = () => {
    const treatmentTypeSchema = optionsSchema(
      true,
      map(treatmentTypeOptions, 'value'),
      t('errors:field-required', { field: t('treatment-type') }),
    );

    const shape = {
      treatmentTypeId: treatmentTypeSchema,
      startAt: dateSchema(true, t('errors:field-required', { field: t('starts-at') })),
      endAt: dateSchema(true, t('errors:field-required', { field: t('ends-at') })),
      duration: durationSchema(true, t('errors:field-required', { field: t('duration') })),
      completed: booleanSchema(true, t('errors:field-required', { field: t('treatment-completed') })),
    };

    if (treatmentType && treatmentType.subType === 'option') {
      const treatmentTypeOptionSchema = optionsSchema(
        true,
        map(treatmentTypeOptionOptions, 'value'),
        t('errors:field-required', { field: t('treatment-sub-type') }),
      );

      set(shape, 'treatmentTypeOptionId', treatmentTypeOptionSchema);
    }

    if (treatmentType && treatmentType.subType === 'text') {
      const treatmentTypeTextSchema = textSchema(
        false,
        t('errors:field-required', { field: t('field') }),
      );

      set(shape, 'treatmentTypeText', treatmentTypeTextSchema);
    }

    const schema = yup
      .object()
      .shape(shape);

    setErrors({});

    const formData = {
      treatmentTypeId: state.treatmentTypeId,
      treatmentTypeOptionId: state.treatmentTypeOptionId,
      treatmentTypeText: state.treatmentTypeText,
      startAt: state.startAt,
      endAt: state.endAt,
      duration: state.duration,
      completed: state.completed,
    };

    if (!formValidation(schema, formData, setErrors)) {
      return false;
    }

    if (isOfType<TTreatment>(treatment, 'id')) {
      dispatch(treatmentsActions.update([{
        ...treatment,
        updatedAt: getUnixTime(),
        treatmentTypeId: state.treatmentTypeId,
        treatmentTypeOptionId: state.treatmentTypeOptionId,
        treatmentTypeText: state.treatmentTypeText,
        startDateTime: getDateTime(state.startAt),
        endDateTime: getDateTime(state.endAt),
        duration: state.duration,
        completed: state.completed,
      }]));
    } else {
      dispatch(treatmentsActions.add([{
        id: uuidv4(),
        createdAt: getUnixTime(),
        treatmentTypeId: state.treatmentTypeId,
        treatmentTypeOptionId: state.treatmentTypeOptionId,
        treatmentTypeText: state.treatmentTypeText,
        startDateTime: getDateTime(state.startAt),
        endDateTime: getDateTime(state.endAt),
        duration: state.duration,
        completed: state.completed,
      }]));
    }

    return history.push('/treatments');
  };

  return (
    <>
      <section className="treatment-content">
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          htmlFor="type"
        >
          <span className="body light">{t('treatment-type')}</span>
          <Dropdown
            options={treatmentTypeOptions}
            onChange={(option) => localDispatch(actions.updateTreatmentTypeId(option.value))}
            value={state.treatmentTypeId}
            placeholder={t('treatment-type')}
          />
          {
            errors.treatmentTypeId && <p className="body error">{t(errors.treatmentTypeId)}</p>
          }
        </label>
        {
          (!treatmentType || (treatmentType && treatmentType.subType === 'option')) && (
            <label // eslint-disable-line jsx-a11y/label-has-associated-control
              htmlFor="subtype"
            >
              <span className="body light">{t('treatment-sub-type')}</span>
              <Dropdown
                options={treatmentTypeOptionOptions}
                onChange={(
                  option,
                ) => localDispatch(actions.updateTreatmentTypeOptionId(option.value))}
                value={state.treatmentTypeOptionId}
                placeholder={t('treatment-sub-type')}
              />
              {
                errors.treatmentTypeOptionId && <p className="body error">{t(errors.treatmentTypeOptionId)}</p>
              }
            </label>
          )
        }
        {
          treatmentType && treatmentType.subType === 'text' && (
            <label // eslint-disable-line jsx-a11y/label-has-associated-control
              htmlFor="text-subtype"
            >
              <h1 className="heading-secondary"><span /></h1>
              <input
                value={state.treatmentTypeText}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => localDispatch(actions.updateTreatmentTypeText(event.currentTarget.value))}
                id="text-subtype"
              />
              {
                errors.treatmentTypeText && <p className="body error">{t(errors.treatmentTypeText)}</p>
              }
            </label>
          )
        }
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          htmlFor="start-at"
        >
          <span className="body light">{t('starts')}</span>
          <DatePicker
            selected={state.startAt}
            onChange={(date: Date) => localDispatch(actions.updateStartAt(date))}
            showTimeSelect
            dateFormat="dd/LL/yyyy HH:mm"
            timeFormat="HH:mm"
            id="start-at"
          />
          {
            errors.startAt && <p className="body error">{t(errors.startAt)}</p>
          }
        </label>
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          htmlFor="end-at"
        >
          <span className="body light">{t('ends')}</span>
          <DatePicker
            selected={state.endAt}
            onChange={(date: Date) => localDispatch(actions.updateEndAt(date))}
            showTimeSelect
            dateFormat="dd/LL/yyyy HH:mm"
            timeFormat="HH:mm"
            id="end-at"
          />
          {
            errors.endAt && <p className="body error">{t(errors.endAt)}</p>
          }
        </label>
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          htmlFor="duration"
        >
          <span className="body light">{t('duration')}</span>
          <DurationDropdown
            amountValue={state.duration.amount}
            unitValue={state.duration.unit}
            onChangeAmount={(
              amount: string,
            ) => localDispatch(actions.updateDuration({ amount: parseInt(amount) }))}
            onChangeUnit={(unit: TUnit) => localDispatch(actions.updateDuration({ unit }))}
          />
          {
            errors.duration && <p className="body error">{t(errors.duration)}</p>
          }
        </label>
        <label // eslint-disable-line jsx-a11y/label-has-associated-control
          className="checkbox-container"
          htmlFor="completed"
        >
          <span
            className="body light label-section"
            onClick={(event) => event.preventDefault()}
            aria-hidden="true"
          >{t('treatment-completed')}
          </span>
          <section className="checkbox">
            <input
              type="checkbox"
              id="completed"
              checked={state.completed}
              onChange={(event) => localDispatch(
                actions.updateCompleted({ completed: event.currentTarget.checked, endAt }),
              )}
            />
            {
              state.completed
                ? <IconChecked fill={Colors.primary} className="icon" />
                : <IconUnchecked fill={Colors.primary} className="icon" />
            }
          </section>
          {
            errors.completed && <p className="body error">{t(errors.completed)}</p>
          }
        </label>
        <Button labelText={t('save')} onClick={handleSave} />
        {
          isOfType<TTreatment>(treatment, 'id') && (
            <Button
              labelText={t('delete')}
              color={Colors.destructiveRed}
              onClick={showModal}
            />
          )
        }
      </section>
      <Modal
        visible={modal.visible}
        hideModal={hideModal}
        actions={[
          {
            title: t('cancel'),
            onClick: hideModal,
          },
          {
            title: t('delete'),
            onClick: handleDelete,
            destructive: true,
          },
        ]}
      >
        <>
          <h1 className="heading">{t('modals.delete.title', { w: t('treatment') })}</h1>
          <p className="body">{t('modals.delete.body', { w: t('treatment') })}</p>
        </>
      </Modal>
    </>
  );
};

export default Treatment;
