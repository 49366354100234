import React from 'react';

import Colors from './Colors';
import { ReactComponent as IconSugery } from '../assets/icons/Operation.svg';
import { ReactComponent as IconChemotherapy } from '../assets/icons/Chemotherapy.svg';
import { ReactComponent as IconRadiotherapy } from '../assets/icons/Radiotherapy.svg';
import { ReactComponent as IconHormoneTherapy } from '../assets/icons/HormoneTherapy.svg';
import { ReactComponent as IconGCSF } from '../assets/icons/GCSF.svg';
import { ReactComponent as IconAntiSicknessDrugs } from '../assets/icons/AntiSicknessDrugs.svg';
import { ReactComponent as IconPainkillers } from '../assets/icons/Painkillers.svg';
import { ReactComponent as IconOther } from '../assets/icons/Other.svg';
import { ReactComponent as IconBisphosphonates } from '../assets/icons/Bisphosphonates.svg';
import { ReactComponent as IconWatchfulWaiting } from '../assets/icons/WatchfulWaiting.svg';
import { ReactComponent as IconCryotherapy } from '../assets/icons/Cryotherapy.svg';
import { ReactComponent as IconHIFU } from '../assets/icons/HIFU.svg';
import { ReactComponent as IconTURP } from '../assets/icons/TURP.svg';
import { ReactComponent as IconSteroids } from '../assets/icons/Steroids.svg';
import { ReactComponent as IconActiveSurveillance } from '../assets/icons/ActiveSurveillance.svg';
import { ReactComponent as IconBrachytherapy } from '../assets/icons/Brachytherapy.svg';
import { ReactComponent as IconBrachytherapyNeedles } from '../assets/icons/BrachytherapyNeedles.svg';
import { ReactComponent as IconInfo } from '../assets/icons/Info.svg';
import { ReactComponent as IconMinus } from '../assets/icons/Minus.svg';
import { ReactComponent as IconPlus } from '../assets/icons/Plus.svg';
import { ReactComponent as IconDelete } from '../assets/icons/Delete.svg';

const treatmentTypeIcons = {
  '6959d139-dfff-4501-b9ae-7fd6d3a142dc': <IconSugery className="icon" fill={Colors.primary} />, // Surgery
  '2b68d810-023c-484a-8534-b0c5c70caaa7': <IconChemotherapy fill={Colors.primary} className="icon" />, // Chemotherapy
  'a44eaf79-9924-4981-94a9-cc6c3c5c9a6c': <IconRadiotherapy fill={Colors.primary} className="icon" />, // Radiotherapy
  'e8adc4a9-7880-4fa7-8a88-1a36eae551b1': <IconHormoneTherapy fill={Colors.primary} className="icon" />, // Hormonetherapy
  '1b5d8f4a-e48f-44eb-ad87-d263ff9c08ec': <IconGCSF fill={Colors.primary} className="icon" />, // GCSF (Filgrastim®), Growth Factor)
  '507e2c91-6274-4bc8-ae1c-b4e1c029e3c4': <IconAntiSicknessDrugs fill={Colors.primary} className="icon" />, // Anti-sickness drugs
  '6065aa30-2cff-4590-aa41-10a69c733840': <IconPainkillers fill={Colors.primary} className="icon" />, // Painkillers
  'e01a763d-5bcc-4518-bfb0-447341cc54b4': <IconOther fill={Colors.primary} className="icon" />, // Other
  'ffc795a0-a5e4-4594-9cbc-968471bba16d': <IconActiveSurveillance className="icon" fill={Colors.primary} />,
  '71fd2d89-60fb-4d77-b4e8-ff281bededf9': <IconBisphosphonates className="icon" fill={Colors.primary} />, // Bisphosphonates
  'f0536369-ff8b-4c2b-b88e-b95979881e3f': <IconWatchfulWaiting className="icon" fill={Colors.primary} />, // Watchful waiting
  'bb7d5165-082c-4454-8887-c33bc4b39e01': <IconCryotherapy className="icon" fill={Colors.primary} />, // Cryotherapy
  '701f8f12-f3fa-42ca-8653-06d6acdb0f49': <IconHIFU className="icon" fill={Colors.primary} />, // High intensity focal ultrasound (HIFU)
  '9be7d8ac-56bc-44f5-b691-6d13827753eb': <IconTURP className="icon" fill={Colors.primary} />, // Transurethral resection of the prostate (TURP)
  'fe4c82c6-2dfa-4574-a3be-63d74c22389c': <IconSteroids className="icon" fill={Colors.primary} />, // Steroids
};

const pathwayIcons = {
  '574c590e-572f-42be-98f0-01278574b29c': <IconActiveSurveillance className="icon" fill={Colors.primary} />, // Active surveillance
  'c8e566d5-0e3e-42be-aea3-8f75689144d2': <IconSugery className="icon" fill={Colors.primary} />, // Surgery
  '1aa8e076-9de0-4563-9d03-2bac277a0e72': <IconRadiotherapy className="icon" fill={Colors.primary} />, // Radiotherapy (EBRT)
  '4418b414-f9f4-421e-ab96-f268f9040558': <IconBrachytherapy className="icon" fill={Colors.primary} />, // Brachytherapy
  '1a748503-4c4a-4d99-a70c-b51e85aa3091': (
    <section>
      <IconRadiotherapy className="icon" fill={Colors.primary} />
      <IconBrachytherapyNeedles className="icon" fill={Colors.primary} />
    </section>
  ), // Radiotherapy (EBRT) + Brachytherapy
  'fdf4986b-3c00-4519-8a23-c55a9eca27e5': (
    <section>
      <IconRadiotherapy
        className="icon"
        fill={Colors.primary}
      />
      <IconHormoneTherapy
        className="icon"
        fill={Colors.primary}
      />
    </section>
  ), // Radiotherapy (EBRT) + Hormone therapy
};

export const InfoIcon = <IconInfo className="icon" fill={Colors.primary} />;
export const MinusIconContrast = <IconMinus className="icon" fill={Colors.white} />;
export const PlusIconContrast = <IconPlus className="icon" fill={Colors.white} />;

const DeleteIconDestructive = <IconDelete className="icon" fill={Colors.destructiveRed} />;

export {
  treatmentTypeIcons,
  pathwayIcons,
  DeleteIconDestructive,
};
