import React, { useCallback, useEffect, MouseEvent } from 'react';
import Slider from 'react-slick';
import map from 'lodash/map';
import find from 'lodash/find';
import get from 'lodash/get';
import sample from 'lodash/sample';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import filter from 'lodash/filter';
import compact from 'lodash/compact';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useDimensions from 'react-cool-dimensions';

import { AppState } from '../state/reducers';
import { TDailySuggestion } from '../../types';

import Button from './Button';
import { ReactComponent as IconClose } from '../assets/icons/Close.svg';
import { actions as dailySuggestionsActions, selectors as dailySuggestionsSelectors } from '../state/dailySuggestions';
import { selectors as settingsSelectors } from '../state/settings';
import Colors from '../theme/Colors';
import isOfType from '../utils/isOfType';
import getLocalizedText from '../utils/getLocalizedText';
import getUnixTime from '../utils/getUnixTime';
import '../css/DailySuggestions.css';

import { suggestions } from '../../data/system-document.json';

const suggestionsWeb = filter(suggestions, 'onInteract.web');

const colors = [
  '#52538F',
  '#6186DB',
  '#C24890',
  '#520B66',
  '#9168CC',
  '#7A4BC9',
  '#3766FF',
  '#6186DB',
  '#3D228E',
  '#68A4CC',
  '#39ABF6',
  '#7A4BC9',
  '#7F68CC',
  '#52538F',
  '#8068CC',
  '#E45DE8',
  '#49738F',
  '#30264E',
  '#520B66',
  '#9168CC',
  '#E45DE8',
  '#3D228E',
  '#8068CC',
  '#68A4CC',
  '#39ABF6',
  '#3766FF',
  '#C24890',
  '#BF64D9',
  '#6487D9',
  '#575BFF',
];

const DailySuggestions = () => {
  const { i18n, t } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();

  const { observe, width } = useDimensions<HTMLElement | null>();

  const dailySuggestions = useSelector(
    (state: AppState) => dailySuggestionsSelectors.getOpen(state),
  );

  const suggestionsEnabledSetting = (
    useSelector((state: AppState) => settingsSelectors.getGeneralSuggestionsEnabled(state))
  );

  useEffect(useCallback(() => {
    dispatch(dailySuggestionsActions.check());
  }, []));

  if (!suggestionsEnabledSetting) {
    return null;
  }

  const onClick = (id: string) => {
    const suggestion = find(suggestionsWeb, { id });
    const item = find(dailySuggestions, { id });

    if (isEmpty(suggestion) || !isOfType<TDailySuggestion>(item, 'id')) {
      return false;
    }

    dispatch(dailySuggestionsActions.update([{
      ...item,
      interactedAt: getUnixTime(),
    }]));

    const action = get(suggestion, 'onInteract.web.action');

    if (!action) {
      return false;
    }

    if (action === 'route') {
      const path = get(suggestion, 'onInteract.web.path');
      const paramName = get(suggestion, 'onInteract.web.paramName');
      const paramValue = get(item, `params.${paramName}`);

      if (!path) {
        return false;
      }

      let param = '';

      if (paramName && paramValue) {
        param = paramValue;
      }

      return history.push(`${path}/${param}`);
    }

    return true;
  };

  const onClose = (id: string) => {
    const item = find(dailySuggestions, { id });

    if (!isOfType<TDailySuggestion>(item, 'id')) {
      return false;
    }

    return dispatch(dailySuggestionsActions.update([{
      ...item,
      closed: true,
      closedAt: getUnixTime(),
    }]));
  };

  const slides = compact(map(dailySuggestions, (item, index) => {
    const suggestion = find(suggestionsWeb, { id: item.id });

    if (isEmpty(suggestion)) {
      return null;
    }

    const color = get(colors, [index]) || sample(colors) as string;

    const handleClose = (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onClose(item.id);
    };

    return (
      <section // eslint-disable-line
        key={item.id}
        className="slide"
        onClick={() => onClick(item.id)}
      >
        <section className="slide-content" style={{ borderColor: color }}>
          <div>
            <div className="bar" style={{ backgroundColor: color }} />
            <h1 className="heading-secondary">{getLocalizedText(suggestion, i18n.language, 'title')}</h1>
            <p>{getLocalizedText(suggestion, i18n.language, 'description')}</p>
          </div>
          <Button
            labelText={getLocalizedText(suggestion, i18n.language, 'buttonText')}
            color={Colors.secondary}
          />
          <button type="button" className="close" onClick={handleClose}>
            <IconClose fill={Colors.secondary} className="icon small" />
          </button>
        </section>
      </section>
    );
  }));

  const slideWidth = 240;

  let slidesToShow = 1;

  if ((width / slideWidth) > 2) {
    slidesToShow = 2;
  }

  if ((width / slideWidth) > 3) {
    slidesToShow = 3;
  }

  if ((width / slideWidth) > 4) {
    slidesToShow = 4;
  }

  if (slidesToShow > size(slides)) {
    slidesToShow = size(slides);
  }

  let content = (
    <section className="overview-empty-state">
      <h1 className="heading">{t('empty-state-daily-suggestions')}</h1>
      <p className="body">{t('empty-state-daily-suggestions-body')}</p>
    </section>
  );

  if (isOfType<TDailySuggestion[]>(dailySuggestions)) {
    content = (
      <Slider
        dots
        arrows
        slidesToShow={slidesToShow}
        slidesToScroll={1}
      >
        {slides}
      </Slider>
    );
  }

  return (
    <section className="daily-suggestions" ref={observe}>
      <h1 className="body light">{t('today-suggestions')}</h1>
      {content}
    </section>
  );
};

export default DailySuggestions;
