import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import slice from 'lodash/slice';
import size from 'lodash/size';
import MarkdownView from 'react-showdown';

import ListItem from './ListItem';
import { ReactComponent as IconCheck } from '../assets/icons/Check.svg';
import { ReactComponent as IconChevronUp } from '../assets/icons/ChevronUp.svg';
import { ReactComponent as IconChevronDown } from '../assets/icons/ChevronDown.svg';
import Colors from '../theme/Colors';
import { pathwayIcons } from '../theme/Icons';
import getLocalizedText from '../utils/getLocalizedText';

import pathwayOptionsJSON from '../../data/pathwayOptions.json';

type TState = {
  answers: any;
  scores: any;
};

type Props = {
  report?: boolean;
  riskProfile: string;
  state: TState;
};

const DecisionAidListItems = (props: Props) => {
  const {
    report = false,
    riskProfile,
    state,
  } = props;

  const { t, i18n } = useTranslation();

  const [activeIndex, setActiveIndex] = useState<number>();

  const toggleItem = (index: number) => {
    if (index === activeIndex) {
      return setActiveIndex(undefined);
    }

    return setActiveIndex(index);
  };

  let rightButton = (
    <IconCheck
      className="icon"
      fill={Colors.primary}
    />
  );

  // Sort options by lowest score
  const options = sortBy(
    map(pathwayOptionsJSON, (option) => {
      const score = sum(compact(map(state.scores, (s) => (
        get(s, [option.id])
      ))));

      return {
        ...option,
        score,
      };
    }),
    'score',
  );

  const renderOptions = () => {
    // Do not display options when there are no scores yet
    if (isEmpty(state.scores)) {
      return null;
    }

    // Filter options based on risk profile
    const filteredOptions = filter(
      options,
      ({ riskProfiles }) => includes(riskProfiles, riskProfile),
    );

    // Only show 3 options in the report
    const slicedOptions = report ? slice(filteredOptions, 0, 3) : filteredOptions;

    return map(slicedOptions, (option, index) => {
      const active = activeIndex === index;
      const disabled = false;

      if (report) {
        rightButton = (
          <section className="row center more">
            <p className="button accent">{active ? t('less') : t('more')}</p>
            {
              active
                ? <IconChevronUp fill={Colors.primary} className="icon small" />
                : <IconChevronDown fill={Colors.primary} className="icon small" />
            }
          </section>
        );
      }

      const title = getLocalizedText(option, i18n.language, 'title');
      const description = getLocalizedText(option, i18n.language, 'description');
      const icon = get(pathwayIcons, [option.id]);

      return (
        <Fragment key={`fragment-${option.id}`}>
          <ListItem
            primaryText={title}
            divider={size(slicedOptions) !== (index + 1)}
            leftIcon={icon}
            rightIcon={rightButton}
            disabled={disabled}
            onClick={report ? () => toggleItem(index) : undefined}
          />
          {
            activeIndex === index
              ? (
                <>
                  <section className="report-container">
                    <MarkdownView markdown={description} />
                  </section>
                  <hr className="divider" />
                </>
              )
              : null
          }
        </Fragment>
      );
    });
  };

  const heading = `${t('decisionAid:pathway-options-heading')}`;

  return (
    <section className="decision-aid-list-items">
      <h1 className="heading accent">{heading}</h1>
      {renderOptions()}
    </section>
  );
};

export default DecisionAidListItems;
