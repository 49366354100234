import { Reducer } from 'redux';

import * as utils from './utils';
import * as actionTypes from './actionTypes';
import {
  MessagesState,
  MessagesActionTypes,
  MessagesAddActionType,
  MessagesRemoveActionType,
  MessagesSetReadAtActionType,
  MessagesSetAlertShownAtActionType,
} from './types';

const initialState: MessagesState = {};

const reducer: Reducer<MessagesState, MessagesActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      return utils.add(state, action as MessagesAddActionType);
    }
    case actionTypes.REMOVE: {
      return utils.remove(state, action as MessagesRemoveActionType);
    }
    case actionTypes.SET_READAT: {
      return utils.setReadAt(state, action as MessagesSetReadAtActionType);
    }
    case actionTypes.SET_ALERTSHOWNAT: {
      return utils.setAlertShownAt(state, action as MessagesSetAlertShownAtActionType);
    }
    case actionTypes.CLEAR: return utils.clear();
    default: return state;
  }
};

export default reducer;
