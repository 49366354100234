import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-dropdown';
import range from 'lodash/range';
import map from 'lodash/map';
import toString from 'lodash/toString';

import { TUnit } from './Treatment/types';
import '../css/DurationDropdown.css';

type Props = {
  amountValue: number;
  unitValue: TUnit;
  onChangeAmount: (amount: string) => void;
  onChangeUnit: (unit: TUnit) => void;
};

const propTypes = {
  amountValue: PropTypes.string.isRequired,
  unitValue: PropTypes.string.isRequired,
  onChangeAmount: PropTypes.func.isRequired,
  onChangeUnit: PropTypes.func.isRequired,
};

const DurationDropdown = (props: Props) => {
  const {
    amountValue,
    unitValue,
    onChangeAmount,
    onChangeUnit,
  } = props;

  const amountOptions = map(range(1, 100), (value) => ({ label: `${value}`, value: `${value}` }));

  const unitOptions = [
    { label: 'Day(s)', value: 'days' },
    { label: 'Week(s)', value: 'weeks' },
    { label: 'Month(s)', value: 'months' },
    { label: 'Year(s)', value: 'years' },
  ];

  return (
    <section className="duration-dropdown">
      <Dropdown
        options={amountOptions}
        onChange={(option) => onChangeAmount(option.value)}
        value={toString(amountValue)}
      />
      <Dropdown
        options={unitOptions}
        onChange={(option) => onChangeUnit(option.value as TUnit)}
        value={unitValue}
      />
    </section>
  );
};

DurationDropdown.propTypes = propTypes;

export default DurationDropdown;
